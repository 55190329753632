import React, { useEffect, useRef, useState } from 'react';
import { InputError, InputLabel } from 'components';
import classNames from 'classnames';
import Trigger from './Trigger';

type Props = {
  children: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  label?: string;
  error?: any;
  placeholder?: string;
  resetFn?: any;
};

const defaultProps = {
  selected: false,
  disabled: false,
  label: '',
  error: null,
  placeholder: '',
  resetFn: null,
};

function Dropdown({
  children,
  selected,
  disabled,
  label,
  placeholder,
  error,
  resetFn,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <div className="relative mb-12 xl:mb-16" ref={dropdownRef}>
        <Trigger
          open={isOpen}
          selected={!!selected}
          error={!!error}
          resetFn={resetFn}
          disabled={disabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          {placeholder || 'Wybierz'}
        </Trigger>
        <div
          className={classNames('absolute top-40 xl:top-48 left-0 w-full bg-white z-10', {
            'border border-t-0 border-primary': isOpen,
          })}
        >
          {isOpen && <div className="px-16 py-12 ">{children}</div>}
        </div>
        <InputError message={error?.message} />
      </div>
    </>
  );
}

Dropdown.defaultProps = defaultProps;

export default Dropdown;
