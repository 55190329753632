import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Input, InputLabel, Datepicker, Dropdown, CheckboxGroup } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { fetchGlobals, selectorGlobals } from 'features/globals/globalsSlice';
import Loading from 'features/loading/Loading';
import { debounce } from 'lodash';
import { orderService } from 'services';
import type { ListResponse, Order } from 'helpers/types';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { AlertStatus } from 'helpers/enums';
import { catchApiError } from 'helpers/error';

type Props = {
  page: number;
  perPage: number;
  handleFetch: (v: ListResponse<Order>) => void;
  changePage: (v: number) => void;
};

type Inputs = {
  scopeOfOrderId: string[];
  typeOfStudyId: string[];
  fieldId: string[];
  pagesCountFrom: number;
  pagesCountTo: number;
  termFrom: string;
  termTo: string;
};

function EditorSearchOrder({ page, perPage, changePage, handleFetch }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const globals = useAppSelector(selectorGlobals);

  useEffect(() => {
    dispatch(fetchGlobals(['scopeOfOrders', 'typesOfStudy', 'activityFields']));
  }, []);

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      scopeOfOrderId: [],
      typeOfStudyId: [],
      fieldId: [],
    },
  });

  const scopeOfOrderIdWatch = watch('scopeOfOrderId');
  const typeOfStudyIdWatch = watch('typeOfStudyId');
  const fieldIdWatch = watch('fieldId');

  const onSubmit = useCallback(
    async ({
      scopeOfOrderId,
      typeOfStudyId,
      fieldId,
      pagesCountFrom,
      pagesCountTo,
      termFrom,
      termTo,
    }: Inputs) => {
      try {
        dispatch(addLoading({ id: 'EDITOR_SEARCH' }));

        const filters: { [v: string]: any } = {};

        if (scopeOfOrderId) {
          filters['a.scopeOfOrder'] = {
            in: scopeOfOrderId,
          };
        }

        if (typeOfStudyId) {
          filters['a.typeOfStudy'] = {
            in: typeOfStudyId,
          };
        }

        if (fieldId) {
          filters['a.field'] = {
            in: fieldId,
          };
        }

        if (pagesCountFrom || pagesCountTo) {
          filters['a.pagesCount'] = {};

          if (pagesCountFrom) {
            filters['a.pagesCount'].gte = pagesCountFrom;
          }

          if (pagesCountTo) {
            filters['a.pagesCount'].lte = pagesCountTo;
          }
        }

        if (termFrom || termTo) {
          filters['a.term'] = {};

          if (termFrom) {
            filters['a.term'].gte = termFrom;
          }

          if (termTo) {
            filters['a.term'].lte = termTo;
          }
        }

        const params = {
          records: perPage,
          page,
          filters,
          sortField: 'a.created',
          sortDirection: 'DESC',
        };

        const response = await orderService.search(params);

        handleFetch(response);
      } catch (error) {
        catchApiError(error, () =>
          dispatch(
            addNotification({
              id: 'auth.editorSearchOrder',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        );
      } finally {
        dispatch(removeLoading('EDITOR_SEARCH'));
      }
    },
    [page],
  );

  useEffect(() => {
    handleSubmit(onSubmit)();
  }, [page]);

  const handleCustomChange = useMemo(
    () =>
      debounce(() => {
        if (page === 1) {
          handleSubmit(onSubmit)();
        } else {
          changePage(1);
        }
      }, 800),
    [page, changePage, handleSubmit, onSubmit],
  );

  return (
    <Loading items={['FETCH_GLOBALS']}>
      <form>
        <div className="row">
          <div className="col xl:w-1/3">
            <Dropdown
              label="Zakres zlecenia"
              selected={scopeOfOrderIdWatch && !!scopeOfOrderIdWatch.length}
              disabled={isSubmitting}
              resetFn={() => {
                setValue('scopeOfOrderId', [], { shouldValidate: true });
                handleCustomChange();
              }}
            >
              <CheckboxGroup
                register={register}
                name="scopeOfOrderId"
                idPrefix="scopeOfOrderId"
                items={globals.scopeOfOrders}
                disabled={isSubmitting}
                onChangeEmit={handleCustomChange}
              />
            </Dropdown>
          </div>
          <div className="col xl:w-1/3">
            <Dropdown
              label="Rodzaj opracowania"
              selected={typeOfStudyIdWatch && !!typeOfStudyIdWatch.length}
              disabled={isSubmitting}
              resetFn={() => {
                setValue('typeOfStudyId', [], { shouldValidate: true });
                handleCustomChange();
              }}
            >
              <CheckboxGroup
                register={register}
                name="typeOfStudyId"
                idPrefix="typeOfStudyId"
                items={globals.typesOfStudy}
                disabled={isSubmitting}
                onChangeEmit={handleCustomChange}
              />
            </Dropdown>
          </div>
          <div className="col xl:w-1/3">
            <Dropdown
              label="Dziedzina"
              selected={fieldIdWatch && !!fieldIdWatch.length}
              disabled={isSubmitting}
              resetFn={() => {
                setValue('fieldId', [], { shouldValidate: true });
                handleCustomChange();
              }}
            >
              <CheckboxGroup
                register={register}
                name="fieldId"
                idPrefix="fieldId"
                items={globals.activityFields}
                disabled={isSubmitting}
                onChangeEmit={handleCustomChange}
              />
            </Dropdown>
          </div>
          <div className="col md:w-1/2 xl:w-1/3">
            <InputLabel>Ilość stron</InputLabel>
            <div className="row">
              <div className="col w-1/2">
                <Input
                  control={control}
                  name="pagesCountFrom"
                  placeholder="Od"
                  disabled={isSubmitting}
                  onChangeEmit={handleCustomChange}
                />
              </div>
              <div className="col w-1/2">
                <Input
                  control={control}
                  name="pagesCountTo"
                  placeholder="Do"
                  disabled={isSubmitting}
                  onChangeEmit={handleCustomChange}
                />
              </div>
            </div>
          </div>
          <div className="col md:w-1/2 xl:w-1/3">
            <InputLabel>Termin realizacji</InputLabel>
            <div className="row">
              <div className="col w-1/2">
                <Datepicker
                  control={control}
                  name="termFrom"
                  placeholder="Od"
                  disabled={isSubmitting}
                  onChangeEmit={handleCustomChange}
                />
              </div>
              <div className="col w-1/2">
                <Datepicker
                  control={control}
                  name="termTo"
                  placeholder="Do"
                  disabled={isSubmitting}
                  onChangeEmit={handleCustomChange}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </Loading>
  );
}

export default EditorSearchOrder;
