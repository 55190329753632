import api, { ApiError } from 'services';
import history from 'helpers/history';
import { Role } from 'helpers/enums';
import type { User, OptionItem } from 'helpers/types';
import { parseAttachment } from 'helpers/attachments';
import { get, set, unset } from 'lodash';

type UserEditorResponseType = User & {
  editorProfile: {
    nativeLanguage: OptionItem;
    invoiceCountryId: string;
    educationId: string;
    preferredFieldsIds: string[];
  };
};

const parseOption = (user: any, key: string, keyId: string) => {
  const option: OptionItem = get(user, key);

  if (option) {
    set(user, keyId, option.id);
    unset(user, key);
  }
};

const parseOptions = (user: any, key: string, keyId: string) => {
  const options: OptionItem[] = get(user, key);

  if (options) {
    set(
      user,
      keyId,
      options.map((el) => String(el.id)),
    );
    unset(user, key);
  }
};

export const setUser = (user: User | UserEditorResponseType, remember = false): void => {
  const target = 'localStorage';

  user.profilePhoto = user.profilePhoto ? parseAttachment(user.profilePhoto) : null;

  parseOption(user, 'editorProfile.nativeLanguage', 'editorProfile.nativeLanguageId');
  parseOption(user, 'editorProfile.invoiceCountry', 'editorProfile.invoiceCountryId');
  parseOption(user, 'editorProfile.education', 'editorProfile.educationId');
  parseOptions(user, 'editorProfile.preferredFields', 'editorProfile.preferredFieldsIds');

  window[target].setItem('user', JSON.stringify(user));

  document.dispatchEvent(new CustomEvent('userChange'));
};

export const setToken = (token: string, remember: boolean): void => {
  const target = 'localStorage';

  window[target].setItem('token', token);
};

export const removeUser = (): void => {
  window.localStorage.clear();
  window.sessionStorage.clear();
};

export const getUser = (): User | Record<string, undefined> => {
  try {
    return JSON.parse(
      window.localStorage.getItem('user') || window.sessionStorage.getItem('user') || '',
    ) as User;
  } catch (error) {
    return {};
  }
};

export const getToken = (): string => {
  return window.localStorage.getItem('token') || window.sessionStorage.getItem('token') || '';
};

export const isLogin = (): boolean => {
  return !!window.localStorage.getItem('token') || !!window.sessionStorage.getItem('token');
};

export const hasRole = (role: Role): boolean => {
  const { role: userRole } = getUser() || {};
  return userRole === role;
};

export const redirect = (): void => {
  const { role } = getUser();

  if (role === Role.editor) {
    history.push('/redaktor');
  }

  if (role === Role.client) {
    history.push('/klient');
  }
};

export const logout = (): void => {
  removeUser();
  history.push('/auth/login?logout');
};

type LoginRequest = {
  username: string;
  password: string;
};

type LoginResponse = {
  token: string;
  user: User;
};

export const login = async (data: LoginRequest): Promise<LoginResponse> => {
  try {
    return await api
      .post('auth/login', {
        json: data,
      })
      .json();
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const forgotPassword = async (email: string): Promise<void> => {
  try {
    await api.post('auth/reset-password', {
      json: {
        email,
      },
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export type ForgotPasswordResetRequest = {
  password: string;
  token: string;
};

export const forgotPasswordReset = async (data: ForgotPasswordResetRequest): Promise<void> => {
  try {
    await api.post('auth/reset-password-confirm', {
      json: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const forgotPasswordResetConfirmToken = async (token: string): Promise<void> => {
  try {
    await api.get('auth/reset-password-confirm', {
      searchParams: `token=${token}`,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const registerClient = async (data: FormData): Promise<void> => {
  try {
    await api.post('auth/register-client', {
      body: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const registerEditor = async (data: FormData): Promise<void> => {
  try {
    await api.post('auth/register-editor', {
      body: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const confirmRegister = async (token: string): Promise<void> => {
  try {
    await api.post('auth/confirm-email', {
      json: {
        token,
      },
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};
