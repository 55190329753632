import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from 'store';

type Item = {
  id: string;
};

const adapter = createEntityAdapter<Item>();

const slice = createSlice({
  name: 'disabled',
  initialState: adapter.getInitialState(),
  reducers: {
    addOne: adapter.addOne,
    removeOne: adapter.removeOne,
  },
});

export const { addOne, removeOne } = slice.actions;

export const disabledSelectors = adapter.getSelectors<RootState>((state) => state.disabled);

export default slice.reducer;
