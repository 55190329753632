import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ForgotPasswordReset } from 'forms';
import { authService } from 'services';

function ForgotPasswordForm(): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const query = new URLSearchParams(history.location.search);
        await authService.forgotPasswordResetConfirmToken(query.get('token') || '');
      } catch (error) {
        history.push('/404');
      }
    })();
  }, []);

  return (
    <>
      <h1 className="font-headings font-bold text-black text-32 xl:text-48 mb-16">
        Zapomniałeś hasła?
      </h1>
      <p className="text-18 xl:text-20 font-semibold text-black-75 leading-29 xl:leading-32 mb-24 xl:mb-40">
        Ustaw nowe hasło.
      </p>
      <ForgotPasswordReset />
    </>
  );
}

export default ForgotPasswordForm;
