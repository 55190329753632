import { useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import {
  addOne as addNotification,
  removeOne as removeNotification,
} from 'features/notifications/notificationsSlice';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';

const useFieldsErrors = (formId: string, errors: FieldErrors) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['alert']);

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      dispatch(
        addNotification({
          id: formId,
          status: AlertStatus.error,
          message: t('alert:fieldsError'),
        }),
      );
    } else {
      dispatch(removeNotification(formId));
    }
  }, [errors]);
};

export default useFieldsErrors;
