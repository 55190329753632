import React from 'react';
import { Icon } from 'components';

type Props = {
  value: number;
  asTrigger?: boolean;
  onClick?: (v: number) => void;
};

const defaultProps = {
  asTrigger: false,
  onClick: undefined,
};

const DefaultItemWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="mx-2">{children}</div>
);

type TriggerProps = {
  children: React.ReactNode;
  value: number;
  onClick?: (v: number) => void;
};

const triggerDefaultProps = {
  onClick: undefined,
};

const TriggerItemWrapper = ({ children, value, onClick }: TriggerProps) => (
  <button type="button" className="mx-2" onClick={onClick ? () => onClick(value) : () => {}}>
    {children}
  </button>
);

TriggerItemWrapper.defaultProps = triggerDefaultProps;

function RatingStars({ value, asTrigger, onClick }: Props): JSX.Element {
  const Wrapper = asTrigger ? TriggerItemWrapper : DefaultItemWrapper;

  return (
    <div className="flex -mx-2">
      {new Array(10).fill(null).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Wrapper key={i} value={i + 1} onClick={onClick}>
          <Icon
            icon="star"
            className="pointer-events-none"
            beforeInjection={(svg) => {
              svg.classList.add('w-16', 'h-16', i < value ? 'fill-accent-dk' : 'fill-black-10');
            }}
          />
        </Wrapper>
      ))}
    </div>
  );
}

RatingStars.defaultProps = defaultProps;

export default RatingStars;
