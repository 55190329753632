import React from 'react';
import { Button } from 'components';
import { LayoutImage } from 'layouts';
import { images } from 'assets';

const RegisterEditorSuccess = (): JSX.Element => {
  return (
    <LayoutImage image={images.authBg}>
      <h1 className="font-headings font-bold text-black text-32 xl:text-48 mb-16">
        Zostań redaktorem
      </h1>
      <p className="text-18 xl:text-20 font-semibold text-black-75 leading-29 xl:leading-32">
        Twój wniosek został przyjęty. Administrator zweryfikuje podane dane i otrzymasz od nas
        wiadomość z informacją, czy Twoje konto zostało zaakceptowane.
      </p>
      <div className="mt-24 xl:mt-40">
        <Button as="route" to="/">
          Powrót do strony głównej
        </Button>
      </div>
    </LayoutImage>
  );
};

export default RegisterEditorSuccess;
