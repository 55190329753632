import React, { useEffect, useState } from 'react';
import { Alert, Modal, Pagination, ButtonCopy } from 'components';
import { Order, Stages, OrderSummary } from 'layouts';
import Loading from 'features/loading/Loading';
import { useTranslation } from 'react-i18next';
import { AlertStatus, OrderList } from 'helpers/enums';
import { useAppDispatch, useAppSelector, useOrders } from 'hooks';
import { AddStageOfCorrection } from 'forms';
import {
  selectors,
  removeOne as removeModal,
  Modal as ModalProps,
} from 'features/modals/modalsSlice';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { orderService } from 'services';
import { catchApiError } from 'helpers/error';

const Ongoing = (): JSX.Element => {
  const { t } = useTranslation(['alert', 'text']);
  const [list] = useOrders(OrderList.ongoing, 'CLIENT_ORDERS_ONGOING');
  const [addAttachment, setAddAttachment] = useState<[number, number] | null>(null);
  const dispatch = useAppDispatch();
  const modals = useAppSelector(selectors.selectAll);
  const [paymentModal, setPaymentModal] = useState<ModalProps | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<{ name: string; value: string; label: string }[]>(
    [],
  );

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_PAYMENT_INFO' }));

        const response = await orderService.paymentInfo();

        setPaymentInfo(response);
      } catch (error) {
        catchApiError(error);
      } finally {
        dispatch(removeLoading('FETCH_PAYMENT_INFO'));
      }
    })();
  }, []);

  useEffect(() => {
    const m = modals.find((item) => item.id === 'PAYMENT_INFO');

    setPaymentModal(m || null);
  }, [modals]);

  const renderOrders = () =>
    list.items.map((item) => {
      return (
        <Order key={item.id} {...item}>
          <div className="border-t border-black-10 pt-12 xl:pt-16">
            <Stages
              stages={item.stages}
              idOrder={item.id}
              refreshFn={() => list.setPage(1)}
              addAttachmentFn={(idO, idS) => setAddAttachment([idO, idS])}
            />
            <div className="mt-16 xl:mt-24">
              <OrderSummary idOrder={item.id} offers={item.offers} titlePrice={t('text:reward')} />
            </div>
          </div>
        </Order>
      );
    });

  return (
    <>
      <Loading items={['CLIENT_ORDERS_ONGOING', 'FETCH_PAYMENT_INFO']}>
        {list.items.length ? (
          renderOrders()
        ) : (
          <Alert status={AlertStatus.info} message={t('alert:noResults')} />
        )}
        <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
      </Loading>
      <Modal
        title={t('text:addCorrection')}
        isOpen={!!addAttachment}
        onRequestClose={() => setAddAttachment(null)}
      >
        {addAttachment && (
          <AddStageOfCorrection
            id={addAttachment[1]}
            idOrder={addAttachment[0]}
            correction
            onRequestClose={() => setAddAttachment(null)}
            onSuccess={() => {
              setAddAttachment(null);
              list.setPage(1);
            }}
          />
        )}
      </Modal>
      {!!paymentInfo.length && (
        <Modal
          title={t('text:paymentInfo')}
          isOpen={!!paymentModal}
          onRequestClose={() => dispatch(removeModal('PAYMENT_INFO'))}
        >
          <p className="text-12 xl:text-16 leading-19 xl:leading-28 pb-24 border-b border-black-5">
            {t('text:paymentDescription')}
          </p>
          <div className="mt-32">
            {[
              ...paymentInfo,
              {
                name: 'text:paymentTitle',
                value: paymentModal?.data?.title,
                label: paymentModal?.data?.title,
              },
              {
                name: 'text:price',
                value: paymentModal?.data?.price,
                label: `${paymentModal?.data?.price}&nbsp;zł`,
              },
            ].map((info) => (
              <div className="row mb-24 items-center">
                <div className="col md:w-1/4">
                  <p className="text-12 xl:text-13 leading-19 xl:leading-22 font-semibold text-primary-dk uppercase">
                    {t(info.name)}:
                  </p>
                </div>
                <div className="col w-auto">
                  <div className="row items-center">
                    <div className="col w-auto">
                      <p
                        className="text-12 xl:text-16 leading-19 xl:leading-28"
                        dangerouslySetInnerHTML={{
                          __html: String(info.label).replace('\n', '<br />'),
                        }}
                      />
                    </div>
                    <div className="col w-auto">
                      <ButtonCopy text={info.value} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Ongoing;
