import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HeroText, CtaImageFull, ArticleThumb } from 'layouts';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { pageService } from 'services';
import { useAppDispatch, useList } from 'hooks';
import { catchApiError } from 'helpers/error';
import Loading from 'features/loading/Loading';
import type { ArticlePage } from 'services/page/types';
import { Cms } from 'components';
import { Props as ArticleThumbType } from 'layouts/ArticleThumb/ArticleThumb';
import { useTranslation } from 'react-i18next';

type Params = {
  slug: string;
};

const Article = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { slug } = useParams<Params>();
  const { t } = useTranslation(['text']);
  const [article, setArticle] = useState<ArticlePage>();
  const otherArticles = useList<ArticleThumbType>(3);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_PAGE' }));

        const response = await pageService.article(slug);

        setArticle(response);
      } catch (error) {
        catchApiError(error);
      } finally {
        dispatch(removeLoading('FETCH_PAGE'));
      }
    })();
  }, [slug]);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_OTHER_ARTICLES' }));

        const params = {
          records: otherArticles.perPage,
          page: otherArticles.page,
          sortField: 'a.created',
          sortDirection: 'DESC',
          filters: {
            'a.id': {
              neq: article?.id,
            },
          },
        };

        const response = await pageService.articles(params);

        otherArticles.handleFetch(response);
      } catch (error) {
        console.error('FETCH_OTHER_ARTICLES');
      } finally {
        dispatch(removeLoading('FETCH_OTHER_ARTICLES'));
      }
    })();
  }, [article, otherArticles.shouldUpdate]);

  return (
    <Loading items={['FETCH_PAGE', 'FETCH_OTHER_ARTICLES']}>
      {article?.title && <HeroText title={article.title} />}

      {article?.content && (
        <section className="my-64 xl:my-96">
          <div className="container">
            <Cms>
              <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </Cms>
          </div>
        </section>
      )}
      {article?.cta && <CtaImageFull {...article.cta} />}
      {!!otherArticles.items.length && (
        <section className="my-64 xl:my-96">
          <div className="container">
            <h3 className="font-bold font-headings text-black text-28 xl:text-40 leading-34 xl:leading-50 text-center mb-24 xl:mb-32">
              {t('text:articles')}
            </h3>
            <div className="row -mb-16 lg:-mb-24">
              {otherArticles.items.map((item) => (
                <div className="col md:w-1/2 xl:w-1/3 mb-16 lg:mb-24" key={item.id}>
                  <ArticleThumb {...item} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </Loading>
  );
};

export default Article;
