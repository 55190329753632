import React from 'react';
import { Cta } from 'layouts';
import classNames from 'classnames';
import type { Props as CtaType } from 'layouts/Cta/Cta';
import type { Image } from 'helpers/types';

export type Props = {
  cta: CtaType | null;
  image: Image | null;
  reverse?: boolean;
};

const defaultProps = {
  reverse: false,
};

function CtaImage({ cta, image, reverse }: Props): JSX.Element | null {
  if (!cta) return null;

  return (
    <section className="my-64 xl:my-96">
      <div className="container">
        <div className="row">
          <div
            className={classNames('col md:w-1/2', {
              'xl:order-1': !reverse,
            })}
          >
            <Cta {...cta} />
          </div>
          <div
            className={classNames('col md:w-1/2 mt-32 md:mt-0 text-center', {
              'md:text-left': !reverse,
              'md:text-right': reverse,
            })}
          >
            {image && (
              <img
                src={image.url}
                alt={image.alt}
                className={classNames('inline-block', {
                  'xl:mr-24': !reverse,
                  'xl:ml-24': reverse,
                })}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

CtaImage.defaultProps = defaultProps;

export default CtaImage;
