import React from 'react';
import { useLocation } from 'react-router-dom';
import RegisterEditorView from './RegisterEditorView';
import RegisterEditorSuccess from './RegisterEditorSuccess';

const RegisterEditor = (): JSX.Element => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  if (query.has('success')) return <RegisterEditorSuccess />;

  return <RegisterEditorView />;
};

export default RegisterEditor;
