import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import type { TabRoute } from 'helpers/types';
import { NavLink } from 'react-router-dom';
import { Badge } from 'components/index';

type Props = {
  routes: TabRoute[];
  path?: string;
};

const defaultProps = {
  path: '',
};

function Tabs({ routes, path }: Props): JSX.Element {
  return (
    <Swiper
      spaceBetween={24}
      slidesPerView="auto"
      freeMode
      breakpoints={{
        1280: {
          spaceBetween: 40,
        },
      }}
    >
      {routes.map((route) => (
        <SwiperSlide key={route.key} className="w-auto">
          <NavLink
            to={`${path}${route.path}`}
            exact
            className="text-15 w-auto xl:text-16 font-semibold uppercase pb-12 flex items-center border-b-2 border-transparent"
            activeClassName="text-primary-dk border-primary-dk"
          >
            <span className="mr-8">{route.name}</span>
            {route.badge && <Badge title={route.badge} />}
          </NavLink>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

Tabs.defaultProps = defaultProps;

export default Tabs;
