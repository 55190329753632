import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { Button } from 'components';
import { editorService } from 'services';
import { catchApiError } from 'helpers/error';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { AlertStatus } from 'helpers/enums';
import { useAppDispatch } from 'hooks';

const Download = (): JSX.Element => {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const downloadBilling = async () => {
    try {
      setIsLoading(true);
      const response = await editorService.downloadBilling();

      saveAs(response, 'rozliczenie.csv');
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'downloadBilling',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button as="button" to="" onClick={downloadBilling} loading={isLoading}>
        {t('button:downloadBilling')}
      </Button>
    </div>
  );
};

export default Download;
