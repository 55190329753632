import React from 'react';
import { Alert, Pagination } from 'components';
import { Order, Offer } from 'layouts';
import Loading from 'features/loading/Loading';
import { useTranslation } from 'react-i18next';
import { AlertStatus, OrderList } from 'helpers/enums';
import { useOrders } from 'hooks';

const Offers = (): JSX.Element => {
  const { t } = useTranslation(['alert']);
  const [list] = useOrders(OrderList.offers, 'CLIENT_ORDERS_OFFERS');

  const renderOrders = () =>
    list.items.map((item) => (
      <Order key={item.id} {...item}>
        {item.offers.length ? (
          <div className="row">
            {item.offers.map(({ id, price, editor }) => (
              <div className="col w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6" key={id}>
                <Offer
                  id={id}
                  idOrder={item.id}
                  price={price}
                  editor={editor!}
                  handleSuccess={() => list.setPage(1)}
                />
              </div>
            ))}
          </div>
        ) : null}
      </Order>
    ));

  return (
    <Loading items={['CLIENT_ORDERS_OFFERS']}>
      {list.items.length ? (
        renderOrders()
      ) : (
        <Alert status={AlertStatus.info} message={t('alert:noResults')} />
      )}
      <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
    </Loading>
  );
};

export default Offers;
