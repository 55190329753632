import { useEffect, useState } from 'react';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { pageService } from 'services';
import { Page } from 'helpers/enums';
import { useAppDispatch } from 'hooks/index';
import { useHistory } from 'react-router-dom';

const usePage = <T>(page: Page | null): T => {
  const [data, setData] = useState<T | Record<string, unknown>>({});
  const dispatch = useAppDispatch();
  const history = useHistory();

  const fetch = async () => {
    try {
      dispatch(addLoading({ id: 'FETCH_PAGE' }));
      const response = await pageService.fetch<T>(page);

      setData(response);
    } catch (error) {
      history.push('/404');
    } finally {
      dispatch(removeLoading('FETCH_PAGE'));
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return data as T;
};

export default usePage;
