import React from 'react';
import './Switch.scss';
import classNames from 'classnames';
import { useController } from 'react-hook-form';

type Props = {
  name: string;
  control: any;
  id: string;
  children?: React.ReactNode;
  disabled?: boolean;
};

const defaultProps = {
  children: '',
  disabled: false,
};

function Switch({ name, control, id, children, disabled }: Props): JSX.Element {
  const {
    field: { ...inputProps },
  } = useController({
    name,
    control,
    defaultValue: false,
  });

  return (
    <div
      className={classNames('switch', {
        'opacity-50': disabled,
      })}
    >
      <input type="checkbox" id={id} disabled={disabled} {...inputProps} />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}

Switch.defaultProps = defaultProps;

export default Switch;
