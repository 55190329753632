import React from 'react';
import { Stage } from 'layouts';
import type { Stage as StageType } from 'helpers/types';

type Props = {
  stages: StageType[];
  idOrder: number;
  refreshFn: () => void;
  addAttachmentFn?: (idOrder: number, idStage: number) => void;
  withoutActions?: boolean;
};

const defaultProps = {
  addAttachmentFn: null,
  withoutActions: false,
};

function Stages({
  stages,
  idOrder,
  refreshFn,
  addAttachmentFn,
  withoutActions,
}: Props): JSX.Element | null {
  if (!stages || stages.length === 0) return null;

  return (
    <div className="row -mb-12 xl:-mb-16">
      {stages.map((item, i) => (
        <div className="col w-1/2 md:w-1/3 xxxl:w-1/6 mb-12 xl:mb-16" key={item.id}>
          <Stage
            idOrder={idOrder}
            {...item}
            prevStageStatus={stages[i - 1] ? stages[i - 1].status : null}
            refreshFn={refreshFn}
            addAttachmentFn={addAttachmentFn}
            withoutActions={withoutActions}
          />
        </div>
      ))}
    </div>
  );
}

Stages.defaultProps = defaultProps;

export default Stages;
