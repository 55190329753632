import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  ongoingOrders?: number | null;
  finishedOrders?: number | null;
  rankingPos?: number | null;
};

const defaultProps = {
  ongoingOrders: null,
  finishedOrders: null,
  rankingPos: null,
};

const Number = ({ children }: { children: React.ReactNode }) => (
  <p className="text-28 xl:text-32 font-bold font-headings text-primary-dk mb-8 xl:mb-16">
    {children}
  </p>
);

const Title = ({ children }: { children: React.ReactNode }) => (
  <p className="text-12 xl:text-13 leading-19 xl:leading-22 text-black-75">{children}</p>
);

function EditorStats({ ongoingOrders, finishedOrders, rankingPos }: Props): JSX.Element {
  const { t } = useTranslation(['text']);

  return (
    <div className="flex justify-between text-center -mx-8">
      {(ongoingOrders || ongoingOrders === 0) && (
        <div className="px-8 border-r border-black-5 w-full">
          <Number>{ongoingOrders}</Number>
          <Title>{t('text:ongoingOrders')}</Title>
        </div>
      )}
      {(finishedOrders || finishedOrders === 0) && (
        <div className="px-8 w-full">
          <Number>{finishedOrders}</Number>
          <Title>{t('text:finishedOrders')}</Title>
        </div>
      )}
      {(rankingPos || rankingPos === 0) && (
        <div className="px-8 border-l border-black-5 w-full">
          <Number>{rankingPos}</Number>
          <Title>{t('text:rankingPos')}</Title>
        </div>
      )}
    </div>
  );
}

EditorStats.defaultProps = defaultProps;

export default EditorStats;
