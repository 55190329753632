import { useEffect } from 'react';
import { useAppDispatch, useList } from 'hooks';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { orderService } from 'services';
import { AlertStatus, OrderList } from 'helpers/enums';
import type { Order } from 'helpers/types';
import { catchApiError } from 'helpers/error';

const useOrders = (type: OrderList, loadingId: string, perPage = 10) => {
  const dispatch = useAppDispatch();
  const list = useList<Order>(perPage);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: loadingId }));

        const params = {
          records: list.perPage,
          page: list.page,
          sortField: 'a.created',
          sortDirection: 'DESC',
        };

        const response = await orderService.orders(params, type);

        list.handleFetch(response);
      } catch (error) {
        catchApiError(error, () =>
          dispatch(
            addNotification({
              id: 'list.orders',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        );
      } finally {
        dispatch(removeLoading(loadingId));
      }
    })();
  }, [list.shouldUpdate]);

  return [list];
};

export default useOrders;
