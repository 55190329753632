import React from 'react';
import './LayoutImage.scss';

type Props = {
  children: React.ReactNode;
  image: string;
};

function LayoutImage({ children, image }: Props): JSX.Element {
  return (
    <div className="container px-0 lg:px-16 xl:px-12">
      <div className="layout-image">
        <div className="row lg:flex-nowrap">
          <div className="col layout-image__col px-24 lg:px-8 xl:px-12">
            <div className="my-40 lg:mt-48 lg:mb-96">{children}</div>
          </div>
          <div className="col layout-image__out-col">
            <img src={image} alt="" className="layout-image__bg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutImage;
