export enum Role {
  client = 'ROLE_CLIENT',
  editor = 'ROLE_EDITOR',
}

export enum OrderList {
  ongoing = 'ONGOING',
  offers = 'OFFERS',
  finished = 'FINISHED',
  undefined = 'UNDEFINED',
}

export enum PaymentList {
  unpaid = 'UNPAID',
  paid = 'PAID',
}

export enum AlertStatus {
  success = 'SUCCESS',
  info = 'INFO',
  error = 'ERROR',
  warning = 'WARNING',
}

export enum OfferStatus {
  accepted = 'accepted',
  finished = 'finished',
  canceled = 'canceled',
  created = 'created',
}

export enum StageStatus {
  unpaid = 'unpaid',
  paid = 'paid',
  ongoing = 'in-progress',
  correction = 'fixes',
  accepted = 'finished',
}

export enum PaymentStatus {
  paid = 'finished',
}

export enum Page {
  home = 'home-page',
  about = 'about',
  editors = 'editors-list',
  regulations = 'rules',
  privacy = 'privacy',
  faq = 'faq',
  contact = 'contact',
}
