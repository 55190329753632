import React from 'react';
import { Alert, Button, Pagination } from 'components';
import { Order, OrderSummary } from 'layouts';
import Loading from 'features/loading/Loading';
import { useTranslation } from 'react-i18next';
import { AlertStatus, OfferStatus, OrderList } from 'helpers/enums';
import { useAppDispatch, useLoading, useOrders } from 'hooks';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { editorService } from 'services';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { catchApiError } from 'helpers/error';

const Offers = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['alert', 'button', 'text']);
  const [list] = useOrders(OrderList.offers, 'EDITOR_ORDERS_OFFERS');
  const loading = useLoading();

  const handleCancel = async (id: number) => {
    try {
      dispatch(addLoading({ id: `OFFER_CANCEL_${id}` }));

      await editorService.cancelOffer(id);

      dispatch(
        addNotification({
          id: 'offer.cancel',
          status: AlertStatus.success,
          message: t('alert:cancelOffer'),
        }),
      );

      list.setPage(1);
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'offer.cancel',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    } finally {
      dispatch(removeLoading(`OFFER_CANCEL_${id}`));
    }
  };

  const renderOrders = () =>
    list.items.map((item) => {
      return (
        <Order key={item.id} {...item}>
          <OrderSummary
            idOrder={item.id}
            offers={item.offers}
            titlePrice={t('text:yourOffer')}
            status={OfferStatus.created}
          >
            <div className="col w-auto mb-12">
              <Button
                as="button"
                to=""
                onClick={() => handleCancel(item.id)}
                loading={loading.check([`OFFER_CANCEL_${item.id}`])}
              >
                {t('button:cancelOffer')}
              </Button>
            </div>
          </OrderSummary>
        </Order>
      );
    });

  return (
    <Loading items={['EDITOR_ORDERS_OFFERS']}>
      {list.items.length ? (
        renderOrders()
      ) : (
        <Alert status={AlertStatus.info} message={t('alert:noResults')} />
      )}
      <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
    </Loading>
  );
};

export default Offers;
