import api, { ApiError, withAuthorizationHeader } from 'services';
import qs from 'qs';

export const remove = async (id: number | string): Promise<void> => {
  try {
    await api.delete(`attachment/${String(id)}`, {
      headers: withAuthorizationHeader(),
    });
  } catch (error) {
    console.log('Fail remove attachment');
  }
};

export const zipFiles = async (files: string[]): Promise<any> => {
  try {
    return await api
      .get('attachment/zip', {
        headers: withAuthorizationHeader(),
        searchParams: qs.stringify({ files }),
      })
      .blob();
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};
