import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Input } from 'components';
import { useFieldsErrors, useAppDispatch } from 'hooks';
import { authService } from 'services';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { AlertStatus } from 'helpers/enums';
import { catchApiError } from 'helpers/error';
import { useTranslation } from 'react-i18next';

type Inputs = {
  email: string;
};

const schema = yup.object().shape({
  email: yup.string().email('Nieprawidłowy adres e-mail').required('To pole jest wymagane'),
});

function ForgotPassword(): JSX.Element {
  const { t } = useTranslation(['alert']);
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const dispatch = useAppDispatch();
  useFieldsErrors('form-forgot-password', errors);

  const onSubmit = async ({ email }: Inputs) => {
    try {
      await authService.forgotPassword(email);

      dispatch(
        addNotification({
          id: 'auth.forgotPassword',
          status: AlertStatus.success,
          message: t('alert:sentMail'),
        }),
      );

      reset();
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'auth.login',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input control={control} name="email" placeholder="Adres e-mail" />
      <div className="mt-16">
        <Button as="submit" to="" loading={isSubmitting}>
          Resetuj hasło
        </Button>
      </div>
    </form>
  );
}

export default ForgotPassword;
