import apiClient, { withAuthorizationHeader } from './apiClient';
import ApiError from './ApiError';

import * as globalsService from './globals';
import * as authService from './auth';
import * as editorService from './editor';
import * as clientService from './client';
import * as attachmentService from './attachment';
import * as orderService from './order';
import * as chatService from './chat';
import * as pageService from './page';
import * as publicService from './public';
import * as badgesService from './badges';

export default apiClient;

export {
  ApiError,
  withAuthorizationHeader,
  globalsService,
  authService,
  editorService,
  clientService,
  attachmentService,
  orderService,
  chatService,
  pageService,
  publicService,
  badgesService,
};
