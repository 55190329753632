import React from 'react';
import { Button, Icon } from 'components';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  open: boolean;
  selected: boolean;
  error: boolean;
  disabled?: boolean;
  resetFn: any;
  [x: string]: any;
};

const defaultProps = {
  disabled: false,
};

function Trigger({
  children,
  open,
  selected,
  error,
  disabled,
  resetFn,
  ...rest
}: Props): JSX.Element {
  return (
    <div
      className={classNames('flex border', {
        'bg-black-5': !error && !open,
        'border-black-5': !error && !open && !selected,
        'border-primary': !error && !open && selected,
        'bg-white border-primary': open,
        'bg-error-75 border-error-75': error && !open,
        'opacity-50': disabled,
      })}
    >
      <button
        type="button"
        className={classNames(
          'w-full min-h-40 xl:min-h-48 px-16 text-black-75 text-15 xl:text-16 text-left flex items-center justify-between truncate',
          {
            'cursor-not-allowed': disabled,
          },
        )}
        disabled={disabled}
        {...rest}
      >
        {children}
        {(!selected || !resetFn) && (
          <Icon
            icon="arrowDown"
            beforeInjection={(svg) => {
              svg.classList.add('h-10', 'fill-primary');
            }}
          />
        )}
      </button>
      {selected && resetFn && (
        <Button
          as="button"
          to=""
          icon="close"
          onClick={resetFn}
          transparent
          className="w-48"
          disabled={disabled}
        />
      )}
    </div>
  );
}

Trigger.defaultProps = defaultProps;

export default Trigger;
