import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import type { OptionItem } from 'helpers/types';
import { globalsService } from 'services';
import { RootState } from 'store';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';

type Globals = {
  countries: OptionItem[];
  languages: OptionItem[];
  educations: OptionItem[];
  activityFields: OptionItem[];
  scopeOfOrders: OptionItem[];
  typesOfStudy: OptionItem[];
  genders: OptionItem[];
};

type Keys =
  | 'genders'
  | 'countries'
  | 'languages'
  | 'educations'
  | 'activityFields'
  | 'scopeOfOrders'
  | 'typesOfStudy';

const initialState = {
  countries: [],
  languages: [],
  educations: [],
  activityFields: [],
  scopeOfOrders: [],
  typesOfStudy: [],
  genders: [
    {
      id: 'female',
      name: 'Kobieta',
    },
    {
      id: 'male',
      name: 'Mężczyzna',
    },
  ],
} as Globals;

export const fetchCountries = createAsyncThunk('globals/fetchCountries', async () => {
  return globalsService.fetchCountries();
});

export const fetchLanguages = createAsyncThunk('globals/fetchLanguages', async () => {
  return globalsService.fetchLanguages();
});

export const fetchEducations = createAsyncThunk('globals/fetchEducations', async () => {
  return globalsService.fetchEducations();
});

export const fetchActivityFields = createAsyncThunk('globals/fetchActivityFields', async () => {
  return globalsService.fetchActivityFields();
});

export const fetchScopeOfOrders = createAsyncThunk('globals/fetchScopeOfOrders', async () => {
  return globalsService.fetchScopeOfOrders();
});

export const fetchTypesOfStudy = createAsyncThunk('globals/fetchTypesOfStudy', async () => {
  return globalsService.fetchTypesOfStudy();
});

export const fetchGlobals = createAsyncThunk(
  'globals/fetch',
  async (keys: Keys[], { getState, dispatch }): Promise<void> => {
    const { globals } = getState() as RootState;
    const creators: Promise<any>[] = [];

    dispatch(addLoading({ id: 'FETCH_GLOBALS' }));

    keys.forEach((key) => {
      if (!globals[key].length) {
        switch (key) {
          case 'countries':
            creators.push(dispatch(fetchCountries()));
            break;
          case 'languages':
            creators.push(dispatch(fetchLanguages()));
            break;
          case 'educations':
            creators.push(dispatch(fetchEducations()));
            break;
          case 'activityFields':
            creators.push(dispatch(fetchActivityFields()));
            break;
          case 'scopeOfOrders':
            creators.push(dispatch(fetchScopeOfOrders()));
            break;
          case 'typesOfStudy':
            creators.push(dispatch(fetchTypesOfStudy()));
            break;
          default:
        }
      }
    });

    await Promise.all(creators);

    dispatch(removeLoading('FETCH_GLOBALS'));

    return Promise.resolve();
  },
);

const slice = createSlice({
  name: 'globals',
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchCountries.fulfilled}`]: (state, action) => {
      state.countries = action.payload;
    },
    [`${fetchLanguages.fulfilled}`]: (state, action) => {
      state.languages = action.payload;
    },
    [`${fetchEducations.fulfilled}`]: (state, action) => {
      state.educations = action.payload;
    },
    [`${fetchActivityFields.fulfilled}`]: (state, action) => {
      state.activityFields = action.payload;
    },
    [`${fetchScopeOfOrders.fulfilled}`]: (state, action) => {
      state.scopeOfOrders = action.payload;
    },
    [`${fetchTypesOfStudy.fulfilled}`]: (state, action) => {
      state.typesOfStudy = action.payload;
    },
  },
});

export const selectorGlobals = createSelector(
  (state: RootState) => state.globals,
  (globals) => globals,
);

export default slice.reducer;
