import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

function Cookie(): JSX.Element {
  const { t } = useTranslation(['text', 'button']);
  const [cookie, setCookie] = useState<boolean>(false);

  const checkCookie = useCallback(() => {
    const parts = `; ${document.cookie}`.split(`; dcookie=`);
    const check = parts.length === 2 ? parts.pop()?.split(';').shift() : false;

    setCookie(!!check);
  }, []);

  const handleSetCookie = useCallback(() => {
    const date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

    document.cookie = `dcookie=true; expires=${date.toUTCString()}; path=/`;

    checkCookie();
  }, []);

  useEffect(() => {
    checkCookie();
  }, []);

  return (
    <div
      className={classNames(
        'fixed right-0 bottom-0 z-30 w-full px-16 py-24 text-center sm:p-24 sm:max-w-320 bg-primary',
        {
          hidden: cookie,
        },
      )}
    >
      <p
        className="text-12 xl:text-13 leading-19 xl:leading-22 text-white"
        dangerouslySetInnerHTML={{ __html: t('text:cookie') }}
      />
      <div className="mt-24">
        <Button as="button" to="" onClick={handleSetCookie}>
          {t('button:cookieAccept')}
        </Button>
      </div>
    </div>
  );
}

export default Cookie;
