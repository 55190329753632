import React from 'react';
import { useSelect } from 'downshift';
import { InputError, InputLabel } from 'components';
import { useController } from 'react-hook-form';
import type { EmitOnChange, OptionItem } from 'helpers/types';
import Trigger from './Trigger';
import Content from './Content';
import Item from './Item';

type Props = {
  items: OptionItem[];
  name: string;
  control: any;
  disabled?: boolean;
  label?: string;
  onChangeEmit?: (v: EmitOnChange) => void;
};

const defaultProps = {
  disabled: false,
  label: '',
  onChangeEmit: null,
};

function Select({ items, name, control, disabled, label, onChangeEmit }: Props): JSX.Element {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: null,
  });

  const selectedItem = items.find((item) => String(item.id) === String(value)) || null;

  const {
    reset,
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getLabelProps,
    highlightedIndex,
  } = useSelect({
    items,
    itemToString: (item) => (item ? String(item.id) : ''),
    selectedItem,
    onSelectedItemChange: (option) => {
      const v = option.selectedItem ? String(option.selectedItem.id) : null;
      onChange(v);

      if (onChangeEmit) {
        onChangeEmit({
          name,
          value: v,
        });
      }
    },
  });

  return (
    <>
      {label && <InputLabel {...getLabelProps()}>{label}</InputLabel>}
      <div className="relative mb-12 xl:mb-16">
        <Trigger
          open={isOpen}
          selected={!!selectedItem}
          error={!!error}
          resetFn={reset}
          {...getToggleButtonProps({ disabled })}
        >
          {selectedItem ? selectedItem.name : 'Wybierz z listy...'}
        </Trigger>
        <Content {...getMenuProps()} isOpen={isOpen}>
          {isOpen &&
            items.map((item, index) => (
              <Item
                key={`${item.id}`}
                active={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                {item.name}
              </Item>
            ))}
        </Content>
        <InputError message={error?.message} />
      </div>
    </>
  );
}

Select.defaultProps = defaultProps;

export default Select;
