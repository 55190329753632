import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { useRoutes, useAccessibility } from 'hooks';

function Root(): JSX.Element {
  const [renderRoutes] = useRoutes(routes);
  const location = useLocation();
  useAccessibility();

  useEffect(() => {
    if (location.hash) {
      const target = document.querySelector(location.hash);
      if (target) {
        target.scrollIntoView();
        window.scrollBy(0, -110);
      }
    } else {
      window.scrollTo(0, 0);
    }

    document.dispatchEvent(new CustomEvent('userAction'));
  }, [location]);

  return renderRoutes();
}

export default Root;
