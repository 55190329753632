import { configureStore } from '@reduxjs/toolkit';
import globalsReducer from 'features/globals/globalsSlice';
import loadingReducer from 'features/loading/loadingSlice';
import disabledReducer from 'features/disabled/disabledSlice';
import notificationsReducer from 'features/notifications/notificationsSlice';
import badgesReducer from 'features/badges/badgesSlice';
import modalsReducer from 'features/modals/modalsSlice';

export const store = configureStore({
  reducer: {
    globals: globalsReducer,
    loading: loadingReducer,
    disabled: disabledReducer,
    notifications: notificationsReducer,
    badges: badgesReducer,
    modals: modalsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
