import React from 'react';
import type { Props as ButtonType } from 'components/Button/Button';
import './Hero.scss';
import classNames from 'classnames';
import { Button } from 'components';
import type { ImageBreakpoint } from 'helpers/types';

export type Props = {
  title?: string;
  subtitle?: string;
  description?: string;
  button?: ButtonType | null;
  buttonExtra?: ButtonType | null;
  image?: ImageBreakpoint | null;
};

const defaultProps = {
  title: '',
  subtitle: '',
  description: '',
  button: null,
  buttonExtra: null,
  image: null,
};

function Hero({ title, subtitle, description, button, buttonExtra, image }: Props): JSX.Element {
  return (
    <header className="hero">
      <div
        className={classNames('hero__wrapper', {
          'py-40 xl:py-96': !image,
          'pt-40 lg:pb-40 xl:py-96 xl:min-h-600 flex items-center': image,
        })}
        style={image ? { backgroundImage: `url(${image.urlLarge})` } : {}}
      >
        <div className="container">
          <div className="row">
            <div className="col xl:w-1/2">
              {title && (
                <h1 className="text-26 xl:text-48 leading-34 xl:leading-60 font-bold font-headings text-black mb-12 xl:mb-16">
                  {title}
                </h1>
              )}
              {subtitle && (
                <p className="text-20 xl:text-24 leading-24 xl:leading-32 font-bold font-headings text-black mb-12 xl:mb-16">
                  {subtitle}
                </p>
              )}
              {description && (
                <p
                  className="text-18 xl:text-20 leading-29 xl:leading-32 font-semibold text-black-75"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}

              {button || buttonExtra ? (
                <div className="row mt-24 xl:mt-40">
                  {button && (
                    <div className="col sm:w-auto">
                      <Button {...button} full />
                    </div>
                  )}
                  {buttonExtra && (
                    <div className="col sm:w-auto mt-12 sm:mt-0">
                      <Button {...buttonExtra} secondary full />
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {image && (
        <img
          src={image.url}
          alt={image.alt}
          className="object-cover object-center w-full min-h-300 lg:mt-32 lg:mt-0 lg:hidden"
        />
      )}
    </header>
  );
}

Hero.defaultProps = defaultProps;

export default Hero;
