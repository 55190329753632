import React from 'react';

export type Props = {
  title: string;
  description?: string;
};

const defaultProps = {
  description: '',
};

function HeroText({ title, description }: Props): JSX.Element {
  return (
    <header className="bg-primary-lt50 py-40 xl:py-48">
      <div className="container">
        <h1 className="text-black text-32 xl:text-48 font-bold font-headings">{title}</h1>
        {description && (
          <p
            className="mt-16 xl:mt-24 text-15 xl:text-16 leading-25 xl:leading-28 text-black-75"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </header>
  );
}

HeroText.defaultProps = defaultProps;

export default HeroText;
