import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'components';
import { useAppDispatch } from 'hooks';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { editorService } from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { catchApiError } from 'helpers/error';
import { formatPrice } from 'helpers/price';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';

type Props = {
  id: number;
  onRequestClose: () => void;
};

type Inputs = {
  amount: number;
  message: string;
};

const schema = yup.object().shape({
  amount: yup.mixed().required('To pole jest wymagane'),
  message: yup.string().required('To pole jest wymagane'),
});

function EditorAddOffer({ id, onRequestClose }: Props): JSX.Element {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async ({ amount, message }: Inputs) => {
    try {
      await editorService.addOffer({
        textOrderId: id,
        amount: formatPrice(+amount),
        message,
      });

      onRequestClose();

      dispatch(
        addNotification({
          id: 'editor.addOffer',
          status: AlertStatus.success,
          message: t('alert:addOffer'),
        }),
      );
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'editor.addOffer',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col w-2/3 md:w-1/3">
          <Input
            type="number"
            control={control}
            name="amount"
            placeholder="Wpisz kwotę"
            label="Twoja oferta *"
          />
        </div>
        <div className="col">
          <Input
            type="textarea"
            control={control}
            name="message"
            placeholder="Wpisz"
            label="Wiadomość do klienta *"
          />
        </div>
      </div>
      <div className="row mt-12">
        <div className="col w-auto mb-8 sm:mb-0">
          <Button as="submit" to="" loading={isSubmitting}>
            Wyślij ofertę
          </Button>
        </div>
        <div className="col w-auto mb-8 sm:mb-0">
          <Button as="button" to="" secondary disabled={isSubmitting} onClick={onRequestClose}>
            Anuluj
          </Button>
        </div>
      </div>
    </form>
  );
}

export default EditorAddOffer;
