import React from 'react';
import { PageTitle } from 'components';
import { ClientAddOrder } from 'forms';

const AddOrder = (): JSX.Element => {
  return (
    <>
      <PageTitle>Dodaj zlecenie</PageTitle>
      <ClientAddOrder />
    </>
  );
};

export default AddOrder;
