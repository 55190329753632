import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'components';
import { useAppDispatch } from 'hooks';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { chatService } from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { catchApiError } from 'helpers/error';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';

type Props = {
  id: number;
  onSuccess: () => void;
};

type Inputs = {
  content: string;
};

const schema = yup.object().shape({
  content: yup.string().required('To pole jest wymagane'),
});

function ChatAddMessage({ id, onSuccess }: Props): JSX.Element {
  const { t } = useTranslation(['alert', 'button']);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async ({ content }: Inputs) => {
    try {
      await chatService.add(id, content);

      dispatch(
        addNotification({
          id: 'chat.add',
          status: AlertStatus.success,
          message: t('alert:addChat'),
        }),
      );

      reset();
      onSuccess();
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'chat.add',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-nowrap items-start">
        <div className="w-full">
          <Input
            control={control}
            name="content"
            type="textarea-short"
            placeholder="Wpisz wiadomość..."
          />
        </div>
        <Button as="submit" to="" loading={isSubmitting} icon="send" />
      </div>
    </form>
  );
}

export default ChatAddMessage;
