import React from 'react';
import './Radio.scss';
import classNames from 'classnames';
import { InputError } from 'components';

export type RadioItem = {
  id: string | number;
  name: string;
};

type Props = {
  name: string;
  register: any;
  idPrefix: string;
  items: RadioItem[];
  error?: any;
  disabled?: boolean;
  className?: string;
  itemWrapper?: any;
};

const defaultProps = {
  error: null,
  disabled: false,
  className: '',
  itemWrapper: null,
};

const DefaultItemWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="mb-12 xl:mb-16">{children}</div>
);

function Radio({
  name,
  register,
  idPrefix,
  items,
  error,
  disabled,
  className: classNameExtend,
  itemWrapper,
}: Props): JSX.Element {
  const renderItem = (item: RadioItem) => {
    const id = `${idPrefix}-${item.id}`;
    const ItemWrapper = itemWrapper || DefaultItemWrapper;

    return (
      <ItemWrapper key={id}>
        <div
          className={classNames('radio', {
            'radio--error': error,
            'opacity-50': disabled,
          })}
        >
          <input type="radio" id={id} {...register(name)} value={item.id} disabled={disabled} />
          <label htmlFor={id}>{item.name}</label>
        </div>
      </ItemWrapper>
    );
  };

  return (
    <div className="mb-12 xl:mb-16">
      <div className={classNameExtend}>{items.map((item) => renderItem(item))}</div>
      <InputError message={error?.message} />
    </div>
  );
}

Radio.defaultProps = defaultProps;

export default Radio;
