import React from 'react';
import type { Props as ButtonType } from 'components/Button/Button';
import { Button } from 'components';
import classNames from 'classnames';

export type Props = {
  label?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  button?: ButtonType | null;
  small?: boolean;
};

const defaultProps = {
  label: '',
  title: '',
  subtitle: '',
  description: '',
  button: null,
  small: false,
};

function Cta({ label, title, subtitle, description, button, small }: Props): JSX.Element {
  return (
    <div>
      {label && (
        <p className="text-12 xl:text-13 leading-19 xl:leading-22 font-semibold text-primary-dk uppercase mb-4 xl:mb-8">
          {label}
        </p>
      )}
      {title && (
        <p
          className={classNames('text-black font-bold font-headings mb-8 xl:mb-16', {
            'text-28 xl:text-40 leading-34 xl:leading-50': !small,
            'text-26 xl:text-32 leading-32 xl:leading-40': small,
          })}
        >
          {title}
        </p>
      )}
      {subtitle && (
        <p className="text-18 xl:text-20 leading-22 xl:leading-28 text-black font-bold font-headings mb-12 xl:mb-24">
          {subtitle}
        </p>
      )}
      {description && (
        <p
          className="text-15 xl:text-16 leading-25 xl:leading-28 text-black-75"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {button && (
        <div className="mt-16 xl:mt-40">
          <Button {...button} />
        </div>
      )}
    </div>
  );
}

Cta.defaultProps = defaultProps;

export default Cta;
