import React from 'react';
import { Icon } from 'components';
import classNames from 'classnames';

export type Props = {
  rating: number | string;
  small?: boolean;
  large?: boolean;
};

const defaultProps = {
  small: false,
  large: false,
};

function Rating({ rating, small, large }: Props): JSX.Element {
  const isDefault = !small && !large;

  return (
    <div
      className={classNames('flex items-center font-semibold text-black', {
        'text-15 xl:text-16': isDefault,
        'text-12 xl:text-13': small,
        'text-18 xl:text-20': large,
      })}
    >
      <Icon
        icon="star"
        className="pointer-events-none mr-8"
        beforeInjection={(svg) => {
          svg.classList.add('fill-accent-dk');

          if (large) {
            svg.classList.add('w-24', 'h-24');
          } else {
            svg.classList.add('w-16', 'h-16');
          }
        }}
      />
      {`${Number.parseFloat(String(rating)).toPrecision(2)} / 10`}
    </div>
  );
}

Rating.defaultProps = defaultProps;

export default Rating;
