import qs from 'qs';
import api, { ApiError, withAuthorizationHeader } from 'services';
import { parseAttachment } from 'helpers/attachments';
import type { ListApiResponse, ListRequest, ListResponse, Message } from 'helpers/types';

export const fetch = async (id: number, params: ListRequest): Promise<ListResponse<Message>> => {
  try {
    const { records, total }: ListApiResponse = await api
      .get(`offer/${id}/chat`, {
        headers: withAuthorizationHeader(),
        searchParams: qs.stringify(params),
      })
      .json();

    return {
      items: records
        .map(({ id: idMessage, content, unread, sender, created }) => ({
          id: idMessage,
          content,
          unread,
          user: {
            id: sender.id,
            name: sender.name,
            profilePhoto: sender.profilePhoto ? parseAttachment(sender.profilePhoto) : null,
          },
          created,
        }))
        .reverse(),
      total,
    };
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const add = async (id: number, content: string): Promise<void> => {
  try {
    await api.post(`offer/${id}/chat`, {
      headers: withAuthorizationHeader(),
      json: {
        content,
      },
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};
