import React from 'react';
import { useRoutes, useRoutesFirst } from 'hooks';
import { useRouteMatch } from 'react-router-dom';
import { Route } from 'routes';
import { PageTitle, Tabs } from 'components';
import type { TabRoute } from 'helpers/types';

type Props = {
  routes: Route[];
};

const tabs: TabRoute[] = [
  {
    key: 'tabUnpaid',
    name: 'Zlecenia nieopłacone',
    path: '/nieoplacone',
  },
  {
    key: 'tabPaid',
    name: 'Zlecenia opłacone',
    path: '/oplacone',
  },
  {
    key: 'tabDownload',
    name: 'Do pobrania',
    path: '/do-pobrania',
  },
];

function Payments({ routes }: Props): JSX.Element {
  const { path } = useRouteMatch();
  const [renderRoutes] = useRoutes(routes, path);
  useRoutesFirst(routes);

  return (
    <>
      <PageTitle>Rozliczenia</PageTitle>
      <Tabs routes={tabs} path={path} />
      <div className="mt-32 xl:mt-40">{renderRoutes()}</div>
    </>
  );
}

export default Payments;
