import React from 'react';
import { useAppSelector } from 'hooks';
import { Notification } from 'components';
import { selectors, Notification as NotificationType } from './notificationsSlice';

const Notifications = (): JSX.Element => {
  const items = useAppSelector(selectors.selectAll);

  return (
    <div className="fixed z-50 w-full max-w-272 sm:max-w-320 top-24 right-24">
      {items.map(({ id, status, message, description }: NotificationType) => (
        <Notification
          key={id}
          id={id}
          status={status}
          message={message}
          description={description}
        />
      ))}
    </div>
  );
};

export default Notifications;
