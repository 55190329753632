import React, { useEffect } from 'react';
import { Alert, Cms, Pagination } from 'components';
import { PageContent, HeroText, EditorThumb } from 'layouts';
import Loading from 'features/loading/Loading';
import { AlertStatus, Page } from 'helpers/enums';
import { useAppDispatch, useList, usePage } from 'hooks';
import type { Editor } from 'helpers/types';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { publicService } from 'services';
import { useTranslation } from 'react-i18next';
import { EditorsPage } from 'services/page/types';

const Editors = (): JSX.Element => {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const { hero, content }: EditorsPage = usePage<EditorsPage>(Page.editors);
  const list = useList<Editor>(15);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_EDITORS' }));

        const params = {
          records: list.perPage,
          page: list.page,
        };

        const response = await publicService.fetchEditors(params);

        list.handleFetch(response);
      } catch (error) {
        console.error('FETCH_EDITORS');
      } finally {
        dispatch(removeLoading('FETCH_EDITORS'));
      }
    })();
  }, [list.shouldUpdate]);

  return (
    <>
      <Loading items={['FETCH_PAGE']}>
        {hero && <HeroText {...hero} />}
        {content && (
          <section className="my-64 xl:my-96">
            <div className="container">
              <Cms>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </Cms>
            </div>
          </section>
        )}
      </Loading>
      <PageContent>
        <Loading items={['FETCH_EDITORS']}>
          <div className="container">
            {list.items.length ? (
              <div className="row -mb-16 lg:-mb-24">
                {list.items.map((item) => (
                  <div className="col md:w-1/2 xl:w-1/3 mb-16 lg:mb-24" key={item.id}>
                    <EditorThumb user={item} publicLink />
                  </div>
                ))}
              </div>
            ) : (
              <Alert status={AlertStatus.info} message={t('alert:noResults')} />
            )}
            <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
          </div>
        </Loading>
      </PageContent>
    </>
  );
};

export default Editors;
