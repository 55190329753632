import React from 'react';
import { IconAlert } from 'components';
import { AlertStatus } from 'helpers/enums';

type Props = {
  status: AlertStatus;
  message: string;
  description?: string;
};

const defaultProps = {
  description: '',
};

function Alert({ status, message, description }: Props): JSX.Element {
  return (
    <div role="alert" className="bg-black-5 rounded-2 bg-white p-24 mb-16">
      <div className="flex">
        <div className="-mt-2 mr-8">
          <IconAlert status={status} />
        </div>
        <div>
          <p>{message}</p>
          {description && <p className="text-13 mt-12">{description}</p>}
        </div>
      </div>
    </div>
  );
}

Alert.defaultProps = defaultProps;

export default Alert;
