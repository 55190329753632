import React from 'react';
import { useTranslation } from 'react-i18next';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { useAppDispatch } from '../../hooks';
import { AlertStatus } from '../../helpers/enums';

type Props = {
  text: string;
};

const defaultProps = {};

function ButtonCopy({ text }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['button', 'alert']);

  const handleClick = () => {
    navigator.clipboard.writeText(text);

    dispatch(
      addNotification({
        id: `copy.${new Date().getTime()}`,
        status: AlertStatus.success,
        message: t('alert:copied'),
      }),
    );
  };

  return (
    <button
      type="button"
      className="min-h-20 rounded-switch font-semibold text-white text-8 px-8 py-4 uppercase flex items-center text-center bg-black"
      onClick={handleClick}
    >
      {t('button:copy')}
    </button>
  );
}

ButtonCopy.defaultProps = defaultProps;

export default ButtonCopy;
