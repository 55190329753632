import React from 'react';
import { formatViewPrice } from 'helpers/price';
import classNames from 'classnames';

type Props = {
  title?: string;
  price: number;
  transparent?: boolean;
};

const defaultProps = {
  title: '',
  transparent: false,
};

function Price({ title, price, transparent }: Props): JSX.Element {
  return (
    <div
      className={classNames('text-center', {
        'bg-primary-lt py-12 px-32 xl:py-16 xl:px-64': !transparent,
      })}
    >
      {title && (
        <p className="text-12 xl:text-13 font-semibold uppercase text-primary-dk mb-8">{title}</p>
      )}
      <p className="font-headings font-bold text-black text-20 xl:text-24">
        {formatViewPrice(price)}&nbsp;zł
      </p>
    </div>
  );
}

Price.defaultProps = defaultProps;

export default Price;
