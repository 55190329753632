import { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Route as RouteType } from 'routes';

const useRoutesFirst = (routes: RouteType[]): void => {
  const { url, isExact } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (isExact) {
      history.push(`${url}${routes[0].path}`);
    }
  }, [isExact]);
};

export default useRoutesFirst;
