import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Checkbox, Input } from 'components';
import { useAppDispatch, useFieldsErrors } from 'hooks';
import { authService } from 'services';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';
import { catchApiError } from 'helpers/error';

type Inputs = {
  username: string;
  password: string;
  remember: boolean;
};

const schema = yup.object().shape({
  username: yup.string().email('Nieprawidłowy adres e-mail').required('To pole jest wymagane'),
  password: yup.string().required('To pole jest wymagane'),
});

function Login(): JSX.Element {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (query.has('logout')) {
      dispatch(
        addNotification({
          id: 'auth.logout',
          status: AlertStatus.success,
          message: t('alert:logout'),
        }),
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams(history.location.search);
      if (query.has('token')) {
        try {
          await authService.confirmRegister(query.get('token') || '');

          history.replace({
            search: '',
          });

          dispatch(
            addNotification({
              id: 'auth.confirmRegister',
              status: AlertStatus.success,
              message: t('alert:accountConfirmed'),
            }),
          );
        } catch (error) {
          catchApiError(error, () =>
            dispatch(
              addNotification({
                id: 'auth.confirmRegister',
                status: AlertStatus.error,
                message: error.message,
              }),
            ),
          );
        }
      }
    })();
  }, []);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useFieldsErrors('form-login', errors);

  const onSubmit = async (data: Inputs) => {
    try {
      const response = await authService.login(data);
      authService.setUser(response.user, data.remember);
      authService.setToken(response.token, data.remember);
      authService.redirect();
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'auth.login',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input control={control} name="username" placeholder="Adres e-mail" />
      <Input type="password" control={control} name="password" placeholder="Hasło" />
      <Checkbox name="remember" control={control} id="remember">
        Zapamiętaj mnie
      </Checkbox>
      <div className="mt-16">
        <Button as="submit" to="" loading={isSubmitting}>
          Zaloguj się
        </Button>
      </div>
    </form>
  );
}

export default Login;
