import React from 'react';

type Props = {
  children: React.ReactNode;
  id?: string;
  htmlFor?: string;
};

const defaultProps = {
  id: '',
  htmlFor: '',
};

function InputLabel({ children, id, htmlFor }: Props): JSX.Element {
  return (
    <label
      htmlFor={htmlFor || id}
      className="block text-12 xl:text-13 font-semibold uppercase mb-4 xl:mb-8 text-black-50"
    >
      {children}
    </label>
  );
}

InputLabel.defaultProps = defaultProps;

export default InputLabel;
