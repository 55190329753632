import React, { useEffect, useState } from 'react';
import type { Editor as EditorType } from 'helpers/types';
import { EditorThumb } from 'layouts';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { Icon } from 'components';
import './EditorThumbSlider.scss';
import classNames from 'classnames';

SwiperCore.use([Pagination]);

type Props = {
  users: EditorType[];
};

const defaultProps = {};

function EditorThumbSlider({ users }: Props): JSX.Element {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  const [si, setSi] = useState(null);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  const handleChange = () => {
    if (si) {
      // @ts-ignore
      setPrevDisabled(si.isBeginning);
      // @ts-ignore
      setNextDisabled(si.isEnd);
    }
  };

  useEffect(() => {
    handleChange();
  }, [si]);

  const handleClick = (type: string) => {
    if (si) {
      if (type === 'prev') {
        // @ts-ignore
        si.slidePrev();
      } else {
        // @ts-ignore
        si.slideNext();
      }
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="w-48 hidden lg:block">
          <button
            type="button"
            onClick={() => handleClick('prev')}
            className={classNames('w-48 h-48 flex items-center justify-center', {
              'opacity-20 pointer-events-none': prevDisabled,
            })}
            disabled={prevDisabled}
          >
            <Icon
              icon="arrowLeftBold"
              beforeInjection={(svg) => {
                svg.classList.add('pointer-events-none', 'w-24', 'h-24', 'fill-black');
              }}
            />
          </button>
        </div>
        <Swiper
          // @ts-ignore
          onSwiper={setSi}
          spaceBetween={24}
          slidesPerView={1}
          onSlideChange={handleChange}
          pagination={{
            el: '.custom-pagination',
            clickable: true,
            renderBullet: (index, className) => {
              return `<button class="${className}" type="button" />`;
            },
          }}
        >
          {users.map((u) => (
            <SwiperSlide key={u.id} className="w-auto">
              <EditorThumb user={u} publicLink />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="w-48 hidden lg:block">
          <button
            type="button"
            onClick={() => handleClick('next')}
            className={classNames('w-48 h-48 flex items-center justify-center', {
              'opacity-20 pointer-events-none': nextDisabled,
            })}
            disabled={nextDisabled}
          >
            <Icon
              icon="arrowRightBold"
              beforeInjection={(svg) => {
                svg.classList.add('pointer-events-none', 'w-24', 'h-24', 'fill-black');
              }}
            />
          </button>
        </div>
      </div>
      <div className="custom-pagination flex justify-center mt-16 xl:mt-24 -mx-8" />
    </div>
  );
  /* eslint-enable @typescript-eslint/ban-ts-comment */
}

EditorThumbSlider.defaultProps = defaultProps;

export default EditorThumbSlider;
