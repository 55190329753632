import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import type { EmitOnChange } from 'helpers/types';
import Header from './Header';
import Label from './Label';
import Text from './Text';
import type { Props as HeaderType } from './Header';

export type Props = {
  children?: React.ReactNode;
  open?: boolean;
  header: (v: HeaderType) => React.ReactNode;
  onChangeEmit?: (v: EmitOnChange) => void;
};

const defaultProps = {
  children: '',
  open: false,
  onChangeEmit: null,
};

function Box({ children, open, header, onChangeEmit }: Props): JSX.Element {
  const [active, setActive] = useState<boolean>(!!open);

  useEffect(() => {
    if (onChangeEmit) {
      onChangeEmit({
        name: 'Box',
        value: active,
      });
    }
  }, [active]);

  return (
    <div className="mb-16">
      {header({ active, toggleFn: () => setActive(!active) })}

      <div
        className={classNames('bg-black-2 px-12 xl:px-24 pb-12 xl:pb-16', {
          hidden: !active,
        })}
      >
        {children && <div className="border-t border-black-10 pt-12 xl:pt-16">{children}</div>}
      </div>
    </div>
  );
}

Box.defaultProps = defaultProps;

Box.Header = Header;
Box.Label = Label;
Box.Text = Text;

export default Box;
