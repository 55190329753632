import React from 'react';
import { Icon } from 'components';
import classNames from 'classnames';

export type Props = {
  type: 'prev' | 'next';
  disabled: boolean;
  onClick: () => void;
};

function Arrow({ type, disabled, onClick }: Props): JSX.Element {
  return (
    <button
      type="button"
      className={classNames('w-40 xl:w-48 h-40 xl:h-48 flex justify-center items-center', {
        'opacity-20 pointer-events-none': disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon
        icon={type === 'prev' ? 'arrowLeft' : 'arrowRight'}
        beforeInjection={(svg) => {
          svg.classList.add('h-16', 'fill-black');
        }}
      />
    </button>
  );
}

export default Arrow;
