import React from 'react';
import Arrow from './Arrow';
import Button from './Button';

export type Props = {
  page: number;
  total: number;
  changePage: (v: number) => void;
};

function Pagination({ page, total, changePage }: Props): JSX.Element | null {
  if (total <= 1) return null;

  const prev = page > 1 ? page - 1 : page;
  const next = page < total ? page + 1 : page;
  let sliceStart = 1;
  let sliceEnd = 5;

  if (total > 5 && page > 2) {
    sliceStart = total - (page - 3) > 5 ? page - 2 : total - 4;
    sliceEnd = page + 2 < total ? page + 2 : total;
  }

  const pages = Array.from(new Array(total), (_, i) => ({
    value: i + 1,
    active: i + 1 === page,
  })).slice(sliceStart - 1, sliceEnd);

  const handleChange = (value: number) => {
    changePage(value);
  };

  return (
    <div className="text-center my-24 xl:my-40">
      <ul className="inline-flex">
        <li>
          <Arrow type="prev" disabled={page < 2} onClick={() => handleChange(prev)} />
        </li>
        {pages.map((p) => (
          <li key={p.value}>
            <Button onClick={() => handleChange(p.value)} active={p.active}>
              {p.value}
            </Button>
          </li>
        ))}

        <li>
          <Arrow type="next" disabled={page >= total} onClick={() => handleChange(next)} />
        </li>
      </ul>
    </div>
  );
}

export default Pagination;
