import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Route as RouteType } from 'routes';
import { authService } from 'services';

const useRoutes = (routes: RouteType[], parentPath = '', extraProps = {}) => {
  const renderRoutes = () => (
    <>
      <Switch>
        {routes.map(
          ({ key, path, exact, withAuth, role, component: Component, redirect, ...res }) => {
            return (
              <Route
                key={key}
                path={`${parentPath}${path}`}
                exact={exact}
                render={(props) => {
                  if (withAuth && !authService.isLogin()) return <Redirect to="/auth/login" />;

                  if (role && !authService.hasRole(role)) return <Redirect to="/404" />;

                  if (redirect) return <Redirect to={redirect} />;

                  if (Component) {
                    return <Component {...{ ...props, ...res, extraProps }} />;
                  }

                  return null;
                }}
              />
            );
          },
        )}
      </Switch>
    </>
  );

  return [renderRoutes];
};

export default useRoutes;
