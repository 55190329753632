import React from 'react';
import './Checkbox.scss';
import classNames from 'classnames';
import { useController } from 'react-hook-form';
import { InputError } from 'components';

type Props = {
  name: string;
  control: any;
  id: string;
  children: React.ReactNode;
  disabled?: boolean;
};

const defaultProps = {
  disabled: false,
};

function Checkbox({ name, control, id, children, disabled }: Props): JSX.Element {
  const {
    field: { ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: false,
  });

  return (
    <div className="mb-12 xl:mb-16">
      <div
        className={classNames('checkbox', {
          'checkbox--error': error,
          'opacity-50': disabled,
        })}
      >
        <input type="checkbox" id={id} disabled={disabled} {...inputProps} />
        <label htmlFor={id}>{children}</label>
      </div>
      <InputError message={error?.message} />
    </div>
  );
}

Checkbox.defaultProps = defaultProps;

export default Checkbox;
