import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, File } from 'components';
import { useAppDispatch } from 'hooks';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { editorService } from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { catchApiError } from 'helpers/error';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';
import { isFile } from 'helpers/files';

type Props = {
  id: number;
  onRequestClose: () => void;
  refreshFn: () => void;
};

type Inputs = {
  attachments: FileList | null;
};

const schema = yup.object().shape({
  attachments: yup.mixed().test('fileRequired', 'To pole jest wymagane', (value) => isFile(value)),
});

function EditorAddInvoice({ id, onRequestClose, refreshFn }: Props): JSX.Element {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async ({ attachments }: Inputs) => {
    try {
      const formData = new FormData();

      Array.from(attachments!).forEach((file) => {
        formData.append('attachments[]', file);
      });

      await editorService.addInvoice(id, formData);

      onRequestClose();
      refreshFn();

      dispatch(
        addNotification({
          id: 'editor.addInvoice',
          status: AlertStatus.success,
          message: t('alert:addInvoice'),
        }),
      );
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'editor.addInvoice',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <File
        {...register('attachments')}
        id="attachments"
        label={`${t('text:addFileLabel')} *`}
        value={watch('attachments')}
        resetFn={() => setValue('attachments', null)}
        error={errors?.attachments}
      />
      <div className="row mt-12">
        <div className="col w-auto mb-8 sm:mb-0">
          <Button as="submit" to="" loading={isSubmitting}>
            {t('button:addInvoice')}
          </Button>
        </div>
        <div className="col w-auto mb-8 sm:mb-0">
          <Button as="button" to="" secondary disabled={isSubmitting} onClick={onRequestClose}>
            {t('button:cancel')}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default EditorAddInvoice;
