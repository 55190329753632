import { StageStatus } from 'helpers/enums';

const config = {
  [StageStatus.unpaid]: {
    nameKey: 'unpaid',
    icon: 'unpaid',
    colors: {
      status: '#829498',
      background: '#F1F3F3',
      border: '#E5E5E5',
    },
  },
  [StageStatus.paid]: {
    nameKey: 'paid',
    icon: 'payments',
    colors: {
      status: '#829498',
      background: '#F1F3F3',
      border: '#E5E5E5',
    },
  },
  [StageStatus.ongoing]: {
    nameKey: 'ongoing',
    icon: 'waiting',
    colors: {
      status: '#FF7563',
      background: '#FFEDDF',
      border: '#FF7563',
    },
  },
  [StageStatus.correction]: {
    nameKey: 'correction',
    icon: 'review',
    colors: {
      status: '#FFB70F',
      background: '#FFF6CF',
      border: '#FFB70F',
    },
  },
  [StageStatus.accepted]: {
    nameKey: 'accepted',
    icon: 'accepted',
    colors: {
      status: '#20D870',
      background: '#D1FDD3',
      border: '#D1FDD3',
    },
  },
};

const get = (status: StageStatus) => {
  switch (status) {
    case StageStatus.unpaid:
      return config[StageStatus.unpaid];
    case StageStatus.paid:
      return config[StageStatus.paid];
    case StageStatus.ongoing:
      return config[StageStatus.ongoing];
    case StageStatus.correction:
      return config[StageStatus.correction];
    case StageStatus.accepted:
      return config[StageStatus.accepted];
    default:
      return null;
  }
};

export default get;
