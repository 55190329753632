import React from 'react';
import { Button } from 'components';

function ForgotPasswordSuccess(): JSX.Element {
  return (
    <>
      <h1 className="font-headings font-bold text-black text-32 xl:text-48 mb-16">
        Zapomniałeś hasła?
      </h1>
      <p className="text-18 xl:text-20 font-semibold text-black-75 leading-29 xl:leading-32 mb-24 xl:mb-40">
        Nowe hasło zostało ustawione. Możesz się teraz zalogować.
      </p>
      <Button as="route" to="/auth/login">
        Zaloguj się
      </Button>
    </>
  );
}

export default ForgotPasswordSuccess;
