import React from 'react';
import './Sidebar.scss';

type Props = {
  children: React.ReactNode;
};

function Sidebar({ children }: Props): JSX.Element {
  return (
    <nav className="sidebar hidden xl:block">
      <div className="sidebar__inner">{children}</div>
    </nav>
  );
}

export default Sidebar;
