import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'hooks/index';
import { fetchBadges } from 'features/badges/badgesSlice';

const useUserAction = () => {
  const dispatch = useAppDispatch();

  const handleUserAction = useCallback(() => {
    dispatch(fetchBadges());
  }, []);

  useEffect(() => {
    handleUserAction();
    document.addEventListener('userAction', handleUserAction);

    return () => {
      document.removeEventListener('userAction', handleUserAction);
    };
  }, []);
};

export default useUserAction;
