import React, { useEffect } from 'react';
import { Alert, Pagination } from 'components';
import { PageContent, HeroText, ArticleThumb } from 'layouts';
import Loading from 'features/loading/Loading';
import { AlertStatus } from 'helpers/enums';
import { useAppDispatch, useList } from 'hooks';
import type { Props as ArticleThumbType } from 'layouts/ArticleThumb/ArticleThumb';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { pageService } from 'services';
import { useTranslation } from 'react-i18next';

const Articles = (): JSX.Element => {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const list = useList<ArticleThumbType>(15);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_PAGE' }));

        const params = {
          records: list.perPage,
          page: list.page,
          sortField: 'a.created',
          sortDirection: 'DESC',
        };

        const response = await pageService.articles(params);

        list.handleFetch(response);
      } catch (error) {
        console.log('FETCH_PAGE');
      } finally {
        dispatch(removeLoading('FETCH_PAGE'));
      }
    })();
  }, [list.shouldUpdate]);

  return (
    <>
      <HeroText title="Artykuły" />
      <PageContent>
        <Loading items={['FETCH_PAGE']}>
          <div className="container">
            {list.items.length ? (
              <div className="row -mb-16 lg:-mb-24">
                {list.items.map((item) => (
                  <div className="col md:w-1/2 xl:w-1/3 mb-16 lg:mb-24" key={item.id}>
                    <ArticleThumb {...item} />
                  </div>
                ))}
              </div>
            ) : (
              <Alert status={AlertStatus.info} message={t('alert:noResults')} />
            )}
            <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
          </div>
        </Loading>
      </PageContent>
    </>
  );
};

export default Articles;
