import React from 'react';
import { LayoutImage } from 'layouts';
import { images } from 'assets';
import { useRoutes } from 'hooks';
import { useRouteMatch } from 'react-router-dom';
import { Route } from 'routes';

type Props = {
  routes: Route[];
};

function ForgotPassword({ routes }: Props): JSX.Element {
  const { path } = useRouteMatch();
  const [renderRoutes] = useRoutes(routes, path);

  return <LayoutImage image={images.authBg}>{renderRoutes()}</LayoutImage>;
}

export default ForgotPassword;
