import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  active: boolean;
};

const Item = forwardRef(
  ({ children, active, ...rest }: Props, ref: ForwardedRef<any>): JSX.Element => {
    return (
      <button
        ref={ref}
        type="button"
        className={classNames(
          'block w-full px-16 py-12 text-black-75 text-15 xl:text-16 text-left',
          {
            'bg-black-5': active,
          },
        )}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

export default Item;
