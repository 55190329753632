import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Input,
  Select,
  Checkbox,
  File,
  Radio,
  InputLabel,
  CheckboxGroup,
  Switch,
  Datepicker,
} from 'components';
import { useAppDispatch, useAppSelector, useFieldsErrors } from 'hooks';
import { authService } from 'services';
import { isFile } from 'helpers/files';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { fetchGlobals, selectorGlobals } from 'features/globals/globalsSlice';
import Loading from 'features/loading/Loading';
import type { User } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';
import { useHistory } from 'react-router-dom';
import { catchApiError } from 'helpers/error';

type Inputs = User & {
  password: string;
  passwordConfirm: string;
  rulesAccepted: boolean;
  marketingAccepted: boolean;
  editorProfile: {
    attachmentsToAdd: FileList | null;
  };
};

const schema = yup.object().shape({
  email: yup.string().email('Nieprawidłowy adres e-mail').required('To pole jest wymagane'),
  password: yup.string().required('To pole jest wymagane'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Podane hasła nie są takie same'),
  name: yup.string().required('To pole jest wymagane'),
  editorProfile: yup.object().shape({
    birthDate: yup.string().required('To pole jest wymagane'),
    gender: yup.mixed().required('To pole jest wymagane'),
    pesel: yup.string().required('To pole jest wymagane'),
    phone: yup.string().required('To pole jest wymagane'),
    nativeLanguageId: yup.mixed().required('To pole jest wymagane'),
    invoiceName: yup.string().required('To pole jest wymagane'),
    invoiceStreet: yup.string().required('To pole jest wymagane'),
    invoiceBuildingNo: yup.string().required('To pole jest wymagane'),
    invoiceTown: yup.string().required('To pole jest wymagane'),
    invoicePostalCode: yup.string().required('To pole jest wymagane'),
    invoiceCountryId: yup.mixed().required('To pole jest wymagane'),
    invoiceBankAccount: yup.string().required('To pole jest wymagane'),
    educationId: yup.mixed().required('To pole jest wymagane'),
  }),
  rulesAccepted: yup.mixed().oneOf([true], 'To pole jest wymagane'),
});

const Heading = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <h2 className="font-headings font-bold text-black text-18 xl:text-20 mb-16">{children}</h2>
);

const ItemHorizontal = ({ children }: { children: React.ReactNode }) => (
  <div className="mx-20">{children}</div>
);

const ItemCol = ({ children }: { children: React.ReactNode }) => (
  <div className="col sm:w-1/2 md:w-1/3 lg:w-1/4 mb-12 xl:mb-16">{children}</div>
);

function RegisterEditor(): JSX.Element {
  const { t } = useTranslation(['alert', 'text']);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const globals = useAppSelector(selectorGlobals);

  useEffect(() => {
    dispatch(fetchGlobals(['genders', 'languages', 'countries', 'educations', 'activityFields']));
  }, []);

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      editorProfile: {
        preferredFieldsIds: [],
      },
    },
  });

  useFieldsErrors('form-register-editor', errors);

  const onSubmit = async ({
    email,
    password,
    name,
    editorProfile,
    rulesAccepted,
    marketingAccepted,
  }: Inputs) => {
    try {
      const formData = new FormData();

      formData.append('email', email);
      formData.append('password', password);
      formData.append('name', name);
      formData.append('editorProfile[birthDate]', editorProfile.birthDate);
      formData.append('editorProfile[gender]', editorProfile.gender);
      formData.append('editorProfile[pesel]', editorProfile.pesel);
      formData.append('editorProfile[phone]', editorProfile.phone);
      formData.append('editorProfile[nativeLanguageId]', editorProfile.nativeLanguageId);
      formData.append('editorProfile[invoiceName]', editorProfile.invoiceName);
      formData.append('editorProfile[invoiceStreet]', editorProfile.invoiceStreet);
      formData.append('editorProfile[invoiceBuildingNo]', editorProfile.invoiceBuildingNo);
      formData.append('editorProfile[invoiceApartmentNo]', editorProfile.invoiceApartmentNo);
      formData.append('editorProfile[invoiceTown]', editorProfile.invoiceTown);
      formData.append('editorProfile[invoicePostalCode]', editorProfile.invoicePostalCode);
      formData.append('editorProfile[invoiceCountryId]', editorProfile.invoiceCountryId);
      formData.append('editorProfile[invoiceBankAccount]', editorProfile.invoiceBankAccount);
      formData.append('editorProfile[aboutMe]', editorProfile.aboutMe);
      formData.append('editorProfile[educationId]', editorProfile.educationId);
      formData.append(
        'editorProfile[showOnlyPreferredFields]',
        String(editorProfile.showOnlyPreferredFields ? 1 : 0),
      );

      editorProfile.preferredFieldsIds?.forEach((item) => {
        formData.append('editorProfile[preferredFieldsIds][]', item);
      });

      if (isFile(editorProfile.attachmentsToAdd)) {
        Array.from(editorProfile.attachmentsToAdd!).forEach((file) => {
          formData.append('editorProfile[attachmentsToAdd][]', file);
        });
      }

      formData.append('rulesAccepted', String(rulesAccepted));
      formData.append('marketingAccepted', String(marketingAccepted));

      await authService.registerEditor(formData);

      dispatch(
        addNotification({
          id: 'auth.registerEditor',
          status: AlertStatus.success,
          message: t('alert:userRegister'),
        }),
      );

      reset();

      history.push('?success');
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'auth.registerEditor',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    }
  };

  return (
    <Loading items={['FETCH_GLOBALS']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-32 xl:mb-40">
          <Heading>Dane do logowania</Heading>
          <div className="row">
            <div className="col md:w-1/2 xl:w-1/3">
              <Input control={control} name="email" placeholder="Wpisz" label="Adres e-mail *" />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Input
                type="password"
                control={control}
                name="password"
                placeholder="Wpisz"
                label="Hasło *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Input
                type="password"
                control={control}
                name="passwordConfirm"
                placeholder="Wpisz"
                label="Powtórz hasło *"
              />
            </div>
          </div>
        </div>

        <div className="mb-32 xl:mb-40">
          <Heading>Podstawowe dane</Heading>
          <div className="row">
            <div className="col md:w-1/2 xl:w-1/3">
              <Input
                control={control}
                name="name"
                placeholder="Wpisz"
                label="Wyświetlana nazwa *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <div className="react-datepicker--header-no-title">
                <Datepicker
                  control={control}
                  name="editorProfile[birthDate]"
                  label="Data urodzenia *"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <InputLabel>płeć *</InputLabel>
              <Radio
                register={register}
                name="editorProfile[gender]"
                idPrefix="editorProfile[gender]"
                items={globals.genders}
                error={errors?.editorProfile?.gender}
                className="flex h-40 xl:h-48 items-center -mx-20"
                itemWrapper={ItemHorizontal}
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Input
                control={control}
                name="editorProfile[pesel]"
                placeholder="Wpisz"
                label="PESEL *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Input
                control={control}
                name="editorProfile[phone]"
                placeholder="Wpisz"
                label="Nr telefonu *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Select
                name="editorProfile[nativeLanguageId]"
                control={control}
                items={globals.languages}
                label="Język ojczysty *"
              />
            </div>
          </div>
        </div>

        <div className="mb-32 xl:mb-40">
          <Heading>Dane do umowy</Heading>
          <div className="row">
            <div className="col md:w-1/2 xl:w-2/6">
              <Input
                control={control}
                name="editorProfile[invoiceName]"
                placeholder="Wpisz"
                label="Imię i nazwisko *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-2/6">
              <Input
                control={control}
                name="editorProfile[invoiceStreet]"
                placeholder="Wpisz"
                label="Ulica *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/6">
              <Input
                control={control}
                name="editorProfile[invoiceBuildingNo]"
                placeholder="Wpisz"
                label="Nr budynku *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/6">
              <Input
                control={control}
                name="editorProfile[invoiceApartmentNo]"
                placeholder="Wpisz"
                label="Nr mieszkania"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Input
                control={control}
                name="editorProfile[invoiceTown]"
                placeholder="Wpisz"
                label="Miasto *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Input
                control={control}
                name="editorProfile[invoicePostalCode]"
                placeholder="Wpisz"
                label="Kod pocztowy *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Select
                name="editorProfile[invoiceCountryId]"
                control={control}
                items={globals.countries}
                label="Kraj *"
              />
            </div>
            <div className="col md:w-1/2 xl:w-1/3">
              <Input
                control={control}
                name="editorProfile[invoiceBankAccount]"
                placeholder="Wpisz"
                label="Nr konta bankowego *"
              />
            </div>
          </div>
        </div>

        <div className="mb-32 xl:mb-40">
          <Heading>Dodatkowe informacje</Heading>
          <div className="row">
            <div className="col">
              <Input
                type="textarea"
                control={control}
                name="editorProfile[aboutMe]"
                placeholder="Napisz kilka słów o sobie"
                label="O sobie"
              />
            </div>
            <div className="col">
              <File
                {...register('editorProfile.attachmentsToAdd')}
                id="editorProfile.attachmentsToAdd"
                label="Załączniki (CV, skan dyplomu lub list motywacyjny)"
                value={watch('editorProfile.attachmentsToAdd')}
                resetFn={() => setValue('editorProfile.attachmentsToAdd', null)}
                error={errors?.editorProfile?.attachmentsToAdd}
                multi
              />
            </div>
            <div className="col xl:w-1/3">
              <Select
                name="editorProfile[educationId]"
                control={control}
                items={globals.educations}
                label="Wykształcenie *"
              />
            </div>
          </div>
        </div>

        <div className="mb-32 xl:mb-40">
          <Heading>
            <div className="md:flex">
              Preferowane dziedziny
              <div className="mt-12 md:ml-40 md:mt-0">
                <Switch
                  name="editorProfile[showOnlyPreferredFields]"
                  control={control}
                  id="editorProfile[showOnlyPreferredFields]"
                >
                  Wyświetlaj tylko zlecenia z preferowanych dziedzin
                </Switch>
              </div>
            </div>
          </Heading>
          <CheckboxGroup
            register={register}
            name="editorProfile[preferredFieldsIds]"
            idPrefix="editorProfile[preferredFieldsIds]"
            items={globals.activityFields}
            error={errors?.editorProfile?.preferredFieldsIds}
            className="row"
            itemWrapper={ItemCol}
          />
        </div>

        <Checkbox name="rulesAccepted" control={control} id="rulesAccepted">
          * Akceptuję Regulamin serwisu oraz Politykę Prywatności.
        </Checkbox>

        <Checkbox name="marketingAccepted" control={control} id="marketingAccepted">
          {t('text:marketingConsent')}
        </Checkbox>

        <div className="mt-16 text-center">
          <Button as="submit" to="" loading={isSubmitting}>
            Wyślij aplikację na redaktora
          </Button>
        </div>
      </form>
    </Loading>
  );
}

export default RegisterEditor;
