import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
};

const Content = forwardRef(
  ({ children, isOpen, ...rest }: Props, ref: ForwardedRef<any>): JSX.Element => {
    return (
      <div
        ref={ref}
        className={classNames('absolute top-40 xl:top-48 left-0 w-full bg-white z-10', {
          'border border-t-0 border-primary': isOpen,
        })}
        {...rest}
      >
        {children}
      </div>
    );
  },
);
export default Content;
