import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, InputLabel, InputError, RatingStars } from 'components';
import { useAppDispatch } from 'hooks';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { orderService } from 'services';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { catchApiError } from 'helpers/error';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';

type Props = {
  id: number;
  onSuccess: () => void;
};

type Inputs = {
  rate: number;
  message: string;
};

const schema = yup.object().shape({
  rate: yup.number().required('To pole jest wymagane').min(1, 'To pole jest wymagane'),
  message: yup.string().required('To pole jest wymagane'),
});

function OrderAddReview({ id, onSuccess }: Props): JSX.Element {
  const { t } = useTranslation(['alert', 'button']);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      rate: 0,
    },
  });

  const onSubmit = async ({ rate, message }: Inputs) => {
    try {
      await orderService.addReview(id, {
        rate,
        message,
      });

      dispatch(
        addNotification({
          id: 'order.addReview',
          status: AlertStatus.success,
          message: t('alert:addReview'),
        }),
      );

      onSuccess();
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'order.addReview',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="font-headings font-bold text-black text-18 xl:text-20 mb-16 xl:mb-24">
        {t('text:addReview')}
      </p>
      <div className="mb-12 xl:mb-16">
        <InputLabel>Ocena</InputLabel>
        <RatingStars
          value={getValues('rate')}
          asTrigger
          onClick={(v) => setValue('rate', v, { shouldValidate: true })}
        />
        <InputError message={errors?.rate?.message} />
      </div>
      <Input
        type="textarea"
        control={control}
        name="message"
        placeholder="Napisz opinię..."
        label="Treść opinii"
      />
      <div className="mt-12">
        <Button as="submit" to="" loading={isSubmitting}>
          {t('button:rateOrder')}
        </Button>
      </div>
    </form>
  );
}

export default OrderAddReview;
