import React, { useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { PageContent, HeroText, User, EditorStats } from 'layouts';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { publicService } from 'services';
import { useAppDispatch, useRoutes, useRoutesFirst } from 'hooks';
import { catchApiError } from 'helpers/error';
import type { TabRoute, Editor as EditorType } from 'helpers/types';
import Loading from 'features/loading/Loading';
import { Route } from 'routes';
import { Tabs } from 'components';

type Params = {
  id: string;
};

type Props = {
  routes: Route[];
};

const tabs: TabRoute[] = [
  {
    key: 'tabAbout',
    name: 'Informacje podstawowe',
    path: '/informacje-podstawowe',
  },
  {
    key: 'tabActivityFields',
    name: 'Preferowane dziedziny',
    path: '/preferowane-dziedziny',
  },
  {
    key: 'tabReviews',
    name: 'Oceny',
    path: '/oceny',
  },
];

const Editor = ({ routes }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id } = useParams<Params>();
  const { path, url } = useRouteMatch();
  const [editor, setEditor] = useState<EditorType>();
  const [renderRoutes] = useRoutes(routes, path, { editor });
  useRoutesFirst(routes);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_EDITOR' }));

        const response = await publicService.fetchEditor(+id);

        setEditor(response);
      } catch (error) {
        catchApiError(error);
      } finally {
        dispatch(removeLoading('FETCH_EDITOR'));
      }
    })();
  }, [id]);

  return (
    <>
      <HeroText title="Profil redaktora" />

      <PageContent>
        <Loading items={['FETCH_EDITOR']}>
          {editor && (
            <>
              <div className="container">
                <div className="row items-center">
                  <div className="col md:w-1/2 mb-16 md:mb-0">
                    <User {...editor} large />
                  </div>
                  <div className="col md:w-1/2">
                    <EditorStats {...editor} />
                  </div>
                </div>
                <div className="mt-40">
                  <Tabs routes={tabs} path={url} />
                  <div className="mt-32 xl:mt-40">{renderRoutes()}</div>
                </div>
              </div>
            </>
          )}
        </Loading>
      </PageContent>
    </>
  );
};

export default Editor;
