import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import type { Props as ActivityFieldType } from 'layouts/ActivityField/ActivityField';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { publicService } from 'services';
import Loading from 'features/loading/Loading';
import { catchApiError } from 'helpers/error';
import { useParams } from 'react-router-dom';
import { ActivityField } from 'layouts';
import { AlertStatus } from 'helpers/enums';
import { Alert } from 'components';
import { useTranslation } from 'react-i18next';

type Params = {
  id: string;
};

const ActivityFields = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['alert']);
  const { id } = useParams<Params>();
  const [fields, setFields] = useState<ActivityFieldType[]>([]);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_EDITOR_ACTIVITY' }));

        const response = await publicService.fetchEditorActivityFields(+id);

        setFields(response);
      } catch (error) {
        catchApiError(error);
      } finally {
        dispatch(removeLoading('FETCH_EDITOR_ACTIVITY'));
      }
    })();
  }, []);

  return (
    <Loading items={['FETCH_EDITOR_ACTIVITY']}>
      {fields.length ? (
        <div className="row md:-mx-32 xl:-mx-64 -mb-16 xl:-mb-24">
          {fields.map((field) => (
            <div
              className="col md:w-1/2 lg:w-1/3 md:px-32 xl:px-64 mb-16 xl:mb-24"
              key={field.field.id}
            >
              <ActivityField {...field} />
            </div>
          ))}
        </div>
      ) : (
        <Alert status={AlertStatus.info} message={t('alert:noResults')} />
      )}
    </Loading>
  );
};

export default ActivityFields;
