import React from 'react';
import { Loader } from 'components';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  loading?: boolean;
  full?: boolean;
};

const defaultProps = {
  loading: false,
  full: false,
};

function Loading({ children, loading, full }: Props): JSX.Element {
  if (!loading) return <>{children}</>;

  return (
    <div
      className={classNames('inline-block relative', {
        'w-full': full,
      })}
    >
      {children}
      <span className="absolute top-0 left-0 flex items-center w-full h-full">
        <Loader secondary small />
      </span>
    </div>
  );
}

Loading.defaultProps = defaultProps;

export default Loading;
