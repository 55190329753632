import React from 'react';
import './Loader.scss';
import classNames from 'classnames';

type Props = {
  page?: boolean;
  secondary?: boolean;
  small?: boolean;
};

const defaultProps = {
  page: false,
  secondary: false,
  small: false,
};

function Loader({ page, secondary, small }: Props): JSX.Element {
  const className = classNames('loader', {
    'loader--secondary': secondary,
    'loader--small': small,
  });

  return page ? (
    <div className="text-center my-64 md:my-96">
      <div className={className} />
    </div>
  ) : (
    <div className={className} />
  );
}

Loader.defaultProps = defaultProps;

export default Loader;
