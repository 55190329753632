import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { Stage as StageType } from 'helpers/types';
import getConfig from 'layouts/Stage/config';
import { Button, ButtonLink } from 'components';
import { AlertStatus, Role, StageStatus } from 'helpers/enums';
import { dateFormat } from 'helpers/date';
import { useAppDispatch, useLoading } from 'hooks';
import { authService, orderService, attachmentService } from 'services';
import classNames from 'classnames';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { addOne as addModal } from 'features/modals/modalsSlice';
import { catchApiError } from 'helpers/error';
import { formatViewPrice } from 'helpers/price';
import { Attachment } from 'helpers/types';
import { saveAs } from 'file-saver';
import Status from './Status';

type Props = StageType & {
  idOrder: number;
  refreshFn: () => void;
  prevStageStatus?: StageStatus | null;
  addAttachmentFn?: (idOrder: number, idStage: number) => void;
  withoutActions?: boolean;
};

const defaultProps = {
  prevStageStatus: null,
  addAttachmentFn: null,
  withoutActions: false,
};

function Stage({
  idOrder,
  id,
  stageIndex,
  pages,
  status,
  term,
  attachments,
  correctionsAttachments,
  prevStageStatus,
  refreshFn,
  addAttachmentFn,
  withoutActions,
  price,
}: Props): JSX.Element {
  const { t } = useTranslation(['text', 'button']);
  const dispatch = useAppDispatch();
  const loading = useLoading();
  const config = getConfig(status);
  const isEditor = authService.hasRole(Role.editor);

  const handleAccept = useCallback(async () => {
    try {
      dispatch(addLoading({ id: `STAGE_ACTION_${idOrder}` }));
      dispatch(addLoading({ id: `STAGE_ACTION_TRIGGER_${id}` }));

      await orderService.acceptStage(id);

      dispatch(
        addNotification({
          id: 'stage.accept',
          status: AlertStatus.success,
          message: t('alert:acceptStage'),
        }),
      );

      refreshFn();
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'stage.accept',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    } finally {
      dispatch(removeLoading(`STAGE_ACTION_${idOrder}`));
      dispatch(removeLoading(`STAGE_ACTION_TRIGGER_${id}`));
    }
  }, []);

  const handlePay = useCallback(async () => {
    dispatch(
      addModal({
        id: 'PAYMENT_INFO',
        data: {
          title: `Zamówienie #${idOrder} (etap #${id})`,
          price: formatViewPrice(price),
        },
      }),
    );
    // try {
    //   dispatch(addLoading({ id: `STAGE_ACTION_${idOrder}` }));
    //   dispatch(addLoading({ id: `STAGE_ACTION_TRIGGER_${id}` }));
    //
    //   const response = await orderService.payStage(id);
    //
    //   window.location.replace(response.url);
    // } catch (error) {
    //   catchApiError(error, () =>
    //     dispatch(
    //       addNotification({
    //         id: 'stage.pay',
    //         status: AlertStatus.error,
    //         message: error.message,
    //       }),
    //     ),
    //   );
    // } finally {
    //   dispatch(removeLoading(`STAGE_ACTION_${idOrder}`));
    //   dispatch(removeLoading(`STAGE_ACTION_TRIGGER_${id}`));
    // }
  }, []);

  const renderActions = (): JSX.Element | null => {
    if (withoutActions) return null;

    switch (status) {
      case StageStatus.ongoing:
      case StageStatus.correction:
        if (isEditor) {
          return (
            <Button
              as="button"
              to=""
              secondary
              full
              onClick={addAttachmentFn ? () => addAttachmentFn(idOrder, id) : null}
              disabled={loading.check([`STAGE_ACTION_${idOrder}`])}
            >
              {t(`button:${attachments.length ? 'updateStage' : 'addStage'}`)}
            </Button>
          );
        }

        if (!attachments.length) return null;

        return (
          <>
            <Button
              as="button"
              to=""
              secondary
              full
              className="mb-8"
              onClick={addAttachmentFn ? () => addAttachmentFn(idOrder, id) : null}
              disabled={loading.check([`STAGE_ACTION_${idOrder}`])}
            >
              {t('button:addCorrection')}
            </Button>
            <Button
              as="button"
              to=""
              secondary
              full
              onClick={handleAccept}
              loading={loading.check([`STAGE_ACTION_TRIGGER_${id}`])}
              disabled={loading.check([`STAGE_ACTION_${idOrder}`])}
            >
              {t('button:accept')}
            </Button>
          </>
        );
      case StageStatus.unpaid:
        if (isEditor) return null;
        if (prevStageStatus === StageStatus.unpaid) return null;

        return (
          <Button
            as="button"
            to=""
            secondary
            full
            onClick={handlePay}
            loading={loading.check([`STAGE_ACTION_TRIGGER_${id}`])}
            disabled={loading.check([`STAGE_ACTION_${idOrder}`])}
          >
            {t('button:pay')}
          </Button>
        );
      default:
        return null;
    }
  };

  const saveFiles = async (files: Attachment[]) => {
    try {
      dispatch(addLoading({ id: `STAGE_SAVE_FILES_${id}` }));

      const filesList = files.filter((f) => f.deleted === null);

      if (filesList.length > 1) {
        const response = await attachmentService.zipFiles(filesList.map((f) => f.file));

        saveAs(response, `order-${idOrder}-${stageIndex + 1}.zip`);
      } else if (filesList[0]) {
        saveAs(filesList[0].src, filesList[0].name);
      }
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'stage.zip',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    } finally {
      dispatch(removeLoading(`STAGE_SAVE_FILES_${id}`));
    }
  };

  const renderAttachments = (): JSX.Element | null => {
    if (withoutActions) return null;

    return (
      <div className="text-12 md:text-13 xxl:text-16 font-semibold">
        <div className="mb-12">
          <ButtonLink
            as="button"
            to=""
            className="text-inherit"
            disabled={!attachments.length || loading.check([`STAGE_SAVE_FILES_${id}`])}
            onClick={() => saveFiles(attachments)}
          >
            {t('button:downloadStage')}
          </ButtonLink>
        </div>
        <ButtonLink
          as="button"
          to=""
          className="text-inherit"
          disabled={!correctionsAttachments.length || loading.check([`STAGE_SAVE_FILES_${id}`])}
          onClick={() => saveFiles(correctionsAttachments)}
        >
          {t('button:downloadCorrection')}
        </ButtonLink>
      </div>
    );
  };

  return (
    <div>
      <div className="text-center mb-12">
        <p className="font-headings font-bold text-black text-18 xl:text-20 mb-8">{`${t(
          'text:stage',
        )} ${stageIndex}`}</p>
        <Status config={config} />
      </div>
      <div
        className="py-12 px-8 xl:py-24 text-center border-2"
        style={{
          backgroundColor: config?.colors.background,
          borderColor: config?.colors.border,
        }}
      >
        <div
          className={classNames({
            'opacity-20': status === StageStatus.unpaid,
          })}
        >
          <p className="font-headings font-bold text-black text-32 xl:text-48 mb-8">{pages}</p>
          <p className="font-semibold text-black-75 mb-16">{t('text:page', { count: pages })}</p>
          <p className="font-headings font-bold text-black text-20 xl:text-24 mb-16">
            {formatViewPrice(price)}&nbsp;zł
          </p>
          <p className="text-black-75 text-10 sm:text-12 xl:text-13 mb-16 xl:mb-24">
            <strong>{t('text:term')}: </strong>
            {dateFormat(term)}
          </p>
        </div>
        {renderAttachments()}
      </div>
      <div className="pt-12 xl:px-8 text-center">{renderActions()}</div>
    </div>
  );
}

Stage.defaultProps = defaultProps;

export default Stage;
