import React from 'react';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  description?: React.ReactNode;
  sticky?: boolean;
};

const defaultProps = {
  description: '',
  sticky: false,
};

function PageTitle({ children, description, sticky }: Props): JSX.Element {
  return (
    <div
      className={classNames('mb-24 xl:mb-40', {
        'sticky bg-white top-82 xl:top-0 z-10 pb-8 border-b border-black-5': sticky,
      })}
    >
      <h1 className="font-headings font-bold text-black text-26 xl:text-32 leading-32 xl:leading-40">
        {children}
      </h1>
      {description && <div className="mt-8">{description}</div>}
    </div>
  );
}

PageTitle.defaultProps = defaultProps;

export default PageTitle;
