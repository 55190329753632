import React from 'react';
import classNames from 'classnames';

export type Props = {
  children: React.ReactNode;
  className?: string;
};

const defaultProps = {
  className: '',
};

function Text({ children, className: classNameExtend }: Props): JSX.Element {
  return (
    <p className={classNames('text-12 xl:text-16 leading-19 xl:leading-28', classNameExtend)}>
      {children}
    </p>
  );
}

Text.defaultProps = defaultProps;

export default Text;
