import React, { useCallback } from 'react';
import { Cms } from 'components';
import { HeroText } from 'layouts';
import { usePage } from 'hooks';
import Loading from 'features/loading/Loading';
import type { TextPage as TextPageType } from 'services/page/types';
import { Page } from 'helpers/enums';
import { useLocation } from 'react-router-dom';

const TextPage = (): JSX.Element => {
  const { pathname } = useLocation();

  const getPage = useCallback(() => {
    let page = null;

    switch (pathname) {
      case '/regulamin':
        page = Page.regulations;
        break;
      case '/polityka-prywatnosci':
        page = Page.privacy;
        break;
      case '/faq':
        page = Page.faq;
        break;
      default:
    }

    return page;
  }, [pathname]);

  const { hero, content }: TextPageType = usePage<TextPageType>(getPage());

  return (
    <Loading items={['FETCH_PAGE']}>
      {hero && <HeroText {...hero} />}
      {content && (
        <section className="my-64 xl:my-96">
          <div className="container">
            <Cms>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Cms>
          </div>
        </section>
      )}
    </Loading>
  );
};

export default TextPage;
