import React from 'react';
import { ReactSVG } from 'react-svg';
import { icons } from 'assets';

type Props = {
  icon: string;
  className?: string;
  beforeInjection?: (svg: SVGSVGElement) => void;
};

const defaultProps = {
  className: '',
  beforeInjection: () => {},
};

function Icon({ icon, className, beforeInjection }: Props): JSX.Element {
  return <ReactSVG src={icons[icon]} className={className} beforeInjection={beforeInjection} />;
}

Icon.defaultProps = defaultProps;

export default Icon;
