import React from 'react';
import { Rating } from 'components';
import { images } from 'assets';
import type { UserShort } from 'helpers/types';
import classNames from 'classnames';

export type Props = UserShort & {
  small?: boolean;
  large?: boolean;
};

const defaultProps = {
  small: false,
  large: false,
};

function User({ name, profilePhoto, avgRate, small, large }: Props): JSX.Element | null {
  const isDefault = !small && !large;

  return (
    <div className="flex items-center">
      <div
        className={classNames('flex-grow-0 flex-shrink-0', {
          'mr-16': isDefault,
          'mr-8': small,
          'mr-16 xl:mr-24': large,
        })}
      >
        <img
          className={classNames('rounded-full', {
            'w-64 h-64': isDefault,
            'w-40 h-40': small,
            'w-96 h-96 xl:w-180 xl:h-180': large,
          })}
          src={profilePhoto ? profilePhoto.src : images.avatar}
          alt={name}
          onError={(e) => {
            const target = e.target as HTMLImageElement;

            target.src = images.avatar;
          }}
        />
      </div>
      <div>
        <p
          className={classNames('font-headings font-bold text-black', {
            'text-18 xl:text-20 mb-4': isDefault,
            'text-15 xl:text-16 mb-4': small,
            'text-26 xl:text-32 mb-8': large,
          })}
        >
          {name}
        </p>
        {!!avgRate && <Rating rating={avgRate} large={large} />}
      </div>
    </div>
  );
}

User.defaultProps = defaultProps;

export default User;
