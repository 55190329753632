import React from 'react';
import { Cta } from 'layouts';
import './CtaImageFull.scss';
import type { Props as CtaType } from 'layouts/Cta/Cta';
import classNames from 'classnames';
import type { ImageBreakpoint } from 'helpers/types';

export type Props = {
  cta: CtaType | null;
  image: ImageBreakpoint | null;
  stretch?: boolean;
};

const defaultProps = {
  stretch: false,
};

function CtaImageFull({ cta, image, stretch }: Props): JSX.Element | null {
  if (!cta) return null;

  return (
    <section
      className="cta-image-full-wrapper"
      style={image ? { backgroundImage: `url(${image.urlLarge})` } : {}}
    >
      <div className="container pt-40 pb-32 xl:py-64">
        <div className="row">
          <div
            className={classNames('col', {
              'md:w-1/2': !stretch,
              'md:w-2/3': stretch,
            })}
          >
            <Cta {...cta} />
          </div>
        </div>
      </div>
      {image && (
        <img
          src={image.url}
          alt={image.alt}
          className="object-cover object-center w-full min-h-300 lg:mt-32 lg:mt-0 lg:hidden"
        />
      )}
    </section>
  );
}

CtaImageFull.defaultProps = defaultProps;

export default CtaImageFull;
