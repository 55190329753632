import React from 'react';
import classNames from 'classnames';

export type Props = {
  active?: boolean;
  children: React.ReactNode;
  onClick: () => void;
};

const defaultProps = {
  active: false,
};

function Button({ active, children, onClick }: Props): JSX.Element {
  return (
    <button
      type="button"
      className={classNames(
        'w-40 xl:w-48 h-40 xl:h-48 flex justify-center items-center font-headings font-bold text-18 xl:text-20',
        {
          'text-black': !active,
          'text-white bg-primary-dk': active,
        },
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

Button.defaultProps = defaultProps;

export default Button;
