import React from 'react';
import './Steps.scss';
import { Icon } from 'components';

export type StepItem = {
  title: string;
  description: string;
  icon: string;
  color: string;
};

export type Props = {
  steps: StepItem[];
};

const defaultProps = {
  reverse: false,
};

function Steps({ steps }: Props): JSX.Element | null {
  if (!steps.length) return null;

  return (
    <section className="mt-24 mb-64 xl:mt-40 xl:mb-96 steps">
      <div className="container z-10 relative">
        <div className="row -mb-32">
          {steps.map((step, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="col w-1/2 lg:w-1/4 mb-32" key={i}>
              <div
                className="border-2 px-12 pb-24 text-center h-full mt-16 bg-white"
                style={{ borderColor: `${step.color}25` }}
              >
                <p
                  className="inline-block font-headings font-bold text-40 xl:text-64 leading-none bg-white px-12 -mt-32"
                  style={{ color: `${step.color}25` }}
                >
                  {i + 1}
                </p>
                <div className="mb-16 xl:mb-24 mt-12">
                  <div
                    className="inline-block w-48 h-48 rounded-full inline-flex items-center justify-center"
                    style={{ backgroundColor: step.color }}
                  >
                    <Icon
                      icon={step.icon}
                      beforeInjection={(svg) => {
                        svg.classList.add('w-20', 'h-20', 'fill-white');
                      }}
                    />
                  </div>
                </div>
                <p className="font-headings font-bold text-black text-18 leading-22 xl:text-20 xl:leading-28 mb-8 xl:mb-16">
                  {step.title}
                </p>
                <p className="text-12 xl:text-16 leading-19 xl:leading-28 text-black-75">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

Steps.defaultProps = defaultProps;

export default Steps;
