import React from 'react';
import { Badge, Icon } from 'components';
import classNames from 'classnames';
import type { UserShort, Badge as BadgeType } from 'helpers/types';
import Label from './Label';

export type Props = {
  label?: string | null;
  title?: string;
  user?: UserShort | null;
  badges?: BadgeType[] | null;
  active: boolean;
  toggleFn: () => void;
};

function Header({ title, user, label, badges, active, toggleFn }: Props): JSX.Element {
  const renderInfo = () => {
    if (!title) return null;

    return (
      <span className="flex justify-between md:justify-start mb-4">
        {label && <Label className="md:mr-40">{label}</Label>}

        {user && (
          <span className="flex items-center text-12 xl:text-13 leading-19 xl:leading-22 font-semibold text-black-50">
            <Icon
              icon="user"
              className="ml-8 mr-8"
              beforeInjection={(svg) => {
                svg.classList.add('w-16', 'fill-black-50');
              }}
            />
            {user.name}
          </span>
        )}
      </span>
    );
  };

  const renderBadges = () => {
    if (!badges) return null;

    return (
      <span className="flex flex-wrap -mx-4 -mb-4 mt-8 xxl:mt-0">
        {badges.map((badge) => (
          <span className="px-4 pb-4" key={`${badge.title}`}>
            <Badge {...badge} />
          </span>
        ))}
      </span>
    );
  };

  return (
    <button
      type="button"
      className="block w-full text-left bg-black-2 p-12 xl:px-24 xl:py-16"
      onClick={toggleFn}
    >
      {renderInfo()}
      <span className="flex justify-between">
        <span className="xxl:flex items-center">
          <span className="block text-15 xl:text-16 leading-25 xl:leading-28 font-semibold mr-16 xl:mr-24">
            {title}
          </span>
          {renderBadges()}
        </span>
        <div className="ml-8 mt-10">
          <Icon
            icon="arrowDown"
            className={classNames({
              'transform origin-center rotate-180': active,
            })}
            beforeInjection={(svg) => {
              svg.classList.add('h-8', 'fill-primary');
            }}
          />
        </div>
      </span>
    </button>
  );
}

export default Header;
