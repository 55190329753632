import React from 'react';
import classNames from 'classnames';
import { InputError, InputLabel } from 'components';
import { useController } from 'react-hook-form';
import type { EmitOnChange } from 'helpers/types';

type Props = {
  type?: 'text' | 'number' | 'textarea' | 'textarea-short' | 'email' | 'password';
  name: string;
  control: any;
  disabled?: boolean;
  label?: string;
  id?: string;
  onChangeEmit?: (v: EmitOnChange) => void;
  [key: string]: any;
};

const defaultProps = {
  type: 'text',
  disabled: false,
  label: '',
  id: '',
  onChangeEmit: null,
};

const defaultStyle = classNames([
  'w-full min-h-40 xl:min-h-48 px-16 text-15 xl:text-16',
  'border border-solid focus:border-primary',
  'rounded-none shadow-none',
  'focus:bg-white placeholder-black-75 text-black-75',
]);

function Input({
  type,
  name,
  control,
  disabled,
  label,
  id,
  onChangeEmit,
  ...rest
}: Props): JSX.Element {
  const {
    field: { value, onChange: onChangeController, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const className = classNames(defaultStyle, {
    'min-h-96 xl:min-h-128 py-16': type === 'textarea',
    'pt-12 xl:pt-16 h-40 xl:h-48 max-h-96': type === 'textarea-short',
    'bg-error-75 border-error-75': error,
    'opacity-50 cursor-not-allowed': disabled,
    'bg-black-5 border-black-5': !error && !value,
    'bg-black-5 border-primary': !error && value,
  });

  const handleOnChange = (e: any) => {
    onChangeController(e);

    if (onChangeEmit) {
      onChangeEmit({
        name,
        value: e.target.value,
      });
    }
  };

  const renderInput = () => {
    switch (type) {
      case 'number':
        return (
          <input
            className={className}
            type={type}
            disabled={disabled}
            id={id || `input-${name}`}
            onChange={handleOnChange}
            {...inputProps}
            {...rest}
            step="any"
            min="0"
          />
        );
      case 'textarea':
      case 'textarea-short':
        return (
          <textarea
            className={className}
            disabled={disabled}
            id={id || `input-${name}`}
            onChange={handleOnChange}
            {...inputProps}
            {...rest}
          />
        );
      default:
        return (
          <input
            className={className}
            type={type}
            disabled={disabled}
            id={id || `input-${name}`}
            onChange={handleOnChange}
            {...inputProps}
            {...rest}
          />
        );
    }
  };

  return (
    <div className="mb-12 xl:mb-16">
      {label && <InputLabel htmlFor={id || `input-${name}`}>{label}</InputLabel>}
      {renderInput()}
      <InputError message={error?.message} />
    </div>
  );
}

Input.defaultProps = defaultProps;

export default Input;
