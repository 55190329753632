import React, { useEffect } from 'react';
import { useAppDispatch, useList } from 'hooks';
import { Rate as RateType } from 'helpers/types';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { publicService } from 'services';
import Loading from 'features/loading/Loading';
import { catchApiError } from 'helpers/error';
import { useParams } from 'react-router-dom';
import { Alert, Pagination } from 'components';
import { AlertStatus } from 'helpers/enums';
import { Rate } from 'layouts';
import { useTranslation } from 'react-i18next';

type Params = {
  id: string;
};

const Reviews = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['alert']);
  const list = useList<RateType>(10);
  const { id } = useParams<Params>();

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_EDITOR_REVIEWS' }));

        const params = {
          records: list.perPage,
          page: list.page,
        };

        const response = await publicService.fetchEditorReviews(params, +id);

        list.handleFetch(response);
      } catch (error) {
        catchApiError(error);
      } finally {
        dispatch(removeLoading('FETCH_EDITOR_REVIEWS'));
      }
    })();
  }, [list.shouldUpdate]);

  return (
    <Loading items={['FETCH_EDITOR_REVIEWS']}>
      {list.items.length ? (
        list.items.map((rate) => (
          <div className="mb-16 pb-16 border-b border-black-5" key={rate.id}>
            <Rate {...rate} />
          </div>
        ))
      ) : (
        <Alert status={AlertStatus.info} message={t('alert:noResults')} />
      )}
      <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
    </Loading>
  );
};

export default Reviews;
