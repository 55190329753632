import Client from 'pages/client/Client';
import Dashboard from 'pages/client/Dashboard/Dashboard';
import Account from 'pages/client/Account/Account';
import Orders from 'pages/client/Orders/Orders';
import OrdersOngoing from 'pages/client/Orders/Ongoing';
import OrdersOffers from 'pages/client/Orders/Offers';
import OrdersFinished from 'pages/client/Orders/Finished';
import OrdersStatus from 'pages/client/Orders/Status';
import AddOrder from 'pages/client/AddOrder/AddOrder';
import Chat from 'pages/shared/Chat/Chat';
import { Role } from 'helpers/enums';

export default [
  {
    key: 'client',
    path: '/klient',
    exact: false,
    component: Client,
    withAuth: true,
    role: Role.client,
    routes: [
      {
        key: 'clientDashboard',
        path: '/',
        exact: true,
        component: Dashboard,
      },
      {
        key: 'clientOrdersStatus',
        path: '/moje-zlecenia/status/:idPayment',
        exact: true,
        component: OrdersStatus,
      },
      {
        key: 'clientOrders',
        path: '/moje-zlecenia',
        exact: false,
        component: Orders,
        routes: [
          {
            key: 'clientOrdersOngoing',
            path: '/w-toku',
            exact: true,
            component: OrdersOngoing,
          },
          {
            key: 'clientOrdersOffers',
            path: '/oferty',
            exact: true,
            component: OrdersOffers,
          },
          {
            key: 'clientOrdersFinished',
            path: '/zakonczone',
            exact: true,
            component: OrdersFinished,
          },
          {
            key: 'notfound',
            path: '*',
            exact: true,
            redirect: '/404',
          },
        ],
      },
      {
        key: 'clientAddOrder',
        path: '/dodaj-zlecenie',
        exact: true,
        component: AddOrder,
      },
      {
        key: 'clientAccount',
        path: '/moje-dane',
        exact: true,
        component: Account,
      },
      {
        key: 'clientChat',
        path: '/chat/:idOrder/:idOffer',
        exact: true,
        component: Chat,
      },
      {
        key: 'notfound',
        path: '*',
        exact: true,
        redirect: '/404',
      },
    ],
  },
];
