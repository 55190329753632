import { useAppSelector } from 'hooks/index';
import { selectorOrders } from 'features/badges/badgesSlice';
import { useEffect, useState } from 'react';
import { TabRoute } from 'helpers/types';
import { OrderList } from 'helpers/enums';
import { useTranslation } from 'react-i18next';

const tabsInitial: TabRoute[] = [
  {
    key: 'tabOngoing',
    name: 'W toku',
    path: '/w-toku',
    list: OrderList.ongoing,
  },
  {
    key: 'tabOffers',
    name: 'Oferty',
    path: '/oferty',
    list: OrderList.offers,
  },
  {
    key: 'tabFinished',
    name: 'Zakończone',
    path: '/zakonczone',
    list: OrderList.finished,
  },
];

const useOrderTabs = () => {
  const { t } = useTranslation(['badge']);
  const [tabs, setTabs] = useState<TabRoute[]>(tabsInitial);
  const badges = useAppSelector(selectorOrders);

  useEffect(() => {
    setTabs(
      tabsInitial.map((tab) => {
        const hasNew = badges.some((b) => b.list === tab.list);

        if (hasNew) {
          return {
            ...tab,
            badge: t('badge:new'),
          };
        }

        return tab;
      }),
    );
  }, [badges]);

  return tabs;
};

export default useOrderTabs;
