import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { Button } from 'components';

type Props = {
  value?: string;
  error: boolean;
  disabled?: boolean;
  resetFn: any;
  placeholder?: string;
  className?: string;
};

const defaultProps = {
  value: '',
  disabled: false,
  placeholder: '',
  className: '',
};

const Trigger = forwardRef(
  (
    { value, error, disabled, resetFn, placeholder, className: classNameExtend, ...rest }: Props,
    ref: ForwardedRef<any>,
  ): JSX.Element => {
    return (
      <div
        className={classNames('flex border', {
          'bg-black-5': !error,
          'border-black-5': !error && !value,
          'border-primary': !error && value,
          'bg-error-75 border-error-75': error,
          'opacity-50': disabled,
        })}
      >
        <button
          type="button"
          className={classNames(
            classNameExtend,
            'w-full min-h-40 xl:min-h-48 px-16 text-black-75 text-15 text-left flex items-center justify-between truncate',
            {
              'cursor-not-allowed': disabled,
            },
          )}
          ref={ref}
          disabled={disabled}
          {...rest}
        >
          {value || placeholder}
        </button>
        {value && (
          <Button
            as="button"
            to=""
            icon="close"
            onClick={resetFn}
            transparent
            className="w-48"
            disabled={disabled}
          />
        )}
      </div>
    );
  },
);

Trigger.defaultProps = defaultProps;

export default Trigger;
