import { useAppSelector } from 'hooks/index';
import { loadingSelectors } from 'features/loading/loadingSlice';

const useLoading = () => {
  const activeItems = useAppSelector(loadingSelectors.selectIds);

  const check = (items: string[]) => {
    return items.some((item) => activeItems.includes(item));
  };

  return {
    check,
  };
};

export default useLoading;
