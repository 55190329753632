import qs from 'qs';
import api, { ApiError } from 'services';
import type { ListApiResponse, ListRequest, ListResponse, Editor, Rate } from 'helpers/types';
import type { Props as ActivityFieldType } from 'layouts/ActivityField/ActivityField';
import { parseAttachment } from 'helpers/attachments';
import { mapRateExtend } from 'services/order/helpers';

export const fetchEditors = async (params: ListRequest): Promise<ListResponse<Editor>> => {
  try {
    const { records, total }: ListApiResponse = await api
      .get('public/editor', {
        searchParams: qs.stringify(params),
      })
      .json();

    return {
      items: records.map((n) => ({
        id: n.user.id,
        name: n.user.name,
        profilePhoto: n.user.profilePhoto ? parseAttachment(n.user.profilePhoto) : null,
        avgRate: n.avgRate,
        ongoingOrders: n.ongoingOrders,
        finishedOrders: n.finishedOrders,
        rankingPos: n.rankingPos,
      })),
      total,
    };
  } catch (error) {
    return {
      items: [],
      total: 0,
    };
  }
};

export const fetchEditor = async (idEditor: number): Promise<Editor> => {
  try {
    const { id, name, profilePhoto, editorProfile } = await api.get(`editor/${idEditor}`).json();

    return {
      id,
      name,
      profilePhoto: profilePhoto ? parseAttachment(profilePhoto) : null,
      avgRate: editorProfile.avgRate,
      ongoingOrders: editorProfile.ongoingOrders,
      finishedOrders: editorProfile.finishedOrders,
      rankingPos: editorProfile.rankingPos,
      aboutMe: editorProfile.aboutMe,
      education: editorProfile.education,
      nativeLanguage: editorProfile.nativeLanguage,
      gender: editorProfile.gender,
    };
  } catch (error) {
    throw await new ApiError(error).redirect404();
  }
};

export const fetchEditorReviews = async (
  params: ListRequest,
  idEditor: number,
): Promise<ListResponse<Rate>> => {
  try {
    const { records, total }: ListApiResponse = await api
      .get(`editor/${idEditor}/rate`, {
        searchParams: qs.stringify(params),
      })
      .json();

    return {
      items: records.map(mapRateExtend),
      total,
    };
  } catch (error) {
    throw await new ApiError(error).redirect404();
  }
};

export const fetchEditorActivityFields = async (idEditor: number): Promise<ActivityFieldType[]> => {
  try {
    return await api.get(`editor/${idEditor}/field`).json();
  } catch (error) {
    throw await new ApiError(error).redirect404();
  }
};

export const sendContact = async (data: {
  name: string;
  email: string;
  content: string;
  rulesAccepted: boolean;
}): Promise<void> => {
  try {
    await api.post('public/contact-form', {
      json: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};
