import api, { ApiError, withAuthorizationHeader } from 'services/index';
import type { BadgeOrder } from 'helpers/types';
import { OrderList } from 'helpers/enums';

export type OrderBadgeResponse = {
  textOrderId: number;
  status: string;
  attachmentBadge: boolean;
  chatBadge: boolean;
  newBadge: boolean;
};

const getOrderStatusFromApi = (status: string): OrderList => {
  switch (status) {
    case 'accepted':
    case 'in-progress':
      return OrderList.ongoing;
    case 'created':
      return OrderList.offers;
    case 'finished':
      return OrderList.finished;
    default:
      return OrderList.undefined;
  }
};

export const orders = async (): Promise<BadgeOrder[]> => {
  try {
    const response: OrderBadgeResponse[] = await api
      .get('text-order/badge', {
        headers: withAuthorizationHeader(),
      })
      .json();

    return response.reduce((prev: BadgeOrder[], curr: OrderBadgeResponse) => {
      const badges: BadgeOrder[] = [];
      const list = getOrderStatusFromApi(curr.status);

      if (curr.chatBadge) {
        badges.push({
          id: curr.textOrderId,
          list,
          type: 'chat',
        });
      }

      if (curr.attachmentBadge) {
        badges.push({
          id: curr.textOrderId,
          list,
          type: 'attachment',
        });
      }

      if (curr.newBadge) {
        badges.push({
          id: curr.textOrderId,
          list,
          type: 'offer',
        });
      }

      return [...prev, ...badges];
    }, []);
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const clearOrderBadges = async (id: number | string): Promise<void> => {
  try {
    await api.delete(`text-order/${String(id)}/badge`, {
      headers: withAuthorizationHeader(),
    });
  } catch (error) {
    console.log('Fail remove order badges');
  }
};
