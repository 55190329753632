import React from 'react';
import { PageTitle } from 'components';
import { EditorEditAccount } from 'forms';

const Account = (): JSX.Element => {
  return (
    <>
      <PageTitle>Moje dane</PageTitle>
      <EditorEditAccount />
    </>
  );
};

export default Account;
