import React, { useState } from 'react';
import { Alert, Modal, Pagination } from 'components';
import { Order, OrderSummary, Stages } from 'layouts';
import Loading from 'features/loading/Loading';
import { useTranslation } from 'react-i18next';
import { AlertStatus, OrderList } from 'helpers/enums';
import { useOrders } from 'hooks';
import { AddStageOfCorrection } from 'forms';

const Ongoing = (): JSX.Element => {
  const { t } = useTranslation(['alert', 'text']);
  const [list] = useOrders(OrderList.ongoing, 'EDITOR_ORDERS_ONGOING');
  const [addAttachment, setAddAttachment] = useState<[number, number] | null>(null);

  const renderOrders = () =>
    list.items.map((item) => {
      return (
        <Order key={item.id} {...item}>
          <div className="border-t border-black-10 pt-12 xl:pt-16">
            <Stages
              stages={item.stages}
              idOrder={item.id}
              refreshFn={() => list.setPage(1)}
              addAttachmentFn={(idO, idS) => setAddAttachment([idO, idS])}
            />
            <div className="mt-16 xl:mt-24">
              <OrderSummary idOrder={item.id} offers={item.offers} titlePrice={t('text:reward')} />
            </div>
          </div>
        </Order>
      );
    });

  return (
    <>
      <Loading items={['EDITOR_ORDERS_ONGOING']}>
        {list.items.length ? (
          renderOrders()
        ) : (
          <Alert status={AlertStatus.info} message={t('alert:noResults')} />
        )}
        <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
      </Loading>
      <Modal
        title={t('text:addStage')}
        isOpen={!!addAttachment}
        onRequestClose={() => setAddAttachment(null)}
      >
        {addAttachment && (
          <AddStageOfCorrection
            id={addAttachment[1]}
            idOrder={addAttachment[0]}
            onRequestClose={() => setAddAttachment(null)}
            onSuccess={() => {
              setAddAttachment(null);
              list.setPage(1);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default Ongoing;
