import api from 'services';
import type { OptionItem } from 'helpers/types';

export const fetchCountries = async (): Promise<OptionItem[]> => {
  try {
    return await api.get('public/country').json();
  } catch (error) {
    return [];
  }
};

export const fetchLanguages = async (): Promise<OptionItem[]> => {
  try {
    return await api.get('public/language').json();
  } catch (error) {
    return [];
  }
};

export const fetchEducations = async (): Promise<OptionItem[]> => {
  try {
    return await api.get('public/education').json();
  } catch (error) {
    return [];
  }
};

export const fetchActivityFields = async (): Promise<OptionItem[]> => {
  try {
    return await api.get('public/activity-field').json();
  } catch (error) {
    return [];
  }
};

export const fetchScopeOfOrders = async (): Promise<OptionItem[]> => {
  try {
    return await api.get('public/scope-of-order').json();
  } catch (error) {
    return [];
  }
};

export const fetchTypesOfStudy = async (): Promise<OptionItem[]> => {
  try {
    return await api.get('public/type-of-study').json();
  } catch (error) {
    return [];
  }
};
