import React from 'react';
import type { Editor as EditorType } from 'helpers/types';
import { Price, Button } from 'components';
import { useTranslation } from 'react-i18next';
import { ChatTrigger, User } from 'layouts';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { AlertStatus } from 'helpers/enums';
import { useAppDispatch, useLoading } from 'hooks';
import { clientService } from 'services';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { catchApiError } from 'helpers/error';

type Props = {
  id: number;
  idOrder: number;
  editor: EditorType;
  price: number;
  handleSuccess: () => void;
};

const defaultProps = {};

function Offer({ id, idOrder, editor, price, handleSuccess }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['text', 'button', 'alert']);
  const loading = useLoading();

  const handleClick = async () => {
    try {
      dispatch(addLoading({ id: `OFFER_ACCEPT_${idOrder}` }));
      dispatch(addLoading({ id: `OFFER_ACCEPT_TRIGGER_${id}` }));

      await clientService.acceptOffer(id);

      dispatch(
        addNotification({
          id: 'offer.accept',
          status: AlertStatus.success,
          message: t('alert:acceptOffer'),
        }),
      );

      handleSuccess();
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'offer.accept',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    } finally {
      dispatch(removeLoading(`OFFER_ACCEPT_${idOrder}`));
      dispatch(removeLoading(`OFFER_ACCEPT_TRIGGER_${id}`));
    }
  };

  return (
    <div>
      <div className="bg-primary-lt p-12 xl:p-24 text-center">
        <div className="mb-16">
          <Price title={t('text:estimation')} price={price} transparent />
        </div>
        <div className="mb-20">
          <User {...editor} small />
        </div>
        <ChatTrigger idOrder={idOrder} idOffer={id} />
      </div>
      <div className="py-12 xl:px-8 text-center">
        <Button
          as="button"
          to=""
          secondary
          onClick={handleClick}
          loading={loading.check([`OFFER_ACCEPT_TRIGGER_${id}`])}
          disabled={loading.check([`OFFER_ACCEPT_${idOrder}`])}
        >
          {t('button:acceptOffer')}
        </Button>
      </div>
    </div>
  );
}

Offer.defaultProps = defaultProps;

export default Offer;
