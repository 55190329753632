import React from 'react';
import { Icon } from 'components';
import { AlertStatus } from 'helpers/enums';

type Props = {
  status: AlertStatus;
};

function IconAlert({ status }: Props): JSX.Element {
  let icon: string;
  let color: string;

  switch (status) {
    case AlertStatus.info:
      icon = 'alertInfo';
      color = '#00f';
      break;
    case AlertStatus.error:
      icon = 'alertError';
      color = '#f00';
      break;
    default:
      icon = 'alertSuccess';
      color = '#26bd0f';
  }

  return (
    <Icon
      icon={icon}
      beforeInjection={(svg) => {
        svg.classList.add('w-20', 'h-20');
        svg.style.fill = color;
      }}
    />
  );
}

export default IconAlert;
