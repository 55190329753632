import React from 'react';
import type { Rate as RateType } from 'helpers/types';
import { RatingStars } from 'components';
import { dateFormat } from 'helpers/date';

type Props = RateType;

const defaultProps = {
  title: '',
  subtitle: '',
};

function Rate({ rate, title, subtitle, message, created }: Props): JSX.Element | null {
  return (
    <div>
      <div className="mb-8 xl:mb-16 xl:flex items-center justify-between">
        <div>
          <p className="font-headings font-bold text-black text-18 xl:text-20">{title}</p>
          {subtitle && (
            <p className="font-semibold text-black-75 text-15 xl:text-16 leading-25 xl:leading-28 mt-8">
              {subtitle}
            </p>
          )}
        </div>
        <div className="flex items-center justify-between md:justify-start mt-8 xl:ml-16 xl:mt-0">
          <RatingStars value={rate} />
          <p className="text-12 text-black-75 opacity-50 ml-16 mt-2">{dateFormat(created)}</p>
        </div>
      </div>
      <p className="text-15 xl:text-16 leading-25 xl:leading-28 text-black-75">{message}</p>
    </div>
  );
}

Rate.defaultProps = defaultProps;

export default Rate;
