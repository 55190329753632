import React from 'react';
import { Rating } from 'components';
import { EditorStats } from 'layouts';
import { images } from 'assets';
import { Editor } from 'helpers/types';
import { Link } from 'react-router-dom';

type Props = {
  user: Editor;
  publicLink?: boolean;
};

const defaultProps = {
  publicLink: false,
};

function EditorThumb({ user, publicLink }: Props): JSX.Element {
  const { name, profilePhoto, avgRate, ongoingOrders, finishedOrders, rankingPos } = user;

  const Avatar = () => (
    <img
      className="w-64 h-64 rounded-full"
      src={profilePhoto ? profilePhoto.src : images.avatar}
      alt={name}
      onError={(e) => {
        const target = e.target as HTMLImageElement;

        target.src = images.avatar;
      }}
    />
  );

  return (
    <div className="border-2 border-primary-lt p-16 xl:p-40">
      <div className="flex items-center mb-16 xl:mb-24">
        <div className="flex-grow-0 flex-shrink-0 mr-16">
          {publicLink ? (
            <Link to={`/redaktorzy/${user.id}`}>
              <Avatar />
            </Link>
          ) : (
            <Avatar />
          )}
        </div>
        <div className="w-full flex justify-between items-center">
          {publicLink ? (
            <Link
              to={`/redaktorzy/${user.id}`}
              className="text-15 xl:text-16 font-semibold text-black-75"
            >
              {name}
            </Link>
          ) : (
            <p className="text-15 xl:text-16 font-semibold text-black-75">{name}</p>
          )}
          {!!avgRate && <Rating rating={avgRate} small />}
        </div>
      </div>
      <EditorStats
        ongoingOrders={ongoingOrders}
        finishedOrders={finishedOrders}
        rankingPos={rankingPos}
      />
    </div>
  );
}

EditorThumb.defaultProps = defaultProps;

export default EditorThumb;
