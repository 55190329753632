import React, { useEffect, useState } from 'react';
import { Icon, Modal } from 'components';
import { isIOS } from 'react-device-detect';

function PwaModal() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isIOS && !sessionStorage.getItem('pwa-prompt')) {
      setTimeout(() => setShow(true), 1000);
      sessionStorage.setItem('pwa-prompt', 'true');
    }
  }, []);

  useEffect(() => {
    const handleShow = () => {
      setShow(true);
    };

    window.addEventListener('pwaprompt', handleShow);

    return () => {
      window.removeEventListener('pwaprompt', handleShow);
    };
  }, []);

  return (
    <Modal title="Dodaj do ekranu początkowego" isOpen={show} onRequestClose={() => setShow(false)}>
      <p className="text-15 xl:text-16 leading-25 xl:leading-28">
        Aby dodać aplikację do ekranu początkowego wykonaj kroki poniżej.
      </p>
      <ul className="mt-32">
        <li className="flex items-center border-b border-black-5 pb-16 mb-16">
          <Icon
            icon="share"
            className="w-32 h-32 flex items-center justify-center mr-12"
            beforeInjection={(svg) => {
              svg.classList.add('h-32', 'fill-body');
            }}
          />
          <p>Kliknij w przycisk &quot;Share&quot;</p>
        </li>
        <li className="flex items-center">
          <Icon
            icon="plus"
            className="w-32 h-32 flex items-center justify-center mr-12"
            beforeInjection={(svg) => {
              svg.classList.add('h-32', 'stroke-body');
            }}
          />
          <p>Do ekranu początkowego</p>
        </li>
      </ul>
    </Modal>
  );
}

export default PwaModal;
