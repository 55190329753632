import api, { ApiError } from 'services';
import qs from 'qs';
import type { ListApiResponse, ListRequest, ListResponse } from 'helpers/types';
import { Props as ArticleThumbType } from 'layouts/ArticleThumb/ArticleThumb';
import { Page } from 'helpers/enums';
import {
  parseHome,
  parseAbout,
  parseArticle,
  parseEditors,
  parseContact,
  parseTextPage,
  mapImage,
} from './helpers';
import { ArticlePage } from './types';

export const fetch = async <T>(page: Page | null): Promise<any> => {
  try {
    const response = await api.get(`public/page/${page}`).json();

    switch (page) {
      case Page.home:
        return parseHome(response);
      case Page.about:
        return parseAbout(response);
      case Page.editors:
        return parseEditors(response);
      case Page.contact:
        return parseContact(response);
      case Page.regulations:
      case Page.privacy:
      case Page.faq:
        return parseTextPage(response);
      default:
        throw new Error();
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const articles = async (params: ListRequest): Promise<ListResponse<ArticleThumbType>> => {
  try {
    const { records, total }: ListApiResponse = await api
      .get('public/article', {
        searchParams: qs.stringify(params),
      })
      .json();

    return {
      items: records.map((n) => ({
        id: n.id,
        slug: n.slug,
        title: n.title,
        shortDescription: n.shortDescription,
        image: mapImage(n.thumbImageUrl, n.thumbImageAlt),
      })),
      total,
    };
  } catch (error) {
    return {
      items: [],
      total: 0,
    };
  }
};

export const article = async (slug: string): Promise<ArticlePage> => {
  try {
    const response = await api.get(`public/article/${slug}`).json();

    return parseArticle(response);
  } catch (error) {
    throw await new ApiError(error).redirect404();
  }
};
