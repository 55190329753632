import React from 'react';
import { images } from 'assets';
import { PageContent } from 'layouts';
import { Button } from 'components';

const NotFound = (): JSX.Element => {
  return (
    <PageContent>
      <div className="container">
        <div className="row items-center">
          <div className="col md:w-1/2 mb-32 md:mb-0">
            <h1 className="font-headings font-bold text-black text-32 xl:text-48 mb-12 xl:mb-16">
              Nie znaleziono strony
            </h1>
            <p className="font-headings font-bold text-black text-18 xl:text-20 leading-22 xl:leading-28">
              Upewnij się, że wpisałeś poprawny adres lub spróbuj ponownie później.
            </p>
            <div className="mt-24 xl:mt-40">
              <Button as="route" to="/">
                Powrót do strony głównej
              </Button>
            </div>
          </div>
          <div className="col md:w-1/2 text-center md:text-right">
            <img src={images.notFound} alt="404" className="inline-block" />
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default NotFound;
