import React from 'react';
import './Checkbox.scss';
import classNames from 'classnames';
import { InputError } from 'components';
import type { EmitOnChange } from 'helpers/types';

export type CheckboxItem = {
  id: string | number;
  name: string;
};

type Props = {
  name: string;
  register: any;
  idPrefix: string;
  items: CheckboxItem[];
  error?: any;
  disabled?: boolean;
  className?: string;
  itemWrapper?: any;
  onChangeEmit?: (v: EmitOnChange) => void;
};

const defaultProps = {
  error: null,
  disabled: false,
  className: '',
  itemWrapper: null,
  onChangeEmit: null,
};

const DefaultItemWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="mb-12 xl:mb-16">{children}</div>
);

function CheckboxGroup({
  name,
  register,
  idPrefix,
  items,
  error,
  disabled,
  className: classNameExtend,
  itemWrapper,
  onChangeEmit,
}: Props): JSX.Element {
  const renderItem = (item: CheckboxItem) => {
    const id = `${idPrefix}-${item.id}`;
    const ItemWrapper = itemWrapper || DefaultItemWrapper;

    const { onChange, ...registerRest } = register(name);

    return (
      <ItemWrapper key={id}>
        <div
          className={classNames('checkbox', {
            'checkbox--error': error,
            'opacity-50 cursor-not-allowed pointer-events-none': disabled,
          })}
        >
          <input
            type="checkbox"
            id={id}
            {...registerRest}
            value={item.id}
            onChange={(e) => {
              onChange(e);
              if (onChangeEmit) {
                onChangeEmit({
                  name,
                  value: item.id,
                });
              }
            }}
          />
          <label htmlFor={id}>{item.name}</label>
        </div>
      </ItemWrapper>
    );
  };

  return (
    <div className="mb-12 xl:mb-16">
      <div className={classNameExtend}>{items.map((item) => renderItem(item))}</div>
      <InputError message={error?.message} />
    </div>
  );
}

CheckboxGroup.defaultProps = defaultProps;

export default CheckboxGroup;
