import React, { useState } from 'react';
import { Alert, Button, Modal, PageTitle, Pagination } from 'components';
import { Order } from 'layouts';
import { EditorAddOffer, EditorSearchOrder } from 'forms';
import type { Order as OrderType } from 'helpers/types';
import Loading from 'features/loading/Loading';
import { useTranslation } from 'react-i18next';
import { useList } from 'hooks';
import { AlertStatus } from 'helpers/enums';
import i18n from 'i18n';

const Dashboard = (): JSX.Element => {
  const { t } = useTranslation(['alert', 'button', 'text', 'badge']);
  const [addOffer, setAddOffer] = useState<number | null>(null);

  const list = useList<OrderType>();

  const renderOrders = () =>
    list.items.map((item) => {
      const hasOffer = !!item.offers.length;

      return (
        <Order
          key={item.id}
          {...item}
          badges={
            hasOffer
              ? [
                  {
                    title: i18n.t('badge:hasOffer'),
                    status: AlertStatus.success,
                  },
                ]
              : null
          }
        >
          <Button as="button" to="" onClick={() => setAddOffer(item.id)}>
            {t(`button:${hasOffer ? 'editOffer' : 'addOffer'}`)}
          </Button>
        </Order>
      );
    });

  return (
    <>
      <PageTitle>Rynek zleceń</PageTitle>
      <EditorSearchOrder
        page={list.page}
        perPage={list.perPage}
        changePage={list.setPage}
        handleFetch={list.handleFetch}
      />
      <div className="mt-32 xl:mt-40">
        <Loading items={['EDITOR_SEARCH']}>
          {list.items.length ? (
            renderOrders()
          ) : (
            <Alert status={AlertStatus.info} message={t('alert:noResults')} />
          )}
          <Pagination page={list.page} total={list.total} changePage={(v) => list.setPage(v)} />
        </Loading>
        <Modal title="Złóż ofertę" isOpen={!!addOffer} onRequestClose={() => setAddOffer(null)}>
          {addOffer && <EditorAddOffer id={addOffer} onRequestClose={() => setAddOffer(null)} />}
        </Modal>
      </div>
    </>
  );
};

export default Dashboard;
