import api, { ApiError, withAuthorizationHeader } from 'services';
import type { User } from 'helpers/types';

export const editAccount = async (data: FormData): Promise<User> => {
  try {
    return await api
      .post('profile/client', {
        headers: withAuthorizationHeader(),
        body: data,
      })
      .json();
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const acceptOffer = async (id: number): Promise<void> => {
  try {
    await api.post(`offer/${id}/accept`, {
      headers: withAuthorizationHeader(),
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};
