import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import type { BadgeOrder } from 'helpers/types';
import { badgesService } from 'services';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { RootState } from 'store';

type Badges = {
  orders: BadgeOrder[];
};

const initialState = {
  orders: [],
} as Badges;

export const fetchOrders = createAsyncThunk('badges/fetchOrders', async () => {
  return badgesService.orders();
});

export const fetchBadges = createAsyncThunk(
  'badges/fetch',
  async (arg, { dispatch }): Promise<void> => {
    const creators: Promise<any>[] = [];

    dispatch(addLoading({ id: 'FETCH_BADGES' }));

    creators.push(dispatch(fetchOrders()));
    await Promise.all(creators);

    dispatch(removeLoading('FETCH_BADGES'));

    return Promise.resolve();
  },
);

const slice = createSlice({
  name: 'badges',
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchOrders.fulfilled}`]: (state, action) => {
      state.orders = action.payload;
    },
  },
});

export const selectorBadges = (state: RootState) => state.badges;

export const selectorOrders = createSelector(selectorBadges, (badges) => badges.orders);

export const getOrderBadges = (id: number) =>
  createSelector(selectorOrders, (orders) => orders.filter((o) => o.id === id));

export default slice.reducer;
