import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ButtonLink } from 'components';
import type { Image } from 'helpers/types';

export type Props = {
  id: number;
  slug: string;
  title: string;
  shortDescription: string;
  image: Image | null;
};

const defaultProps = {};

function ArticleThumb({ slug, title, shortDescription, image }: Props): JSX.Element {
  const { t } = useTranslation(['button']);

  return (
    <div className="border-2 border-primary-lt">
      <div className="h-200 xl:h-240">
        {image && (
          <img
            src={image.url}
            alt={image.alt}
            className="object-cover object-center w-full h-full"
          />
        )}
      </div>
      <div className="p-16 xl:p-24">
        <Link
          to={`/artykuly/${slug}`}
          className="font-headings font-bold text-black text-18 xl:text-20 leading-22 xl:leading-28 mb-8"
        >
          {title}
        </Link>
        <p className="text-black-75 text-15 xl:text-16 leading-25 xl:leading-28">
          {shortDescription}
        </p>
        <div className="mt-16">
          <ButtonLink as="route" to={`/artykuly/${slug}`}>
            {t('button:readMore')}
          </ButtonLink>
        </div>
      </div>
    </div>
  );
}

ArticleThumb.defaultProps = defaultProps;

export default ArticleThumb;
