import React, { useState, useEffect, useRef } from 'react';
import './MenuMobile.scss';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'components';
import classNames from 'classnames';
import { images } from 'assets';

type Props = {
  children: React.ReactNode;
};

function MenuMobile({ children }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLElement>(null);
  const location = useLocation();

  useEffect(() => {
    const handleClick = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <nav
      className="xl:hidden fixed w-full top-0 left-0 z-40 bg-white border-b-2 border-black-5"
      ref={menuRef}
    >
      <div className="container">
        <div className="row items-center h-80">
          <div className="col w-1/2">
            <Link to="/" className="block py-8">
              <img src={images.logo} alt="logo" className="h-40" />
            </Link>
          </div>
          <div className="col w-1/2 text-right">
            <button
              type="button"
              className={classNames('menu-mobile-toggle', {
                'is-open': isOpen,
              })}
              onClick={() => setIsOpen(!isOpen)}
            >
              <div>
                <span />
                <span />
                <span />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div
        className={classNames('menu-mobile-content', {
          'is-open': isOpen,
        })}
      >
        <div className="flex items-center h-80 px-16">
          <Link to="/" className="block py-8">
            <img src={images.logo} alt="logo" className="h-40" />
          </Link>
        </div>
        <div className="menu-mobile-content__inner">{children}</div>
      </div>
    </nav>
  );
}

export default MenuMobile;
