import React from 'react';
import { useAppSelector } from 'hooks';
import { Loader } from 'components';
import { loadingSelectors } from './loadingSlice';

type Props = {
  children: React.ReactNode;
  items: string[];
};

const Loading = ({ children, items }: Props): JSX.Element => {
  const activeItems = useAppSelector(loadingSelectors.selectIds);
  const isLoading = items.some((item) => activeItems.includes(item));

  if (isLoading) return <Loader page />;

  return <>{children}</>;
};

export default Loading;
