import React from 'react';
import { useRoutes, useRoutesFirst, useOrderTabs } from 'hooks';
import { useRouteMatch } from 'react-router-dom';
import { Route } from 'routes';
import { PageTitle, Tabs } from 'components';

type Props = {
  routes: Route[];
};

function Orders({ routes }: Props): JSX.Element {
  const { path } = useRouteMatch();
  const tabs = useOrderTabs();
  const [renderRoutes] = useRoutes(routes, path);
  useRoutesFirst(routes);

  return (
    <>
      <PageTitle>Moje zlecenia</PageTitle>
      <Tabs routes={tabs} path={path} />
      <div className="mt-32 xl:mt-40">{renderRoutes()}</div>
    </>
  );
}

export default Orders;
