import React from 'react';
import { Icon } from 'components';

type Props = {
  children: React.ReactNode;
  icon: string;
};

function Item({ children, icon }: Props): JSX.Element {
  return (
    <li className="mb-8 flex items-center">
      <div className="flex-grow-0 flex-shrink-0 mr-8">
        <Icon
          icon={icon}
          beforeInjection={(svg) => {
            svg.classList.add('w-16', 'h-16', 'fill-primary-dk');
          }}
        />
      </div>
      <p className="text-15 xl:text-16 leading-25 xl:leading-28 text-black-75">{children}</p>
    </li>
  );
}

export default Item;
