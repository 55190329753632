import React from 'react';
import type { Payment as PaymentType, Badge } from 'helpers/types';
import { Box } from 'layouts';
import { dateFormat } from 'helpers/date';
import type { Props as HeaderProps } from 'layouts/Box/Header';
import { formatViewPrice } from 'helpers/price';

export type Props = PaymentType & {
  children?: React.ReactNode;
  badges?: Badge[] | null;
  open?: boolean;
};

const defaultProps = {
  children: '',
  badges: [],
  open: false,
};

const InfoCol = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div className="col md:w-1/2 lg:w-1/5 mb-8">{children}</div>
);

function Payment({
  children,
  open,
  label,
  title,
  user,
  badges,
  price,
  term,
  delay,
  penalty,
  toPay,
}: Props): JSX.Element {
  const renderInfo = (l: string, v: string | number): JSX.Element => {
    return (
      <>
        <Box.Label>{l}</Box.Label>
        <Box.Text>{v}</Box.Text>
      </>
    );
  };

  return (
    <Box
      open={open}
      header={(p: HeaderProps) => (
        <Box.Header label={label} title={title} user={user} badges={badges} {...p} />
      )}
    >
      <div className="row">
        {price || price === 0 ? (
          <InfoCol>{renderInfo('Wynagrodzenie', `${formatViewPrice(price)}\u00A0zł`)}</InfoCol>
        ) : null}
        {term && <InfoCol>{renderInfo('Termin oddania', dateFormat(term))}</InfoCol>}
        {delay || delay === 0 ? (
          <InfoCol>{renderInfo('Opóźnienie', `${delay} dni`)}</InfoCol>
        ) : null}
        {penalty || penalty === 0 ? (
          <InfoCol>
            {renderInfo('Kara za opóźnienie', `${formatViewPrice(penalty)}\u00A0zł`)}
          </InfoCol>
        ) : null}
        {toPay || toPay === 0 ? (
          <InfoCol>{renderInfo('Do zapłaty', `${formatViewPrice(toPay)}\u00A0zł`)}</InfoCol>
        ) : null}
      </div>
      {children ? <div className="mt-16 xl:mt-24">{children}</div> : null}
    </Box>
  );
}

Payment.defaultProps = defaultProps;

export default Payment;
