import { Role } from 'helpers/enums';
import { RouteProps } from 'react-router-dom';
import clientRoutes from './client';
import editorRoutes from './editor';
import appRoutes from './app';

export interface Route extends RouteProps {
  key?: string;
  redirect?: string;
  withAuth?: boolean;
  role?: Role;
  routes?: Route[];
}

export type LinkRoute = {
  key: string;
  name: string;
  path: string;
  exact: boolean;
};

export const routes: Route[] = [...clientRoutes, ...editorRoutes, ...appRoutes];
