import React from 'react';

type Props = {
  children: React.ReactNode;
};

function PageContent({ children }: Props): JSX.Element {
  return <div className="mt-40 mb-64 xl:mt-64 xl:mb-96">{children}</div>;
}

export default PageContent;
