import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Input } from 'components';
import { useFieldsErrors, useAppDispatch } from 'hooks';
import { authService } from 'services';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { useHistory } from 'react-router-dom';
import { AlertStatus } from 'helpers/enums';
import { catchApiError } from 'helpers/error';

type Inputs = {
  password: string;
  passwordConfirm: string;
};

const schema = yup.object().shape({
  password: yup.string().required('To pole jest wymagane'),
  passwordConfirm: yup
    .string()
    .required('To pole jest wymagane')
    .oneOf([yup.ref('password')], 'Podane hasła nie są takie same'),
});

function ForgotPasswordReset(): JSX.Element {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useFieldsErrors('form-forgot-password', errors);

  const onSubmit = async ({ password }: Inputs) => {
    try {
      const query = new URLSearchParams(history.location.search);

      await authService.forgotPasswordReset({
        password,
        token: query.get('token') || '',
      });

      history.push('/auth/forgot-password/success');
    } catch (error) {
      catchApiError(error, () =>
        dispatch(
          addNotification({
            id: 'auth.forgotPasswordReset',
            status: AlertStatus.error,
            message: error.message,
          }),
        ),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input type="password" control={control} name="password" placeholder="Hasło" />
      <Input type="password" control={control} name="passwordConfirm" placeholder="Powtórz hasło" />
      <div className="mt-16">
        <Button as="submit" to="" loading={isSubmitting}>
          Zapisz hasło
        </Button>
      </div>
    </form>
  );
}

export default ForgotPasswordReset;
