import React, { useEffect } from 'react';
import { useAppSelector, useRoutes, useUserAction } from 'hooks';
import { Route } from 'routes';
import { useHistory, useRouteMatch, matchPath } from 'react-router-dom';
import { LayoutDashboard, LoggedUser, SidebarLink } from 'layouts';
import { authService } from 'services';
import { Button, Badge } from 'components';
import { selectorOrders } from 'features/badges/badgesSlice';
import { useTranslation } from 'react-i18next';

type Props = {
  routes: Route[];
};

function Client({ routes }: Props): JSX.Element {
  const { t } = useTranslation(['badge']);
  const { path } = useRouteMatch();
  const [renderRoutes] = useRoutes(routes, path);
  const history = useHistory();
  const hasOrdersBadge = !!useAppSelector(selectorOrders).length;
  useUserAction();

  useEffect(() => {
    document.body.classList.add('pt-80', 'xl:pt-0');

    return () => {
      document.body.classList.remove('pt-80', 'xl:pt-0');
    };
  }, []);

  const isActive = (value: Route) => {
    const match = matchPath(history.location.pathname, value);

    return !!match;
  };

  const renderControls = () => (
    <>
      <LoggedUser />
      <ul className="mt-40 xl:mt-64">
        <li className="mb-24">
          <SidebarLink
            as="route"
            to="/klient"
            icon="dashboard"
            active={isActive({
              path: '/klient',
              exact: true,
            })}
          >
            Dashboard
          </SidebarLink>
        </li>
        <li className="mb-24">
          <div className="flex justify-between items-center w-full">
            <SidebarLink
              as="route"
              to="/klient/moje-zlecenia"
              icon="files"
              active={isActive({
                path: '/klient/moje-zlecenia',
              })}
            >
              Moje zlecenia
            </SidebarLink>
            {hasOrdersBadge && <Badge title={t('badge:new')} />}
          </div>
        </li>
        <li className="mb-24">
          <SidebarLink
            as="route"
            to="/klient/moje-dane"
            icon="user"
            active={isActive({
              path: '/klient/moje-dane',
            })}
          >
            Moje dane
          </SidebarLink>
        </li>
        <li className="mb-24">
          <SidebarLink as="button" to="" icon="logout" onClick={() => authService.logout()}>
            Wyloguj się
          </SidebarLink>
        </li>
        <li className="mb-24 xl:mt-48">
          <Button as="route" to="/klient/dodaj-zlecenie" icon="add">
            Dodaj zlecenie
          </Button>
        </li>
      </ul>
    </>
  );

  return <LayoutDashboard renderControls={renderControls()}>{renderRoutes()}</LayoutDashboard>;
}

export default Client;
