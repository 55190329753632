import React, { useEffect, useState } from 'react';
import { PageTitle, Button, Alert } from 'components';
import { useTranslation } from 'react-i18next';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { orderService } from 'services';
import { catchApiError } from 'helpers/error';
import { AlertStatus, PaymentStatus } from 'helpers/enums';
import { useAppDispatch } from 'hooks';
import { useParams } from 'react-router-dom';
import { Order, Stage } from 'layouts';
import { Order as OrderType, Stage as StageType } from 'helpers/types';
import Loading from 'features/loading/Loading';

type Params = {
  idPayment: string;
};

const Offers = (): JSX.Element => {
  const { idPayment } = useParams<Params>();
  const { t } = useTranslation(['text', 'button']);
  const [status, setStatus] = useState<PaymentStatus>();
  const [order, setOrder] = useState<OrderType>();
  const [stage, setStage] = useState<StageType>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const dispatch = useAppDispatch();

  const fetchStatus = async () => {
    try {
      dispatch(addLoading({ id: 'CLIENT_PAYMENT_STATUS' }));
      setErrorMessage('');

      const response = await orderService.paymentStatus(+idPayment);

      setStatus(response.status);
      setOrder(response.order);
      setStage(response.stage);
    } catch (error) {
      catchApiError(error, () => setErrorMessage(error.message));
    } finally {
      dispatch(removeLoading('CLIENT_PAYMENT_STATUS'));
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <>
      <PageTitle>Status</PageTitle>
      <Loading items={['CLIENT_PAYMENT_STATUS']}>
        {order && (
          <Order {...order} open>
            {stage && (
              <div className="row items-center">
                <div className="col w-1/2 md:w-1/3 xxxl:w-1/6 mb-12 xl:mb-16">
                  <Stage
                    idOrder={order.id}
                    {...stage}
                    prevStageStatus={null}
                    refreshFn={() => {}}
                    withoutActions
                  />
                </div>
                <div className="col w-1/2 md:w-1/3 xxxl:w-3/6 text-center">
                  <p className="text-12 xl:text-13 font-semibold text-primary-dk uppercase mb-12">
                    {t('text:status')}
                  </p>
                  <p className="uppercase font-semibold text-20 text-black">
                    {status === PaymentStatus.paid ? t('text:paid') : t('text:waitingForPayment')}
                  </p>
                  <div className="mt-24">
                    {status === PaymentStatus.paid ? (
                      <Button as="route" to="/klient/moje-zlecenia" icon="files" secondary>
                        {t('button:myOrders')}
                      </Button>
                    ) : (
                      <Button as="button" to="" icon="payments" onClick={fetchStatus} secondary>
                        {t('button:check')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Order>
        )}
        {errorMessage && <Alert status={AlertStatus.error} message={errorMessage} />}
      </Loading>
    </>
  );
};

export default Offers;
