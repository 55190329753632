import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'components';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  as: 'button' | 'link' | 'link-out' | 'route' | 'download';
  to: string;
  active?: boolean;
  icon?: string;
  className?: string;
  [x: string]: any;
};

const defaultProps = {
  active: false,
  icon: '',
  className: '',
};

const defaultStyle = classNames([
  'inline-flex items-center justify-center',
  'text-16 font-semibold',
]);

function SidebarLink({
  children,
  as,
  to,
  active,
  icon,
  className: classNameExtend,
  ...rest
}: Props): JSX.Element | null {
  const className = classNames(defaultStyle, classNameExtend, {
    'text-black': !active,
    'text-primary-dk': active,
  });

  const renderIcon = (iconOverride = icon): JSX.Element | null => {
    if (!iconOverride) return null;

    return (
      <Icon
        icon={iconOverride}
        className="pointer-events-none mr-12"
        beforeInjection={(svg) => {
          svg.classList.add('w-24', 'h-24', `${active ? 'fill-primary-dk' : 'fill-black'}`);
        }}
      />
    );
  };

  switch (as) {
    case 'button':
      return (
        <button type="button" className={className} {...rest}>
          {renderIcon()}
          {children}
        </button>
      );
    case 'link':
      return (
        <a href={to} className={className} {...rest}>
          {renderIcon()}
          {children}
        </a>
      );
    case 'link-out':
      return (
        <a href={to} target="_blank" rel="noopener noreferrer" className={className} {...rest}>
          {renderIcon()}
          {children}
        </a>
      );
    case 'route':
      return (
        <Link to={to} className={className} {...rest}>
          {renderIcon()}
          {children}
        </Link>
      );
    default:
      return null;
  }
}

SidebarLink.defaultProps = defaultProps;

export default SidebarLink;
