import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionItem } from 'helpers/types';
import { Rating } from 'components';

export type Props = {
  field: OptionItem;
  avgRate: number;
  count: number;
};

const defaultProps = {};

function ActivityField({ field, avgRate, count }: Props): JSX.Element {
  const { t } = useTranslation(['text']);

  return (
    <div className="border-b border-black-5 pb-12">
      <div className="flex justify-between mb-8">
        <p className="font-headings font-bold text-black text-18 xl:text-20 leading-22 xl:leading-28">
          {field.name}
        </p>
        <Rating rating={avgRate} />
      </div>
      <p className="text-12 xl:text-13 text-black-75 lowercase">
        <strong className="text-primary-dk font-semibold">{count}</strong>{' '}
        {t('text:finishedOrders')}
      </p>
    </div>
  );
}

ActivityField.defaultProps = defaultProps;

export default ActivityField;
