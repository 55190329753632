import qs from 'qs';
import api, { ApiError, withAuthorizationHeader } from 'services';
import type { ListApiResponse, Payment, User } from 'helpers/types';
import { ListRequest, ListResponse } from 'helpers/types';
import { PaymentList } from 'helpers/enums';
import { mapPayment } from './helpers';

export const editAccount = async (data: FormData): Promise<User> => {
  try {
    return await api
      .post('profile/editor', {
        headers: withAuthorizationHeader(),
        body: data,
      })
      .json();
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const addOffer = async (data: {
  textOrderId: number;
  amount: number;
  message: string;
}): Promise<void> => {
  try {
    await api.post('offer', {
      headers: withAuthorizationHeader(),
      json: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const cancelOffer = async (id: number): Promise<void> => {
  try {
    await api.post(`text-order/${id}/cancel-offers`, {
      headers: withAuthorizationHeader(),
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const payments = async (
  params: ListRequest,
  type: PaymentList,
): Promise<ListResponse<Payment>> => {
  try {
    let url = '';

    switch (type) {
      case PaymentList.unpaid:
        url = 'text-order/unpaid';
        break;
      case PaymentList.paid:
        url = 'text-order/paid';
        break;
      default:
    }

    const { records, total }: ListApiResponse = await api
      .get(url, {
        headers: withAuthorizationHeader(),
        searchParams: qs.stringify(params),
      })
      .json();

    return {
      items: records.map(mapPayment),
      total,
    };
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const addInvoice = async (id: number, data: FormData): Promise<void> => {
  try {
    await api.post(`text-order/${id}/payment-document`, {
      headers: withAuthorizationHeader(),
      body: data,
    });
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};

export const downloadBilling = async (): Promise<any> => {
  try {
    return await api
      .get('profile/editor-summary', {
        headers: withAuthorizationHeader(),
      })
      .blob();
  } catch (error) {
    throw await new ApiError(error).parse();
  }
};
