import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from 'store';
import useRoutes from './useRoutes';
import useRoutesFirst from './useRoutesFirst';
import useAccessibility from './useAccessibility';
import useFieldsErrors from './useFieldsErrors';
import useList from './useList';
import useOrders from './useOrders';
import useLoading from './useLoading';
import usePage from './usePage';
import useUserAction from './useUserAction';
import useOrderTabs from './useOrderTabs';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export {
  useRoutes,
  useRoutesFirst,
  useAccessibility,
  useFieldsErrors,
  useList,
  useOrders,
  useLoading,
  usePage,
  useUserAction,
  useOrderTabs,
};
