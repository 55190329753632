import React from 'react';
import { ButtonLink } from 'components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { authService } from 'services';
import { Role } from 'helpers/enums';

type Props = {
  idOrder: number;
  idOffer: number;
};

function ChatTrigger({ idOrder, idOffer }: Props): JSX.Element {
  const { t } = useTranslation(['button']);
  const history = useHistory();

  const handleClick = () => {
    history.push(
      `/${authService.hasRole(Role.editor) ? 'redaktor' : 'klient'}/chat/${idOrder}/${idOffer}`,
    );
  };

  return (
    <ButtonLink as="button" to="" icon="chat" onClick={handleClick}>
      {t('button:openChat')}
    </ButtonLink>
  );
}

export default ChatTrigger;
