import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Icon } from 'components';
import Loading from './Loading';

export type Props = {
  children?: React.ReactNode;
  as: 'button' | 'submit' | 'link' | 'link-out' | 'route';
  to: string;
  icon?: string;
  secondary?: boolean;
  full?: boolean;
  transparent?: boolean;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  [x: string]: any;
};

const defaultProps = {
  children: '',
  icon: '',
  secondary: false,
  full: false,
  transparent: false,
  loading: false,
  disabled: false,
  className: '',
};

const defaultStyle = classNames([
  'inline-flex items-center justify-center min-h-40 xl:min-h-48',
  'border-2 hover:border-accent-dk hover:bg-accent-dk',
  'uppercase font-semibold text-15 xl:text-16',
]);

function Button({
  children,
  as,
  to,
  icon,
  secondary,
  full,
  transparent,
  loading,
  disabled,
  className: classNameExtend,
  ...rest
}: Props): JSX.Element {
  const isDisabled = disabled || loading;

  const className = classNames(defaultStyle, classNameExtend, {
    'px-24 py-8': children,
    'border-accent bg-accent': !transparent && !secondary,
    'border-black bg-transparent': !transparent && secondary,
    'border-transparent bg-transparent': transparent,
    'px-8 w-40 xl:w-48 flex-shrink-0': icon && !children,
    'opacity-20 pointer-events-none': isDisabled,
    'w-full': full,
  });

  const renderIcon = (): JSX.Element | null => {
    if (!icon) return null;

    return (
      <Icon
        icon={icon}
        className={classNames({
          'mr-8': !!children,
        })}
        beforeInjection={(svg) => {
          svg.classList.add('pointer-events-none', 'w-16', 'h-16', 'fill-black');
        }}
      />
    );
  };

  switch (as) {
    case 'link':
      return (
        <Loading loading={!!loading} full={full}>
          <a href={to} className={className} tabIndex={isDisabled ? -1 : 0} {...rest}>
            {renderIcon()}
            {children}
          </a>
        </Loading>
      );
    case 'link-out':
      return (
        <Loading loading={!!loading} full={full}>
          <a
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            className={className}
            tabIndex={isDisabled ? -1 : 0}
            {...rest}
          >
            {renderIcon()}
            {children}
          </a>
        </Loading>
      );
    case 'route':
      return (
        <Loading loading={!!loading} full={full}>
          <Link to={to} className={className} tabIndex={isDisabled ? -1 : 0} {...rest}>
            {renderIcon()}
            {children}
          </Link>
        </Loading>
      );
    default:
      return (
        /* eslint-disable react/button-has-type */
        <Loading loading={!!loading} full={full}>
          <button type={as} className={className} disabled={isDisabled} {...rest}>
            {renderIcon()}
            {children}
          </button>
        </Loading>
        /* eslint-enable react/button-has-type */
      );
  }
}

Button.defaultProps = defaultProps;

export default Button;
