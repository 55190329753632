import React from 'react';
import { PageContent, HeroText } from 'layouts';
import { RegisterEditor } from 'forms';

const RegisterEditorView = (): JSX.Element => {
  return (
    <>
      <HeroText
        title="Zostań redaktorem"
        description="Jeśli jesteś osobą posiadającą co najmniej tytuł magistra, masz doświadczenie
        w&nbsp;redagowaniu tekstów naukowych i&nbsp;jesteś osobą rzetelną, terminową
        i&nbsp;skrupulatną to możesz dołączyć do naszego grona."
      />
      <PageContent>
        <div className="container">
          <RegisterEditor />
        </div>
      </PageContent>
    </>
  );
};

export default RegisterEditorView;
