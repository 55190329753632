import React, { useEffect } from 'react';
import { Alert, PageTitle, PageSubtitle } from 'components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useList, useOrders } from 'hooks';
import { AlertStatus, OrderList } from 'helpers/enums';
import { EditorThumb, EditorThumbSlider, Offer, Order } from 'layouts';
import Loading from 'features/loading/Loading';
import { Editor as EditorType } from 'helpers/types';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { publicService } from 'services';

const Dashboard = (): JSX.Element => {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const [list] = useOrders(OrderList.offers, 'CLIENT_ORDERS_OFFERS', 3);

  const editors = useList<EditorType>(3);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_EDITORS' }));

        const params = {
          records: editors.perPage,
          page: editors.page,
          sortField: 'a.rankingPos',
          sortDirection: 'ASC',
          filters: {
            'a.rankingPos': {
              gte: 1,
            },
          },
        };

        const response = await publicService.fetchEditors(params);

        editors.handleFetch(response);
      } catch (error) {
        console.log('FETCH_EDITORS');
      } finally {
        dispatch(removeLoading('FETCH_EDITORS'));
      }
    })();
  }, [editors.shouldUpdate]);

  const renderEditors = () => (
    <>
      <div className="mb-32 xxl:hidden">
        <EditorThumbSlider users={editors.items} />
      </div>
      <div className="mb-40 hidden xxl:block">
        <div className="row -mb-16 lg:-mb-24">
          {editors.items.map((item) => (
            <div className="col md:w-1/2 xl:w-1/3 mb-16 lg:mb-24" key={item.id}>
              <EditorThumb user={item} publicLink />
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const renderOrders = () =>
    list.items.map((item) => (
      <Order key={item.id} {...item}>
        {item.offers.length ? (
          <div className="row">
            {item.offers.map(({ id, price, editor }) => (
              <div className="col w-1/2 md:w-1/3 lg:w-1/4 xxxl:w-1/6" key={id}>
                <Offer
                  id={id}
                  idOrder={item.id}
                  price={price}
                  editor={editor!}
                  handleSuccess={() => list.setPage(1)}
                />
              </div>
            ))}
          </div>
        ) : null}
      </Order>
    ));

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <PageSubtitle>Top redaktorzy</PageSubtitle>
      <Loading items={['CLIENT_ORDERS_OFFERS']}>
        {editors.items.length ? (
          renderEditors()
        ) : (
          <Alert status={AlertStatus.info} message={t('alert:noResults')} />
        )}
      </Loading>
      <PageSubtitle>Ostatnie zlecenia</PageSubtitle>
      <Loading items={['CLIENT_ORDERS_OFFERS']}>
        {list.items.length ? (
          renderOrders()
        ) : (
          <Alert status={AlertStatus.info} message={t('alert:noResults')} />
        )}
      </Loading>
    </>
  );
};

export default Dashboard;
