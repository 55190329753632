import { useCallback, useState } from 'react';

const useList = <T>(perPage = 10) => {
  const [shouldUpdate, setShouldUpdate] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const [items, setItems] = useState<T[]>([]);

  const handleFetch = useCallback((v: { items: T[]; total: number }) => {
    setItems(v.items);
    setTotal(Math.ceil(v.total / perPage));
  }, []);

  const update = useCallback(() => {
    setShouldUpdate((p) => p + 1);
  }, []);

  const setPageWithUpdate = useCallback((v: number) => {
    setPage(v);
    update();
  }, []);

  return {
    page,
    setPage: setPageWithUpdate,
    perPage,
    total,
    items,
    handleFetch,
    shouldUpdate,
    update,
  };
};

export default useList;
