import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Input, Select, File, Datepicker } from 'components';
import { useAppDispatch, useAppSelector, useFieldsErrors } from 'hooks';
import { orderService } from 'services';
import { isFile } from 'helpers/files';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { fetchGlobals, selectorGlobals } from 'features/globals/globalsSlice';
import Loading from 'features/loading/Loading';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';
import { catchApiError } from 'helpers/error';

type Inputs = {
  subject: string;
  pagesCount: number;
  term: string;
  additionalInfo: string;
  scopeOfOrderId: string;
  typeOfStudyId: string;
  fieldId: string;
  attachmentsToAdd: FileList | null;
};

const schema = yup.object().shape({
  subject: yup.string().required('To pole jest wymagane'),
  pagesCount: yup.mixed().required('To pole jest wymagane'),
  term: yup.string().required('To pole jest wymagane'),
  scopeOfOrderId: yup.mixed().required('To pole jest wymagane'),
  typeOfStudyId: yup.mixed().required('To pole jest wymagane'),
  fieldId: yup.mixed().required('To pole jest wymagane'),
});

function ClientAddOrder(): JSX.Element {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const globals = useAppSelector(selectorGlobals);

  useEffect(() => {
    dispatch(fetchGlobals(['scopeOfOrders', 'typesOfStudy', 'activityFields']));
  }, []);

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  useFieldsErrors('form-forgot-password', errors);

  const onSubmit = async ({
    subject,
    pagesCount,
    term,
    scopeOfOrderId,
    typeOfStudyId,
    fieldId,
    additionalInfo,
    attachmentsToAdd,
  }: Inputs) => {
    try {
      const formData = new FormData();

      formData.append('subject', subject);
      formData.append('pagesCount', pagesCount.toString());
      formData.append('term', term);
      formData.append('scopeOfOrderId', scopeOfOrderId);
      formData.append('typeOfStudyId', typeOfStudyId);
      formData.append('fieldId', fieldId);
      formData.append('additionalInfo', additionalInfo);

      if (isFile(attachmentsToAdd)) {
        Array.from(attachmentsToAdd!).forEach((file) => {
          formData.append('attachmentsToAdd[]', file);
        });
      }

      await orderService.addOrder(formData);

      dispatch(
        addNotification({
          id: 'auth.clientAddOrder',
          status: AlertStatus.success,
          message: t('alert:addOrder'),
        }),
      );

      reset();
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'auth.clientAddOrder',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    }
  };

  return (
    <Loading items={['FETCH_GLOBALS']}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-b border-black-5 mb-12 xl:mb-16">
          <div className="row">
            <div className="col xxl:w-4/6">
              <Input control={control} name="subject" placeholder="Wpisz" label="Temat pracy *" />
            </div>
            <div className="col xl:w-1/2 xxl:w-1/6">
              <Input
                type="number"
                control={control}
                name="pagesCount"
                placeholder="Wpisz"
                label="Ilość stron *"
              />
            </div>
            <div className="col xl:w-1/2 xxl:w-1/6">
              <Datepicker
                control={control}
                name="term"
                label="Termin realizacji *"
                minDate={new Date()}
              />
            </div>
            <div className="col xl:w-2/6">
              <Select
                name="scopeOfOrderId"
                control={control}
                items={globals.scopeOfOrders}
                label="Zakres zlecenia *"
              />
            </div>
            <div className="col xl:w-2/6">
              <Select
                name="typeOfStudyId"
                control={control}
                items={globals.typesOfStudy}
                label="Rodzaj opracowania *"
              />
            </div>
            <div className="col xl:w-2/6">
              <Select
                name="fieldId"
                control={control}
                items={globals.activityFields}
                label="Dziedzina *"
              />
            </div>
          </div>
        </div>

        <Input
          type="textarea"
          control={control}
          name="additionalInfo"
          placeholder="Wpisz pozostałe wytyczne i wymagania"
          label="Dodatkowe informacje"
        />

        <File
          {...register('attachmentsToAdd')}
          id="attachmentsToAdd"
          label="Załączniki"
          value={watch('attachmentsToAdd')}
          resetFn={() => setValue('attachmentsToAdd', null)}
          error={errors?.attachmentsToAdd}
          multi
        />

        <div className="mt-16 xl:mt-24">
          <Button as="submit" to="" loading={isSubmitting}>
            Wyślij zlecenie
          </Button>
        </div>
      </form>
    </Loading>
  );
}

export default ClientAddOrder;
