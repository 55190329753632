import React from 'react';
import classNames from 'classnames';

export type Props = {
  children: React.ReactNode;
  className?: string;
};

const defaultProps = {
  className: '',
};

function Label({ children, className: classNameExtend }: Props): JSX.Element {
  return (
    <span
      className={classNames(
        'inline-block text-12 xl:text-13 leading-19 xl:leading-22 font-semibold text-primary-dk uppercase',
        classNameExtend,
        {
          'mb-4': !classNameExtend,
        },
      )}
    >
      {children}
    </span>
  );
}

Label.defaultProps = defaultProps;

export default Label;
