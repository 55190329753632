import React, { useState, useCallback, useEffect } from 'react';
import { User } from 'layouts';
import { authService } from 'services';
import { Role } from 'helpers/enums';
import type { User as UserType } from 'helpers/types';

function LoggedUser(): JSX.Element | null {
  const [user, setUser] = useState<UserType>();

  const handleUserChange = useCallback(() => {
    setUser(authService.getUser() as UserType);
  }, []);

  useEffect(() => {
    handleUserChange();
    document.addEventListener('userChange', handleUserChange);

    return () => {
      document.removeEventListener('userChange', handleUserChange);
    };
  }, []);

  if (!user) return null;

  const rating = user.role === Role.editor ? user.editorProfile?.avgRate : null;

  return <User {...user} avgRate={rating} />;
}

export default LoggedUser;
