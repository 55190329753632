import React from 'react';
import { ChatTrigger } from 'layouts';
import { OfferStatus } from 'helpers/enums';
import { Offer } from 'helpers/types';
import { Price } from 'components';

type Props = {
  idOrder: number;
  offers: Offer[];
  titlePrice: string;
  status?: OfferStatus;
  children?: React.ReactNode;
};

const defaultProps = {
  status: OfferStatus.accepted,
  children: '',
};

function OrderSummary({
  idOrder,
  offers,
  titlePrice,
  status,
  children,
}: Props): JSX.Element | null {
  const offer = offers.find((o) => o.status === status) as Offer;

  if (!offer) return null;

  return (
    <div className="row items-center -mb-12">
      <div className="col w-auto mb-12">
        <Price title={titlePrice} price={offer.price} />
      </div>
      <div className="col w-auto mb-12 xl:order-2">
        <ChatTrigger idOrder={idOrder} idOffer={offer.id} />
      </div>
      {children}
    </div>
  );
}

OrderSummary.defaultProps = defaultProps;

export default OrderSummary;
