import { publicAttachmentPath } from 'helpers/attachments';
import type { Props as ButtonType } from 'components/Button/Button';
import type { Props as HeroType } from 'layouts/Hero/Hero';
import type { Props as CtaType } from 'layouts/Cta/Cta';
import { Image, ImageBreakpoint } from 'helpers/types';
import type { HomePage, AboutPage, ArticlePage, EditorsPage, TextPage, ContactPage } from './types';

export const mapButton = (url: string, title: string, target: string): ButtonType | null => {
  if (!url || !title) return null;

  let as: 'route' | 'link' | 'link-out' = 'route';

  if (url.startsWith('http://') || url.startsWith('https://')) {
    as = target === '_blank' ? 'link-out' : 'link';
  }

  return {
    as,
    to: url,
    children: title,
  };
};

export const mapImage = (url: string, alt: string): Image | null => {
  if (!url) return null;

  return {
    url: publicAttachmentPath(url),
    alt,
  };
};

export const mapImageBreakpoint = (
  url: string,
  urlLarge: string,
  alt: string,
): ImageBreakpoint | null => {
  if (!url && !urlLarge) return null;

  return {
    url: url ? publicAttachmentPath(url) : publicAttachmentPath(urlLarge),
    urlLarge: urlLarge ? publicAttachmentPath(urlLarge) : publicAttachmentPath(url),
    alt,
  };
};

export const mapHero = (
  title: string,
  subtitle: string,
  description: string,
  buttonUrl: string,
  buttonTitle: string,
  buttonTarget: string,
  buttonExtraUrl: string,
  buttonExtraTitle: string,
  buttonExtraTarget: string,
  headerImageUrl: string,
  headerImageUrlLarge: string,
  headerImageAlt: string,
): HeroType | null => {
  if (!title && !subtitle && !description) return null;

  return {
    title,
    subtitle,
    description,
    button: mapButton(buttonUrl, buttonTitle, buttonTarget),
    buttonExtra: mapButton(buttonExtraUrl, buttonExtraTitle, buttonExtraTarget),
    image: mapImageBreakpoint(headerImageUrl, headerImageUrlLarge, headerImageAlt),
  };
};

export const mapCta = (
  label: string,
  title: string,
  subtitle: string,
  description: string,
  buttonUrl: string,
  buttonTitle: string,
  buttonTarget: string,
): CtaType | null => {
  if (!title && !subtitle && !description) return null;

  return {
    label,
    title,
    subtitle,
    description,
    button: mapButton(buttonUrl, buttonTitle, buttonTarget),
  };
};

export const mapSummary = (
  editors: number,
  finishedOrders: number,
  availableOrders: number,
  avgRate: number,
): {
  editors: number | null;
  finishedOrders: number | null;
  availableOrders: number | null;
  avgRate: number | null;
} | null => {
  if (!editors && !finishedOrders && !availableOrders && !avgRate) return null;

  return {
    editors: editors || null,
    finishedOrders: finishedOrders || null,
    availableOrders: availableOrders || null,
    avgRate: avgRate || null,
  };
};

export const parseHome = (d: any): HomePage => {
  return {
    hero: mapHero(
      d.headerTitle,
      d.headerSubtitle,
      d.headerDescription,
      d.headerButtonUrl,
      d.headerButtonTitle,
      d.headerButtonTarget,
      d.headerButtonExtraUrl,
      d.headerButtonExtraTitle,
      d.headerButtonExtraTarget,
      d.headerImageUrl,
      d.headerImageUrlLarge,
      d.headerImageAlt,
    ),
    stepsCta: {
      cta: mapCta(
        d.stepsCtaLabel,
        d.stepsCtaTitle,
        d.stepsCtaSubtitle,
        d.stepsCtaDescription,
        d.stepsCtaButtonUrl,
        d.stepsCtaButtonTitle,
        d.stepsCtaButtonTarget,
      ),
      image: mapImage(d.stepsCtaImageUrl, d.stepsCtaImageAlt),
      reverse: d.stepsCtaReverse,
    },
    stepsItems: d.stepsItems
      ? d.stepsItems.map((n: any) => ({
          title: n.title,
          description: n.description,
          icon: n.icon,
          color: n.color,
        }))
      : [],
    cta1: {
      cta: mapCta(
        d.cta1Label,
        d.cta1Title,
        d.cta1Subtitle,
        d.cta1Description,
        d.cta1ButtonUrl,
        d.cta1ButtonTitle,
        d.cta1ButtonTarget,
      ),
      image: mapImageBreakpoint(d.cta1ImageUrl, d.cta1ImageUrlLarge, d.cta1ImageAlt),
      stretch: d.cta1Stretch,
    },
    cta2: {
      cta: mapCta(
        d.cta2Label,
        d.cta2Title,
        d.cta2Subtitle,
        d.cta2Description,
        d.cta2ButtonUrl,
        d.cta2ButtonTitle,
        d.cta2ButtonTarget,
      ),
      image: mapImage(d.cta2ImageUrl, d.cta2ImageAlt),
      reverse: d.cta2Reverse,
    },
    cta3: {
      cta: mapCta(
        d.cta3Label,
        d.cta3Title,
        d.cta3Subtitle,
        d.cta3Description,
        d.cta3ButtonUrl,
        d.cta3ButtonTitle,
        d.cta3ButtonTarget,
      ),
      image: mapImageBreakpoint(d.cta3ImageUrl, d.cta3ImageUrlLarge, d.cta3ImageAlt),
      stretch: d.cta3Stretch,
    },
    summary: mapSummary(d.editorsCount, d.completedOrders, d.availableOrders, d.avgRate),
    editorsCta: mapCta(
      d.editorsCtaLabel,
      d.editorsCtaTitle,
      d.editorsCtaSubtitle,
      d.editorsCtaDescription,
      d.editorsCtaButtonUrl,
      d.editorsCtaButtonTitle,
      d.editorsCtaButtonTarget,
    ),
    doubleCta: {
      cta1: mapCta(
        d.doubleCtaCta1Label,
        d.doubleCtaCta1Title,
        d.doubleCtaCta1Subtitle,
        d.doubleCtaCta1Description,
        d.doubleCtaCta1ButtonUrl,
        d.doubleCtaCta1ButtonTitle,
        d.doubleCtaCta1ButtonTarget,
      ),
      cta2: mapCta(
        d.doubleCtaCta2Label,
        d.doubleCtaCta2Title,
        d.doubleCtaCta2Subtitle,
        d.doubleCtaCta2Description,
        d.doubleCtaCta2ButtonUrl,
        d.doubleCtaCta2ButtonTitle,
        d.doubleCtaCta2ButtonTarget,
      ),
    },
  };
};

export const parseAbout = (d: any): AboutPage => {
  return {
    hero: mapHero(
      d.headerTitle,
      d.headerSubtitle,
      d.headerDescription,
      d.headerButtonUrl,
      d.headerButtonTitle,
      d.headerButtonTarget,
      d.headerButtonExtraUrl,
      d.headerButtonExtraTitle,
      d.headerButtonExtraTarget,
      d.headerImageUrl,
      d.headerImageUrlLarge,
      d.headerImageAlt,
    ),
    content: d.content,
    cta: {
      cta: mapCta(
        d.ctaLabel,
        d.ctaTitle,
        d.ctaSubtitle,
        d.ctaDescription,
        d.ctaButtonUrl,
        d.ctaButtonTitle,
        d.ctaButtonTarget,
      ),
      image: mapImageBreakpoint(d.ctaImageUrl, d.ctaImageUrlLarge, d.ctaImageAlt),
      stretch: d.ctaStretch,
    },
  };
};

export const parseArticle = (d: any): ArticlePage => {
  return {
    id: d.id,
    title: d.title,
    content: d.content,
    cta: {
      cta: mapCta(
        d.ctaLabel,
        d.ctaTitle,
        d.ctaSubtitle,
        d.ctaDescription,
        d.ctaButtonUrl,
        d.ctaButtonTitle,
        d.ctaButtonTarget,
      ),
      image: mapImageBreakpoint(d.ctaImageUrl, d.ctaImageUrlLarge, d.ctaImageAlt),
      stretch: d.ctaStretch,
    },
  };
};

export const parseEditors = (d: any): EditorsPage => {
  return {
    hero: d.headerTitle
      ? {
          title: d.headerTitle,
          description: d.headerDescription || '',
        }
      : null,
    content: d.content,
  };
};

export const parseContact = (d: any): ContactPage => {
  return {
    address: d.address,
    phone: d.phone,
    email: d.email,
    hours: d.hours,
    nip: d.nip,
    regon: d.regon,
  };
};

export const parseTextPage = (d: any): TextPage => {
  return {
    hero: d.headerTitle
      ? {
          title: d.headerTitle,
          description: d.headerDescription || '',
        }
      : null,
    content: d.content,
  };
};
