import React from 'react';
import { useTranslation } from 'react-i18next';

const Title = ({ children }: { children: React.ReactNode }) => (
  <p className="font-headings font-bold text-black text-18 xl:text-20 mb-8">{children}</p>
);

const Description = ({ children }: { children: React.ReactNode }) => (
  <p className="text-15 xl:text-16 leading-25 xl:leading-28 text-black-75">{children}</p>
);

const About = ({ extraProps }: any): JSX.Element => {
  const { t } = useTranslation(['text']);

  return (
    <>
      <div className="mb-24">
        <Title>{t('text:description')}</Title>
        <Description>{extraProps.editor.aboutMe}</Description>
      </div>
      <div className="row -mb-24">
        <div className="col md:w-1/3 mb-24">
          <Title>{t('text:education')}</Title>
          <Description>{extraProps.editor.education?.name}</Description>
        </div>
        <div className="col md:w-1/3 mb-24">
          <Title>{t('text:nativeLanguage')}</Title>
          <Description>{extraProps.editor.nativeLanguage?.name}</Description>
        </div>
        <div className="col md:w-1/3 mb-24">
          <Title>{t('text:gender')}</Title>
          <Description>{t('text:gender', { context: extraProps.editor.gender })}</Description>
        </div>
      </div>
    </>
  );
};

export default About;
