import React from 'react';
import { LayoutImage } from 'layouts';
import { images } from 'assets';
import { ButtonLink } from 'components';
import { Login as LoginForm } from 'forms';

function Login(): JSX.Element {
  return (
    <LayoutImage image={images.authBg}>
      <h1 className="font-headings font-bold text-black text-32 xl:text-48 mb-16">Zaloguj się</h1>
      <p className="text-18 xl:text-20 font-semibold text-black-75 leading-29 xl:leading-32">
        Nie masz jeszcze konta?{' '}
        <ButtonLink as="route" to="/#zarejestruj-sie" className="text-inherit">
          Zarejestruj się
        </ButtonLink>
      </p>
      <p className="text-18 xl:text-20 font-headings font-bold text-black leading-22 xl:leading-28 mt-12 xl:mt-16">
        Potrafisz pisać i&nbsp;chcesz zarabiać na zleceniach?{' '}
        <ButtonLink as="route" to="/zostan-redaktorem" className="text-inherit">
          Zostań redaktorem!
        </ButtonLink>
      </p>
      <div className="mt-24 xl:mt-48">
        <LoginForm />
      </div>
      <div className="mt-32 xl:mt-40">
        <ButtonLink as="route" to="/auth/forgot-password">
          Zapomniałeś hasła?
        </ButtonLink>
      </div>
    </LayoutImage>
  );
}

export default Login;
