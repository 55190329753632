import React, { ForwardedRef, forwardRef, useCallback } from 'react';
import classNames from 'classnames';
import './File.scss';
import { Button, Icon, InputError, InputLabel } from 'components';
import { isFile, isExistFile } from 'helpers/files';

type Props = {
  value: any;
  error?: any;
  disabled?: boolean;
  label?: string;
  id?: string;
  [key: string]: any;
  resetFn: any;
  multi?: boolean;
};

const defaultProps = {
  error: '',
  disabled: false,
  label: '',
  id: '',
  multi: false,
};

const defaultStyle = classNames([
  'w-full min-h-40 xl:min-h-48 block text-15 xl:text-16 pt-12 px-16 pr-96 xl:pt-16 xl:pr-96',
  'border',
  'rounded-none shadow-none',
  'placeholder-black-75 text-black-75',
  'cursor-pointer overflow-ellipsis overflow-hidden whitespace-nowrap',
]);

const AttachmentIcon = () => (
  <Icon
    icon="attachment"
    className="w-40 xl:w-48 h-40 xl:h-48 flex items-center justify-center pointer-events-none"
    beforeInjection={(svg) => {
      svg.classList.add('h-16', 'fill-primary');
    }}
  />
);

const File = forwardRef(
  (
    { value, error, disabled, label, id, resetFn, multi, ...rest }: Props,
    ref: ForwardedRef<any>,
  ): JSX.Element => {
    const className = classNames(defaultStyle, {
      'bg-error-75 border-error-75': error,
      'opacity-50 cursor-not-allowed': disabled,
      'bg-black-5 border-black-5': !error && !isFile(value),
      'bg-black-5 border-primary': !error && isFile(value),
    });

    const handleReset = useCallback(async () => {
      if (isExistFile(value)) {
        resetFn(Array.isArray(value) ? value : [value]);
      } else {
        resetFn(null);
      }
    }, [value, resetFn]);

    const renderFile = () => (
      <div className="file">
        <input multiple={multi} ref={ref} type="file" disabled={disabled} id={id} {...rest} />
        <label className={className} htmlFor={id}>
          {isFile(value) && [...value].map((file: File) => file.name).join(', ')}
          {!isFile(value) && `${multi ? 'Dołącz pliki...' : 'Dołącz plik...'}`}
        </label>
        <div className="file__controls pointer-events-none">
          <AttachmentIcon />
          {isFile(value) && (
            <div className="pointer-events-auto">
              <Button as="button" to="" icon="close" onClick={handleReset} transparent />
            </div>
          )}
        </div>
      </div>
    );

    const renderExistFile = () => {
      const name = Array.isArray(value) ? value.map((el) => el.name).join(', ') : value.name;
      return (
        <div className="file">
          <input ref={ref} type="text" disabled={disabled} id={id} {...rest} />
          <label className={classNames(className, 'pointer-events-none')} htmlFor={id}>
            {name}
          </label>
          <div className="file__controls pointer-events-none">
            <AttachmentIcon />
            <div className="pointer-events-auto">
              <Button as="button" to="" icon="close" onClick={handleReset} transparent />
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="mb-12 xl:mb-16">
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        {isExistFile(value) ? renderExistFile() : renderFile()}
        <InputError message={error?.message} />
      </div>
    );
  },
);

File.defaultProps = defaultProps;

export default File;
