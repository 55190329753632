import React, { useEffect } from 'react';
import { Hero, CtaImageFull, CtaImage, Cta, Steps, EditorThumbSlider } from 'layouts';
import { RegisterClient } from 'forms';
import { useAppDispatch, useList, usePage } from 'hooks';
import Loading from 'features/loading/Loading';
import type { HomePage } from 'services/page/types';
import { Icon } from 'components';
import type { Editor as EditorType } from 'helpers/types';
import { Page } from 'helpers/enums';
import { addOne as addLoading, removeOne as removeLoading } from 'features/loading/loadingSlice';
import { publicService } from 'services';
import { images } from 'assets';

const Home = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    hero,
    stepsCta,
    stepsItems,
    cta1,
    cta2,
    cta3,
    summary,
    editorsCta,
    doubleCta,
  }: HomePage = usePage<HomePage>(Page.home);

  const editors = useList<EditorType>(3);

  useEffect(() => {
    (async () => {
      try {
        dispatch(addLoading({ id: 'FETCH_EDITORS' }));

        const params = {
          records: editors.perPage,
          page: editors.page,
        };

        const response = await publicService.fetchEditors(params);

        editors.handleFetch(response);
      } catch (error) {
        console.log('FETCH_EDITORS');
      } finally {
        dispatch(removeLoading('FETCH_EDITORS'));
      }
    })();
  }, [editors.shouldUpdate]);

  return (
    <Loading items={['FETCH_PAGE']}>
      {hero && <Hero {...hero} />}
      <section className="my-64 xl:mb-96 xl:-mt-64">
        <div className="container xl:p-64 xl:shadow bg-white" id="zarejestruj-sie">
          <div className="text-center mb-16 xl:mb-24">
            <p className="text-12 xl:text-13 font-semibold uppercase text-primary-dk mb-4 xl:mb-8">
              Praca na piątkę z plusem!
            </p>
            <h2 className="text-28 leading-34 xl:text-40 xl:leading-50 font-bold font-headings text-black">
              Zleć wykonanie pracy jednemu z Naszych redaktorów
            </h2>
          </div>
          <RegisterClient />
        </div>
      </section>
      {stepsCta && <CtaImage {...stepsCta} />}
      {stepsItems && <Steps steps={stepsItems} />}
      {cta1 && <CtaImageFull {...cta1} />}
      {cta2 && <CtaImage {...cta2} />}
      {cta3 && <CtaImageFull {...cta3} />}
      {summary && (
        <section className="bg-primary-lt py-32 xl:py-64 text-center">
          <div className="container">
            <div className="row -mb-24">
              {!!summary.editors && (
                <div className="col w-1/2 md:w-1/4 mb-24">
                  <p className="font-headings font-bold text-32 xl:text-48 leading-38 xl:leading-60">
                    {summary.editors}
                  </p>
                  <p className="text-12 xl:text-13 leading-19 xl:leading-22 font-semibold text-primary-dk uppercase mt-4">
                    Redaktorów
                  </p>
                </div>
              )}
              {!!summary.finishedOrders && (
                <div className="col w-1/2 md:w-1/4 mb-24">
                  <p className="font-headings font-bold text-32 xl:text-48 leading-38 xl:leading-60">
                    {summary.finishedOrders}+
                  </p>
                  <p className="text-12 xl:text-13 leading-19 xl:leading-22 font-semibold text-primary-dk uppercase mt-4">
                    Zrealizowanych zleceń
                  </p>
                </div>
              )}
              {!!summary.availableOrders && (
                <div className="col w-1/2 md:w-1/4 mb-24">
                  <p className="font-headings font-bold text-32 xl:text-48 leading-38 xl:leading-60">
                    {summary.availableOrders}
                  </p>
                  <p className="text-12 xl:text-13 leading-19 xl:leading-22 font-semibold text-primary-dk uppercase mt-4">
                    Dostępnych zleceń
                  </p>
                </div>
              )}
              {!!summary.avgRate && (
                <div className="col w-1/2 md:w-1/4 mb-24">
                  <div className="flex justify-center items-center">
                    <Icon
                      icon="star"
                      className="mr-16"
                      beforeInjection={(svg) => {
                        svg.classList.add('w-24', 'xl:w-40', 'h-24', 'xl:h-40', 'fill-accent-dk');
                      }}
                    />
                    <p className="font-headings font-bold text-32 xl:text-48 leading-38 xl:leading-60">
                      {summary.avgRate}
                    </p>
                  </div>
                  <p className="text-12 xl:text-13 leading-19 xl:leading-22 font-semibold text-primary-dk uppercase mt-4">
                    Średnia ocena
                  </p>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
      {(editorsCta || !!editors.items.length) && (
        <section className="my-64 xl:my-96">
          <div className="container">
            <div className="row">
              <div className="col lg:w-1/2">{editorsCta && <Cta {...editorsCta} />}</div>
              {!!editors.items.length && (
                <div className="col lg:w-1/2 mt-32 lg:mt-0">
                  <EditorThumbSlider users={editors.items} />
                </div>
              )}
            </div>
          </div>
        </section>
      )}
      {doubleCta && (doubleCta.cta1 || doubleCta.cta2) && (
        <section className="bg-black-5 pt-40 pb-64 xl:pt-64 xl:pb-80 text-center">
          <div className="container">
            <div className="row">
              {doubleCta.cta1 && (
                <div
                  className="col lg:w-1/2 pb-32 mb-32 lg:pb-0 lg:mb-0 border-b lg:border-b-0 lg:border-r border-white bg-no-repeat bg-contain bg-center"
                  style={{ backgroundImage: `url(${images.doubleBg})` }}
                >
                  <Cta {...doubleCta.cta1} small />
                </div>
              )}
              {doubleCta.cta2 && (
                <div
                  className="col lg:w-1/2 bg-no-repeat bg-contain bg-center"
                  style={{ backgroundImage: `url(${images.doubleBg2})` }}
                >
                  <Cta {...doubleCta.cta2} small />
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </Loading>
  );
};

export default Home;
