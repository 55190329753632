import React from 'react';
import { PageContent, HeroText } from 'layouts';
import { Contact as FormContact } from 'forms';
import { ContactPage } from 'services/page/types';
import { usePage } from 'hooks';
import { Page } from 'helpers/enums';
import Loading from 'features/loading/Loading';
import Item from './Item';

const Contact = (): JSX.Element => {
  const { address, phone, email, hours, nip, regon }: ContactPage = usePage<ContactPage>(
    Page.contact,
  );

  return (
    <Loading items={['FETCH_PAGE']}>
      <HeroText title="Kontakt" />
      <PageContent>
        <div className="container">
          <h2 className="text-28 xl:text-40 leading-34 xl:leading-50 font-bold font-headings text-black mb-16 xl:mb-24">
            Napisz do nas
          </h2>
          <div className="row">
            <div className="col md:w-1/2 mb-40 md:mb-0">
              <FormContact />
            </div>
            {(address || phone || email || hours || nip || regon) && (
              <div className="col md:w-1/2">
                <div className="shadow p-24 xl:ml-96">
                  <h2 className="text-18 xl:text-20 leading-22 xl:leading-28 font-bold font-headings text-black mb-16">
                    Kontakt z nami
                  </h2>
                  <ul>
                    {address && <Item icon="marker">{address}</Item>}
                    {phone && (
                      <Item icon="phone">
                        <a href="tel:+48730888730">{phone}</a>
                      </Item>
                    )}
                    {email && (
                      <Item icon="envelope">
                        <a href={`mailto:${email}`} className="text-primary font-semibold">
                          {email}
                        </a>
                      </Item>
                    )}
                    {hours && <Item icon="clock">{hours}</Item>}
                    {nip && (
                      <p className="text-15 xl:text-16 leading-25 xl:leading-28 text-black-75 mb-8">
                        <strong>NIP:</strong> {nip}
                      </p>
                    )}
                    {regon && (
                      <p className="text-15 xl:text-16 leading-25 xl:leading-28 text-black-75 mb-8">
                        <strong>Regon:</strong> {regon}
                      </p>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </PageContent>
    </Loading>
  );
};

export default Contact;
