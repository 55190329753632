import ky from 'ky';
import { authService } from 'services';
import i18n from '../i18n';

i18n.init({
  ns: ['alert'],
});

class ApiError extends Error {
  public messageCode: string | null;

  public field: string | null;

  public fieldMessage: string | null;

  public status: number;

  public pathname: string;

  public redirect: string | null;

  private response: Response | null;

  constructor({ response }: ky.HTTPError) {
    super(i18n.t('alert:error'));
    this.name = 'ApiError';
    this.messageCode = null;
    this.field = null;
    this.fieldMessage = null;
    this.redirect = null;
    this.status = response ? response.status : 500;
    this.pathname = response ? new URL(response.url).pathname : '';
    this.response = response || null;
  }

  public async parse() {
    if (this.response) {
      let error = await this.response.json();
      error = Object.hasOwnProperty.call(error, 'error') ? error.error : error;

      if (this.status === 404) {
        this.redirect = '/404';
      } else if (error.messageCode) {
        this.messageCode = error.messageCode;

        switch (this.messageCode) {
          case 'api_error_2':
            this.message = i18n.t('alert:userExists');
            break;
          case 'api_error_998':
            this.message = i18n.t('alert:fieldsApiError');
            this.field = error.data.field;
            this.fieldMessage = error.data.error;
            break;
          case 'api_error_901':
            this.message = i18n.t('alert:accountNotConfirmed');
            break;
          case 'api_error_14':
            this.message = i18n.t('alert:largeFile');
            break;
          default:
            this.message = error.message;
        }
      } else if (this.status === 401 && this.pathname === '/api/auth/login') {
        this.message = i18n.t('alert:invalidCredentials');
      } else if (this.status === 401 && this.pathname !== '/api/auth/login') {
        this.redirect = '/';
        this.message = i18n.t('alert:sessionExpired');
        authService.removeUser();
      }
    }

    return this;
  }

  public redirect404() {
    this.redirect = '/404';

    return this;
  }
}

export default ApiError;
