import React from 'react';
import { AlertStatus } from 'helpers/enums';
import type { Badge as BadgeType } from 'helpers/types';

const defaultProps = {
  status: AlertStatus.success,
};

function Badge({ title, status }: BadgeType): JSX.Element {
  let color: string;

  switch (status) {
    case AlertStatus.info:
      color = '#00f';
      break;
    case AlertStatus.error:
      color = '#f00';
      break;
    case AlertStatus.warning:
      color = '#ffb70f';
      break;
    default:
      color = '#20d870';
  }

  return (
    <span
      className="min-h-24 rounded-switch font-semibold text-white text-10 px-8 py-4 uppercase flex items-center text-center"
      style={{ backgroundColor: `${color}` }}
    >
      {title}
    </span>
  );
}

Badge.defaultProps = defaultProps;

export default Badge;
