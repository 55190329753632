import type { Attachment } from 'helpers/types';
import { isDev } from 'helpers/development';

export const attachmentPath = (attachment: Attachment): string => {
  if (isDev()) {
    return `${process.env.REACT_APP_API_URL}/api/attachment/${attachment.file}`;
  }

  return `/api/attachment/${attachment.file}`;
};

export const publicAttachmentPath = (attachment: string): string => {
  if (isDev()) {
    return `${process.env.REACT_APP_API_URL}/api/attachment/asset/${attachment}`;
  }

  return `/api/attachment/asset/${attachment}`;
};

export const parseAttachment = (attachment: Attachment): Attachment => {
  return {
    ...attachment,
    src: attachmentPath(attachment),
  };
};

export const parseAttachments = (attachments: Attachment[]): Attachment[] => {
  return attachments.map((el) => parseAttachment(el));
};
