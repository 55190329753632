import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from 'store';

export type Modal = {
  id: string;
  data?: any;
};

const adapter = createEntityAdapter<Modal>();

const slice = createSlice({
  name: 'modals',
  initialState: adapter.getInitialState(),
  reducers: {
    addOne: adapter.addOne,
    removeOne: adapter.removeOne,
  },
});

export const { addOne, removeOne } = slice.actions;

export const selectors = adapter.getSelectors<RootState>((state) => state.modals);

export default slice.reducer;
