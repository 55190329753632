import React from 'react';

type Props = {
  children: React.ReactNode;
};

function PageSubtitle({ children }: Props): JSX.Element {
  return (
    <div className="my-16 xl:my-24">
      <h2 className="font-headings font-bold text-black text-18 xl:text-20">{children}</h2>
    </div>
  );
}

export default PageSubtitle;
