import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Input, File } from 'components';
import { useAppDispatch, useFieldsErrors } from 'hooks';
import { attachmentService, authService, clientService } from 'services';
import { addOne as addNotification } from 'features/notifications/notificationsSlice';
import { isFile } from 'helpers/files';
import { catchApiError } from 'helpers/error';
import type { User, Attachment } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { AlertStatus } from 'helpers/enums';

type Inputs = User & {
  password: string;
  passwordConfirm: string;
  profilePhoto: FileList | null | string;
};

const schema = yup.object().shape({
  email: yup.string().email('Nieprawidłowy adres e-mail').required('To pole jest wymagane'),
  name: yup.string().required('To pole jest wymagane'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Podane hasła nie są takie same'),
});

const Heading = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <h2 className="font-headings font-bold text-black text-18 xl:text-20 mb-16">{children}</h2>
);

function ClientEditAccount(): JSX.Element {
  const { t } = useTranslation(['alert']);
  const dispatch = useAppDispatch();
  const [filesToRemove, setFilesToRemove] = useState<Attachment[]>([]);

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: authService.getUser(),
  });

  useFieldsErrors('form-client-edit-account', errors);

  const onSubmit = async ({ email, password, name, profilePhoto }: Inputs) => {
    try {
      const formData = new FormData();

      formData.append('email', email);
      formData.append('name', name);

      if (password) {
        formData.append('password', password);
      }

      if (isFile(profilePhoto as FileList)) {
        formData.append('profilePhoto', profilePhoto![0]);
      }

      if (filesToRemove.length) {
        await Promise.all(filesToRemove.map((file) => attachmentService.remove(file.id)));
        setFilesToRemove([]);
      }

      const response = await clientService.editAccount(formData);

      authService.setUser(response);

      reset(authService.getUser());

      dispatch(
        addNotification({
          id: 'client.editAccount',
          status: AlertStatus.success,
          message: t('alert:userEdit'),
        }),
      );
    } catch (error) {
      catchApiError(
        error,
        () =>
          dispatch(
            addNotification({
              id: 'client.editAccount',
              status: AlertStatus.error,
              message: error.message,
            }),
          ),
        setError,
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-32 xl:mb-40">
        <Heading>Dane do logowania</Heading>
        <div className="row">
          <div className="col md:w-1/2 xl:w-1/4">
            <Input control={control} name="email" placeholder="Wpisz" label="Adres e-mail *" />
          </div>
          <div className="col md:w-1/2 xl:w-1/4">
            <Input control={control} name="name" placeholder="Wpisz" label="Wyświetlana nazwa *" />
          </div>
          <div className="col md:w-1/2 xl:w-1/4">
            <Input
              type="password"
              control={control}
              name="password"
              placeholder="Wpisz"
              label="Hasło"
            />
          </div>
          <div className="col md:w-1/2 xl:w-1/4">
            <Input
              type="password"
              control={control}
              name="passwordConfirm"
              placeholder="Wpisz"
              label="Powtórz hasło"
            />
          </div>
        </div>
      </div>
      <div className="mb-32 xl:mb-40">
        <Heading>Dodatkowe informacje</Heading>
        <File
          {...register('profilePhoto')}
          id="profilePhoto"
          label="Zdjęcie profilowe"
          value={watch('profilePhoto')}
          resetFn={(v: Attachment[] | null) => {
            if (v !== null) {
              setFilesToRemove((prev) => [...prev, ...v]);
            }

            setValue('profilePhoto', null);
          }}
          error={errors?.profilePhoto}
        />
      </div>

      <div className="mt-16">
        <Button as="submit" to="" loading={isSubmitting}>
          Zapisz zmiany
        </Button>
      </div>
    </form>
  );
}

export default ClientEditAccount;
