import Login from 'pages/auth/Login/Login';
import ForgotPassword from 'pages/auth/ForgotPassword/ForgotPassword';
import ForgotPasswordForm from 'pages/auth/ForgotPassword/ForgotPasswordForm';
import ForgotPasswordFormReset from 'pages/auth/ForgotPassword/ForgotPasswordFormReset';
import ForgotPasswordSuccess from 'pages/auth/ForgotPassword/ForgotPasswordSuccess';

export default [
  {
    key: 'authLogin',
    path: '/auth/login',
    exact: true,
    component: Login,
  },
  {
    key: 'authForgotPassword',
    path: '/auth/forgot-password',
    exact: false,
    component: ForgotPassword,
    routes: [
      {
        key: 'authForgotPasswordForm',
        path: '/',
        exact: true,
        component: ForgotPasswordForm,
      },
      {
        key: 'authForgotPasswordFormReset',
        path: '/reset',
        exact: true,
        component: ForgotPasswordFormReset,
      },
      {
        key: 'authForgotPasswordSuccess',
        path: '/success',
        exact: true,
        component: ForgotPasswordSuccess,
      },
    ],
  },
];
