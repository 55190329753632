import React, { useEffect, useState } from 'react';
import { Button } from 'components';
import { isIOS } from 'react-device-detect';

function usePwa() {
  const [pwaPrompt, setPWAPrompt] = useState<any>(null);

  useEffect(() => {
    const installPrompt = (e: any) => {
      e.preventDefault();

      setPWAPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', installPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', installPrompt);
    };
  }, []);

  const handlePWAPrompt = () => {
    if (pwaPrompt) {
      pwaPrompt.prompt();
    }

    if (isIOS) {
      window.dispatchEvent(new CustomEvent('pwaprompt'));
    }
  };

  const renderButton = () => {
    if (pwaPrompt !== null || isIOS || process.env.NODE_ENV === 'development') {
      return (
        <Button as="button" to="" secondary onClick={() => handlePWAPrompt()}>
          Pobierz aplikację
        </Button>
      );
    }

    return null;
  };

  return { renderButton };
}

export default usePwa;
