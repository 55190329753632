import { useEffect } from 'react';

const useAccessibility = (): void => {
  useEffect(() => {
    const accessibilityClassName = 'accessibility';

    const handleKeyDown = (event: any) => {
      if (event.key === 'Tab') {
        document.body.classList.add(accessibilityClassName);
      }
    };

    const handleMouseDown = () => {
      document.body.classList.remove(accessibilityClassName);
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);
};

export default useAccessibility;
