import React from 'react';
import ReactModal from 'react-modal';
import { Button } from 'components/index';

ReactModal.setAppElement('#root');

type Props = {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  onRequestClose: () => void;
};

const defaultProps = {};

function Modal({ children, title, isOpen, onRequestClose, ...rest }: Props): JSX.Element {
  return (
    <ReactModal
      style={{
        content: {
          maxWidth: 852,
          width: '100%',
          top: '50%',
          bottom: 'initial',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          border: 'none',
          boxShadow: '0 8px 48px 0 rgba(8, 44, 51, 0.16)',
          borderRadius: 0,
        },
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          zIndex: 40,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      {...rest}
    >
      <div className="px-16 pt-24 pb-40 xl:px-40 xl:pt-40 xl:pb-48">
        <div className="flex justify-between items-center mb-20 xl:mb-24">
          <p className="font-headings font-bold text-black text-26 xl:text-32 leading-32 xl:leading-40">
            {title}
          </p>
          <Button as="button" to="" icon="close" onClick={onRequestClose} transparent />
        </div>
        {children}
      </div>
    </ReactModal>
  );
}

Modal.defaultProps = defaultProps;

export default Modal;
