import type { Attachment } from 'helpers/types';

export const isFile = (value: FileList | null): boolean => {
  return value instanceof FileList && value.length > 0;
};

export const isExistFile = (value: Attachment | Attachment[]): boolean => {
  if (Array.isArray(value)) {
    return !!value.length;
  }

  return value && !!value.src;
};
