import App from 'pages/app/App';
import Home from 'pages/app/Home/Home';
import RegisterEditor from 'pages/app/RegisterEditor/RegisterEditor';
import Editors from 'pages/app/Editors/Editors';
import Editor from 'pages/app/Editor/Editor';
import EditorAbout from 'pages/app/Editor/About';
import EditorActivityFields from 'pages/app/Editor/ActivityFields';
import EditorReviews from 'pages/app/Editor/Reviews';
import About from 'pages/app/About/About';
import Contact from 'pages/app/Contact/Contact';
import TextPage from 'pages/app/TextPage/TextPage';
import Articles from 'pages/app/Articles/Articles';
import Article from 'pages/app/Article/Article';
import NotFound from 'pages/app/NotFound/NotFound';

import authRoutes from 'routes/auth';

export default [
  {
    key: 'app',
    path: '/',
    exact: false,
    component: App,
    routes: [
      {
        key: 'appHome',
        path: '/',
        exact: true,
        component: Home,
      },
      {
        key: 'appRegisterEditor',
        path: '/zostan-redaktorem',
        exact: true,
        component: RegisterEditor,
      },
      {
        key: 'appEditor',
        path: '/redaktorzy/:id',
        exact: false,
        component: Editor,
        routes: [
          {
            key: 'appEditorAbout',
            path: '/informacje-podstawowe',
            exact: true,
            component: EditorAbout,
          },
          {
            key: 'appEditorActivityFields',
            path: '/preferowane-dziedziny',
            exact: true,
            component: EditorActivityFields,
          },
          {
            key: 'appEditorReviews',
            path: '/oceny',
            exact: true,
            component: EditorReviews,
          },
          {
            key: 'notfound',
            path: '*',
            exact: true,
            redirect: '/404',
          },
        ],
      },
      {
        key: 'appEditors',
        path: '/redaktorzy',
        exact: true,
        component: Editors,
      },
      {
        key: 'appAbout',
        path: '/o-platformie',
        exact: true,
        component: About,
      },
      {
        key: 'appContact',
        path: '/kontakt',
        exact: true,
        component: Contact,
      },
      {
        key: 'appPrivacy',
        path: '/polityka-prywatnosci',
        exact: true,
        component: TextPage,
      },
      {
        key: 'appRegulations',
        path: '/regulamin',
        exact: true,
        component: TextPage,
      },
      {
        key: 'appFaq',
        path: '/faq',
        exact: true,
        component: TextPage,
      },
      {
        key: 'appArticles',
        path: '/artykuly',
        exact: true,
        component: Articles,
      },
      {
        key: 'appArticle',
        path: '/artykuly/:slug',
        exact: true,
        component: Article,
      },
      ...authRoutes,
      {
        key: 'notfound',
        path: '*',
        exact: true,
        component: NotFound,
      },
    ],
  },
];
