import React from 'react';
import { Button } from 'components';
import { authService } from 'services';

function LoginButton(): JSX.Element {
  if (authService.isLogin()) {
    return (
      <Button as="button" to="" onClick={() => authService.redirect()}>
        Moje konto
      </Button>
    );
  }

  return (
    <Button as="route" to="/auth/login">
      Zaloguj się
    </Button>
  );
}

export default LoginButton;
