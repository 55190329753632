import React from 'react';
import DP, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useController } from 'react-hook-form';
import pl from 'date-fns/locale/pl';
import { format, parse } from 'date-fns/esm';
import { InputError, InputLabel } from 'components';
import type { EmitOnChange } from 'helpers/types';
import Trigger from './Trigger';
import './Datepicker.scss';

registerLocale('pl', pl);
setDefaultLocale('pl');

type Props = {
  name: string;
  control: any;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  onChangeEmit?: (v: EmitOnChange) => void;
  [x: string]: any;
};

const defaultProps = {
  disabled: false,
  label: '',
  placeholder: '',
  onChangeEmit: null,
};

function Datepicker({
  name,
  control,
  disabled,
  label,
  placeholder,
  onChangeEmit,
  ...rest
}: Props): JSX.Element {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: '',
  });

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <div className="mb-12 xl:mb-16">
        <DP
          popperPlacement="bottom-end"
          selected={value ? parse(value, 'yyyy-MM-dd HH:mm:ss', new Date()) : null}
          onChange={(date) => {
            const v = date ? format(date as Date, 'yyyy-MM-dd HH:mm:ss') : '';
            onChange(v);

            if (onChangeEmit) {
              onChangeEmit({
                name,
                value: v,
              });
            }
          }}
          customInput={
            <Trigger
              error={!!error}
              resetFn={() => {
                onChange('');
                if (onChangeEmit) {
                  onChangeEmit({
                    name,
                    value: '',
                  });
                }
              }}
            />
          }
          disabled={disabled}
          placeholderText={placeholder || 'DD / MM / RRRR'}
          dateFormat="dd / MM / yyyy"
          {...rest}
        />
        <InputError message={error?.message} />
      </div>
    </>
  );
}

Datepicker.defaultProps = defaultProps;

export default Datepicker;
