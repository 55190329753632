import React from 'react';

type Props = {
  message?: string;
};

const defaultProps = {
  message: '',
};

function InputError({ message }: Props): JSX.Element | null {
  if (!message) return null;

  return <p className="text-12 xl:text-13 mt-4 xl:mt-8 text-error">{message}</p>;
}

InputError.defaultProps = defaultProps;

export default InputError;
