import React from 'react';
import { PageTitle } from 'components';
import { ClientEditAccount } from 'forms';

const Account = (): JSX.Element => {
  return (
    <>
      <PageTitle>Moje dane</PageTitle>
      <ClientEditAccount />
    </>
  );
};

export default Account;
