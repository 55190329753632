import React from 'react';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';

type Props = {
  config: any;
};

const defaultProps = {};

function Status({ config }: Props): JSX.Element {
  const { t } = useTranslation(['text']);

  return (
    <span
      className="inline-flex items-center justify-center font-semibold text-12 xl:text-13 uppercase"
      style={{ color: config?.colors.status }}
    >
      <Icon
        icon={config.icon}
        className="pointer-events-none mr-8"
        beforeInjection={(svg) => {
          svg.classList.add('w-12', 'h-12');
          svg.style.fill = config?.colors.status;
        }}
      />
      {t(`text:${config.nameKey}`)}
    </span>
  );
}

Status.defaultProps = defaultProps;

export default Status;
