import { ApiError } from 'services';
import { UseFormSetError } from 'react-hook-form';
import history from 'helpers/history';

export const catchApiError = (
  error: ApiError,
  cb: any = null,
  setFieldError: UseFormSetError<any> | null = null,
) => {
  if (cb && typeof cb === 'function') {
    cb();
  }

  if (setFieldError && error.field && error.fieldMessage) {
    setFieldError(
      error.field,
      {
        type: 'api',
        message: error.fieldMessage,
      },
      { shouldFocus: true },
    );
  }

  if (error.redirect) {
    history.push(error.redirect);
  }
};
